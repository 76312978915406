import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import Moment from 'moment';

import 'moment/locale/th';

import {
    ShopInsuranceAgreement,
    ShopInsuranceForm,
    ShopInsuranceOtpcode,
    ShopInsurancePackage,
    ShopInsurancePhonenumber,
    ShopInsuranceResult,
    ShopInsuranceSummary
} from './components';

import config from '../../environments/config';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

export function ShopInsuranceMain() {
    const history = useHistory();

    const { user } = useSelector((state) => state.auth);

    const [ page, setPage ] = useState('main');
    const [ loading, setLoading ] = useState(false);
    const [ close, setClose ] = useState(false);
    const [ agreement, setAgreement ] = useState(false);
    const [ plan, setPlan ] = useState('แผน 1');
    const [ products, setProducts ] = useState({});
    const [ productcodes, setProductCodes ] = useState([]);
    const [ productprices, setProductPrices ] = useState([]);
    const [ productvats, setProductVats ] = useState([]);
    const [ productname, setProductName ] = useState('');
    const [ productprice, setProductPrice ] = useState('');
    const [ productvat, setProductVat ] = useState('');

    const [ firstname, setFirstname ] = useState('');
    const [ lastname, setLastname ] = useState('');
    const [ citizenid, setCitizenid ] = useState('');
    const [ birthdate, setBirthdate ] = useState('__/__/____');
    const [ occupation, setOccupation ] = useState('1');
    const [ beneficiary, setBeneficiary ] = useState('');
    const [ paymentchannal, setPaymentchannal ] = useState('1');
    const [ coupon, setCoupon ] = useState('');
    const [ coupons, setCoupons ] = useState([]);
    // Phonenumber
    const [ walletnumber, setWalletnumber ] = useState('');
    // Otpcpde
    const [ otpcode, setOtpcode ] = useState('');
    const [ refcode, setRefcode ] = useState('');
    const [ expiretime, setExpiretime ] = useState('');
    const [ disablebtn, setDisableBtn ] = useState(false);
    const [ disableotp, setDisableOtp ] = useState(false);
    const [ response, setResponse ] = useState({});

    const Completionist = () => {
        return (
            <div className='row justify-content-center col-12 p-0 m-0'>
                <button className='btn btn-primary rounded-lg' onClick={(e) => getOtpcode(true)} disabled={disableotp}>
                    ขอ OTP ใหม่
                </button>
            </div>
        );
    };

    const renderer = ({ minutes, seconds, completed }) => {
        // console.log(minutes);
        // console.log(seconds);
        // console.log(completed);
        if (completed) {
            // Render a complete state
            // ("2020-01-02 11:00:00");
            return <Completionist />;
        } else {
            // Render a countdown
            return (
                <div className='row col-12 p-0 m-0 text-center'>
                    <p className='col-12 m-0'>กรุณาลองใหม่ในอีก</p>
                    <span className='col-12'>
                        {(minutes < 10 ? '0' : '') + minutes}:
                        {(seconds < 10 ? '0' : '') + seconds}
                    </span>
                </div>
            );
        }
    };

    useEffect(() => {
        if (user.shop_account === undefined || user.shop_account === null || user.shop_account === '') {
            history.push('/shop/product?page=login');
        }
        getProfile(user.id);
        // getProducts();
    }, []);

    const handleOtp = (code) => {
        setOtpcode(code);
        if (code.length >= 6) {
            setDisableBtn(false);
        } else {
            setDisableBtn(true);
        }
    };

    const btnNext = () => {
        if (page === 'main') {
            setPage('form');
        } else if (page === 'phonenumber') {
            getOtpcode();
        } else if (page === 'otpcode') {
            let param = {
                token: user['shop_token'],
                payment_channel: 'punsook-wallet',
                product: {
                    product_code: plan,
                    quantity: '1'
                },
                insurance_info: {
                    idcard: citizenid,
                    beneficiary: beneficiary,
                    firstname: firstname,
                    lastname: lastname,
                    birthdate: Moment(birthdate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                    occupation: occupation
                },
                phonenumber: walletnumber,
                otp: otpcode,
                ref: refcode
            };
            if (coupon !== '') {
                param['coupon_id'] = [ coupon.value ];
            }
            checkOut(param);
        }
    };

    const btnPlan = (name, price, vat, select) => {
        setPlan(select);
        setLoading(true);
        fetch(config.punsook_shop_url + 'member/insurance/check-sum-insured', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                token: user['shop_token'],
                id_card: citizenid,
                product_code: select
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    setProductName(name);
                    setProductPrice(price);
                    setProductVat(vat);
                    setPage('summary');
                } else {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `${result.responsecode}! ไม่สามารถดำเนินการต่อได้ กรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: `ไม่สามารถดำเนินการต่อได้ กรุณาลองใหม่อีกครั้ง`,
                    icon: 'error',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            });
    };

    const getProfile = (memberid) => {
        setLoading(true);
        fetch(config.py_url + config.version + '/profile/info', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                userid: memberid
            })
        })
            .then((response) => response.json())
            .then((result) => {
                // setLoading(false);
                if (result.responsecode === 0) {
                    if (result.profile.name !== '') {
                        setFirstname(result.profile.name.split(' ')[0]);
                        setLastname(result.profile.name.split(' ')[1]);
                    }
                    if (result.profile.id_card !== '') {
                        setCitizenid(result.profile.id_card);
                    }
                }
                getProducts();
            })
            .catch((error) => {
                // setLoading(false);
                getProducts();
            });
    };

    const getProducts = () => {
        // setLoading(true);
        fetch(config.punsook_shop_url + 'member/insurance/product/find', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                token: user['shop_token']
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setProducts(result);
                if (result.responsecode === 0) {
                    let codes = [];
                    let prices = [];
                    let vats = [];
                    for (const item in result.products) {
                        codes.push(result.products[item]['code']);
                        prices.push(result.products[item]['price']);
                        vats.push(result.products[item]['vat']);
                    }
                    setProductCodes(codes);
                    setProductPrices(prices);
                    setProductVats(vats);
                } else {
                    setClose(true);
                }
            })
            .catch((error) => {
                setLoading(false);
                setClose(true);
            });
    };

    const checkLimit = (page = '', idcard = '') => {
        let param = {
            token: user['shop_token']
        };
        if (idcard !== '') {
            param['id_card'] = idcard;
        }
        fetch(config.punsook_shop_url + 'member/insurance/check-sum-insured', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify(param)
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    if (page !== '') {
                        setPage(page);
                    }
                    // setPage('package');
                } else {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `${result.responsecode}! ไม่สามารถดำเนินการต่อได้ กรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: `ไม่สามารถดำเนินการต่อได้ กรุณาลองใหม่อีกครั้ง`,
                    icon: 'error',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            });
    };

    const checkOut = (param) => {
        setLoading(true);
        fetch(config.punsook_shop_url + 'member/insurance/create', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify(param)
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setResponse(result);
                setPage('result');
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: `ไม่สามารถดำเนินการต่อได้ กรุณาลองใหม่อีกครั้ง`,
                    icon: 'error',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            });
    };

    const btnPayment = () => {
        let param = {
            token: user['shop_token'],
            product: {
                product_code: plan,
                quantity: '1'
            },
            insurance_info: {
                idcard: citizenid,
                beneficiary: beneficiary,
                firstname: firstname,
                lastname: lastname,
                birthdate: Moment(birthdate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                occupation: occupation
            }
            // "phonenumber": "09XXXXXXX",
            // "otp": "12345678",
            // "ref": "123456789",
        };
        if (paymentchannal === '1') {
            param['payment_channel'] = 'bay-qr';
            if (coupon !== '') {
                param['coupon_id'] = [ coupon.value ];
            }
            checkOut(param);
        } else if (paymentchannal === '2') {
            // param['payment_channel'] = 'punsook-wallet';
            // checkOut(param);
            setPage('phonenumber');
        }
    };

    const getOtpcode = (retry = false) => {
        setOtpcode('');
        setLoading(true);
        setDisableOtp(true);
        fetch(config.punsook_shop_url + 'punsook/otp-generate', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                phonenumber: walletnumber
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setDisableOtp(false);
                if (result.responsecode === 0) {
                    setRefcode(result.refcode);
                    setExpiretime(result.timeout);
                    setDisableBtn(true);
                    if (!retry) {
                        setPage('otpcode');
                    }
                } else if (result.responsecode === 100) {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `หมายเลข ${walletnumber} ไม่ใช่เบอร์ในโครงการซิมปันสุข ไม่สามารถเข้าร่วมกลุ่มเครดิตยูเนี่ยนครอบครัวซิมปันสุขได้`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                } else {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `${result.responsecode}! มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                setDisableOtp(false);
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            });
    };

    return (
        <div>
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='insurance-page container'>
                    {close ? (
                        <div className='row justify-content-center align-items-center bg-white cart-box min-h-500px'>
                            <div className='col-12 text-center'>
                                <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                <h3 className='col mt-5'>
                                    มีบางอย่างผิดพลาดหรือไม่มีกรมธรรม์เปิดขายในขณะนี้ กรุณาลองใหม่อีกครั้งภายหลัง
                                </h3>
                            </div>
                        </div>
                    ) : (
                        <div className='row justify-content-center'>
                            {page === 'main' ? (
                                <ShopInsuranceAgreement
                                    btnNext={btnNext}
                                    agreement={agreement}
                                    setAgreement={setAgreement}
                                />
                            ) : page === 'package' ? (
                                <ShopInsurancePackage
                                    user={user}
                                    setLoading={setLoading}
                                    plan={plan}
                                    setPlan={setPlan}
                                    btnPlan={btnPlan}
                                    products={products}
                                    productcodes={productcodes}
                                    productprices={productprices}
                                    productvats={productvats}
                                />
                            ) : page === 'form' ? (
                                <ShopInsuranceForm
                                    user={user}
                                    setLoading={setLoading}
                                    setPage={setPage}
                                    firstname={firstname}
                                    setFirstname={setFirstname}
                                    lastname={lastname}
                                    setLastname={setLastname}
                                    citizenid={citizenid}
                                    setCitizenid={setCitizenid}
                                    birthdate={birthdate}
                                    setBirthdate={setBirthdate}
                                    occupation={occupation}
                                    setOccupation={setOccupation}
                                    beneficiary={beneficiary}
                                    setBeneficiary={setBeneficiary}
                                    checkLimit={checkLimit}
                                />
                            ) : page === 'summary' ? (
                                <ShopInsuranceSummary
                                    user={user}
                                    plan={plan}
                                    setLoading={setLoading}
                                    firstname={firstname}
                                    lastname={lastname}
                                    citizenid={citizenid}
                                    birthdate={birthdate}
                                    occupation={occupation}
                                    beneficiary={beneficiary}
                                    productname={productname}
                                    productprice={productprice}
                                    productvat={productvat}
                                    paymentchannal={paymentchannal}
                                    setPaymentchannal={setPaymentchannal}
                                    coupon={coupon}
                                    setCoupon={setCoupon}
                                    coupons={coupons}
                                    setCoupons={setCoupons}
                                    btnPayment={btnPayment}
                                />
                            ) : page === 'phonenumber' ? (
                                <ShopInsurancePhonenumber
                                    walletnumber={walletnumber}
                                    setWalletnumber={setWalletnumber}
                                    btnNext={btnNext}
                                />
                            ) : page === 'otpcode' ? (
                                <ShopInsuranceOtpcode
                                    Completionist={Completionist}
                                    renderer={renderer}
                                    otpcode={otpcode}
                                    refcode={refcode}
                                    expiretime={expiretime}
                                    handleOtp={handleOtp}
                                    disablebtn={disablebtn}
                                    btnNext={btnNext}
                                />
                            ) : page === 'result' ? (
                                <ShopInsuranceResult paymentchannal={paymentchannal} response={response} />
                            ) : null}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
