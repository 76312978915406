import React from 'react';

import { NavLink } from 'react-router-dom';

import SVG from 'react-inlinesvg';

import { toAbsoluteUrl } from '../../_metronic/_helpers';

export function MobileShareMenu() {
    return (
        <div className='row p-0 m-0 mb-15'>
            <NavLink className='col-12 p-0' to='/share/operation'>
                <div className='card'>
                    <div className='card-body'>
                        <div className='d-flex'>
                            {/* <div className="col-12"> */}
                            <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                <SVG title='จองหุ้น' src={toAbsoluteUrl('/media/svg/icons/Shopping/Bitcoin.svg')} />
                            </span>
                            {/* </div> */}
                            <h5 className='my-auto font-weight-bolder text-dark'>จองหุ้น</h5>
                        </div>
                    </div>
                </div>
            </NavLink>
            <NavLink className='col-12 p-0' to='/share/history'>
                <div className='card'>
                    <div className='card-body'>
                        <div className='d-flex'>
                            {/* <div className="col-12"> */}
                            <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                <SVG title='ประวัติการซื้อหุ้น' src={toAbsoluteUrl('/media/svg/icons/Code/Time-schedule.svg')} />
                            </span>
                            {/* </div> */}
                            <h5 className='my-auto font-weight-bolder text-dark'>ประวัติการซื้อหุ้น</h5>
                        </div>
                    </div>
                </div>
            </NavLink>
        </div>
    );
}
