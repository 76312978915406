import React from 'react';
import NumberFormat from 'react-number-format';
import SVG from 'react-inlinesvg';
import Moment from 'moment';
import 'moment/locale/th';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

export function SubWalletWithdrawResult(props) {
    return (
        <div className='row col-12 p-0 m-0'>
            <div className='card card-custom position-relative overflow-hidden col-11 mx-auto p-0'>
                <div className='position-absolute opacity-30'>
                    <span className='svg-icon svg-icon-10x svg-logo-white'>
                        <SVG
                            // title="แชร์"
                            src={toAbsoluteUrl('/media/svg/shapes/abstract-8.svg')}
                        />
                    </span>
                </div>
                <div className='row justify-content-center py-8 px-8 py-md-36 px-md-0 bg-primary'>
                    <div className='col-md-10'>
                        <div className='d-flex justify-content-between align-items-md-center flex-column flex-md-row'>
                            <div className='d-flex flex-column px-0 order-2 order-md-1'>
                                <div className='py-4 d-flex flex-row text-white'>
                                    <img
                                        alt='Logo'
                                        src={toAbsoluteUrl('/media/logos/logo_sim_punsuk_final.png')}
                                        className='logo-sticky max-h-60px'
                                    />
                                    <h2 className='my-auto'>
                                        <b>ปันสุข</b> |{' '}
                                    </h2>
                                    <span className='pl-2 my-auto font-size-h3'>ซิม</span>
                                </div>
                            </div>
                            <h1 className='display-3 font-weight-boldest text-white order-1 order-md-2'>สรุปรายการ</h1>
                        </div>
                    </div>
                </div>
                {/* test */}
                <div className='row justify-content-center py-8 px-8 py-md-30 px-md-0 bg-gray-300'>
                    <div className='col-md-10'>
                        <div className='row pb-26'>
                            <div className='col-md-12 pr-md-10 py-md-10'>
                                <div className='custom-title'>
                                    <div className='title-text text-dark-50 font-size-lg font-weight-bold my-auto'>
                                        รายการที่
                                    </div>
                                    <div className='title-line bg-gray-500 my-auto' />
                                </div>
                                <div className='font-size-lg font-weight-bold text-right mb-10'>{props.result.ref}</div>
                                <div className='custom-title'>
                                    <div className='title-text text-dark-50 font-size-lg font-weight-bold my-auto'>
                                        ถอนเงินไปยัง
                                    </div>
                                    <div className='title-line bg-gray-500 my-auto' />
                                </div>
                                <div className='font-size-h2 font-weight-bold text-right'>บัญชี {props.bank}</div>
                                <div className='font-size-lg font-weight-bold text-right'>{props.bankno}</div>
                                <div className='font-size-lg font-weight-bold text-right mb-10'>{props.account}</div>
                                <div className='custom-title'>
                                    <div className='title-text text-dark-50 font-size-lg font-weight-bold my-auto'>
                                        วันที่ทำรายการ
                                    </div>
                                    <div className='title-line bg-gray-500 my-auto' />
                                </div>
                                <div className='font-size-lg font-weight-bold text-right mb-10'>
                                    {Moment(new Date(props.result.datetime.replace(/-/g, '/'))).format(
                                        'DD/MM/YYYY HH:mm:ss'
                                    )}
                                </div>
                                <div className='custom-title'>
                                    <div className='title-line bg-gray-500 my-auto' />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-5 border-top pt-14 pb-10 pb-md-18'>
                                <div className='d-flex flex-column flex-md-row'>
                                    <div className='d-flex flex-column'>
                                        <div className='font-weight-bold font-size-h6 mb-3'>ค่าบริการ</div>
                                        <div className='d-flex justify-content-between font-size-lg mb-3'>
                                            <span className='font-weight-bold mr-5'>จำนวนเงินที่ต้องการถอน:</span>
                                            <span className='text-right'>
                                                <NumberFormat
                                                    value={parseFloat(props.amount)}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                />
                                            </span>
                                        </div>
                                        <div className='d-flex justify-content-between font-size-lg mb-3'>
                                            <span className='font-weight-bold mr-5'>ค่าธรรมเนียม:</span>
                                            <span className='text-right'>
                                                <NumberFormat
                                                    value={parseFloat(props.fee)}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                />
                                            </span>
                                        </div>
                                        <div className='d-flex justify-content-between font-size-lg'>
                                            <span className='font-weight-bold mr-5'>
                                                ภาษีหัก ณ ที่จ่าย {parseInt(props.tax)} %:
                                            </span>
                                            <span className='text-right'>
                                                <NumberFormat
                                                    value={parseFloat(
                                                        parseFloat(props.tax) * parseFloat(props.amount) / 100
                                                    )}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-7 pt-md-25'>
                                <div className='bg-primary rounded d-flex align-items-center justify-content-between text-white max-w-350px position-relative ml-auto p-7'>
                                    <div className='position-absolute opacity-30 top-0 right-0'>
                                        <span className='svg-icon svg-icon-2x svg-logo-white svg-icon-flip'>
                                            <SVG
                                                // title="แชร์"
                                                src={toAbsoluteUrl('/media/svg/shapes/abstract-8.svg')}
                                            />
                                        </span>
                                    </div>
                                    <div className='font-weight-boldest font-size-h5'>จำนวนเงินที่ได้รับ</div>
                                    <div className='text-right d-flex flex-column'>
                                        <span className='font-weight-boldest font-size-h3 line-height-sm'>
                                            <NumberFormat
                                                value={parseFloat(
                                                    parseFloat(props.amount) -
                                                        (props.fee +
                                                            parseFloat(
                                                                parseFloat(props.amount) * parseFloat(props.tax) / 100
                                                            ))
                                                )}
                                                displayType={'text'}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                thousandSeparator={true}
                                            />
                                        </span>
                                        <span className='font-size-sm'>รวมค่าบริการทั้งหมดแล้ว</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* test */}
            </div>
        </div>
    );
}
