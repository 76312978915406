import React from 'react';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import NumberFormat from 'react-number-format';

import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

export function UCConfirm(prop) {
    return (
        <div className='row d-flex justify-content-center pb-5'>
            <div className='col-sm-5 col-md-6 mb-5 mb-md-0'>
                <div className='py-4 d-flex flex-row'>
                    <img
                        alt='Logo'
                        src={toAbsoluteUrl('/media/logos/logo_sim_punsuk_final.png')}
                        className='logo-sticky max-h-35px'
                    />
                    <h5 className='my-auto'>
                        <b>ปันสุข</b> |{' '}
                    </h5>
                    <span className='pl-2 my-auto'>ซิม</span>
                </div>
                {/* <OverlayScrollbarsComponent className="summary-body bg-light">className="bg-light rounded d-flex flex-column p-3" */}
                <div className='bg-light rounded d-flex flex-column p-3'>
                    <div className='d-flex row m-0 p-2'>
                        <div className='col-xl-12 d-flex'>
                            <div>
                                <p>
                                    <i className='fas fa-truck mr-3' />
                                    <b>ข้อมูลที่อยู่จัดส่ง</b>
                                </p>
                            </div>
                        </div>
                        <div className='col-xl-12'>
                            <div className='rounded bg-white row m-0 p-3'>
                                <div className='d-flex col-xl-12'>
                                    <div className='p-2'>ชื่อผู้รับสินค้า</div>
                                    <div className='ml-auto p-2'>{prop.shipname}</div>
                                </div>
                                <div className='d-flex col-xl-12'>
                                    <div className='p-2'>เบอร์โทรศัพท์ติดต่อ</div>
                                    <div className='ml-auto p-2'>{prop.shipcontact}</div>
                                </div>
                                <div className='d-flex col-xl-12'>
                                    <div className='p-2'>ภูมิภาค</div>
                                    <div className='ml-auto p-2'>
                                        {prop.shippingtype === 'ST1' ? prop.shipgeo : prop.shipgeo.label}
                                    </div>
                                </div>
                                <div className='d-flex col-xl-12'>
                                    <div className='p-2'>จังหวัด</div>
                                    <div className='ml-auto p-2'>
                                        {prop.shippingtype === 'ST1' ? prop.shipprovince : prop.shipprovince.label}
                                    </div>
                                </div>
                                <div className='d-flex col-xl-12'>
                                    <div className='p-2'>
                                        {(prop.shippingtype === 'ST1' ? prop.shipprovince : prop.shipprovince.label) ===
                                        'กรุงเทพมหานคร' ? (
                                            'เขต'
                                        ) : (
                                            'อำเภอ'
                                        )}
                                    </div>
                                    <div className='ml-auto p-2'>
                                        {prop.shippingtype === 'ST1' ? prop.shipdistrict : prop.shipdistrict.label}
                                    </div>
                                </div>
                                <div className='d-flex col-xl-12'>
                                    <div className='p-2'>
                                        {(prop.shippingtype === 'ST1' ? prop.shipprovince : prop.shipprovince.label) ===
                                        'กรุงเทพมหานคร' ? (
                                            'แขวง'
                                        ) : (
                                            'ตำบล'
                                        )}
                                    </div>
                                    <div className='ml-auto p-2'>
                                        {prop.shippingtype === 'ST1' ? (
                                            prop.shipsubdistrict
                                        ) : (
                                            prop.shipsubdistrict.label
                                        )}
                                    </div>
                                </div>
                                <div className='d-flex col-xl-12'>
                                    <div className='p-2'>รหัสไปรษณีย์</div>
                                    <div className='ml-auto p-2'>{prop.shipzipcode}</div>
                                </div>
                                <div className='d-flex col-xl-12'>
                                    <div className='p-2'>บ้านเลขที่ ถนน ซอย</div>
                                    <div className='ml-auto p-2 text-right'>{prop.shipaddress}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </OverlayScrollbarsComponent> */}
            </div>
            <div className='col-sm-3 col-md-6 mobile'>
                <div className='bg-light rounded d-flex flex-column p-3'>
                    <div className='p-2 ml-3'>
                        <h4>รายละเอียดการเข้าร่วมโครงการ</h4>
                    </div>
                    <div className='p-2 d-flex'>
                        <div className='col-7'>ประเภทเบอร์เข้าร่วมโครงการ</div>
                        <div className='ml-auto text-right'>
                            เข้าร่วมด้วยเบอร์ปันสุข
                            {/* {prop.type === "PT2"
                                ? "เข้าร่วมด้วยเบอร์ใหม่ปันสุข"
                                : "เข้าร่วมด้วยเบอร Feels ที่มีอยู่"} */}
                        </div>
                    </div>
                    <div className='p-2 d-flex'>
                        <div className='col-8'>ขนาดสัญญาดีลเลอร์</div>
                        <div className='ml-auto'>
                            <NumberFormat
                                value={parseFloat(prop.packages.price)}
                                displayType={'text'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                thousandSeparator={true}
                                suffix={' บาท'}
                            />
                        </div>
                    </div>
                    <div className='p-2 d-flex'>
                        <div className='col-8'>เบอร์โทรศัพท์ที่เข้าร่วมโครงการ</div>
                        <div className='ml-auto'>{prop.phonenumber}</div>
                    </div>
                    <div className='border-top px-4 mx-3' />
                    <div className='d-flex flex-row align-content-center p-4'>
                        <div className='pt-2 pr-2 col-xl-12'>
                            <div className='radio-inline'>
                                <label className='radio radio-primary col-xl-12'>
                                    <input
                                        type='radio'
                                        name='radio'
                                        value='2'
                                        checked={prop.paymenttype === '2'}
                                        onChange={() => prop.handlePayment('2')}
                                    />
                                    <span />
                                    <div className='rounded border d-flex w-100 px-2 bg-white'>
                                        <div className='row p-0 m-0'>
                                            <p className='my-auto'>
                                                <i className='fas fa-qrcode text-primary pr-2' />
                                                ชำระเงินผ่านคิวอาร์โค้ด
                                            </p>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-row align-content-center p-4'>
                        <div className='pt-2 pr-2 col-xl-12'>
                            <div className='radio-inline'>
                                <label className='radio radio-primary col-xl-12'>
                                    <input
                                        type='radio'
                                        name='radio'
                                        value='3'
                                        checked={prop.paymenttype === '3'}
                                        onChange={() => prop.handlePayment('3')}
                                    />
                                    <span />
                                    <div className='rounded border d-flex w-100 px-2 bg-white'>
                                        <div className='row p-0 m-0'>
                                            <p className='my-auto'>
                                                <i className='fas fa-wallet text-primary pr-2' />
                                                กระเป๋าซิมปันสุข
                                            </p>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='border-top px-4 mx-3' />
                    <div className='p-2 d-flex pt-3'>
                        <div className='col-8'>
                            <b>ยอดรวม</b>
                        </div>
                        <div className='ml-auto'>
                            <b className='green'>
                                <NumberFormat
                                    value={parseFloat(prop.packages.price)}
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={true}
                                    suffix={' บาท'}
                                />
                            </b>
                        </div>
                    </div>
                    <div className='m-3'>
                        <button type='button' onClick={prop.nextStep} className='btn btn-primary btn-block'>
                            ชำระเงิน
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
