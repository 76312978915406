import React from 'react';
import NumberFormat from 'react-number-format';

export function ShareOrderOperationForm(props) {
    const maxValueCheck = (object) => {
        if (object.target.value > object.target.maxLength) {
            object.target.value = object.target.maxLength;
        }
    };

    return (
        <div>
            <div className='row d-flex justify-content-center bg-white p-5 m-0 mb-md-0 mb-7'>
                <div className='col-sm-5 col-md-6 mb-5 mb-md-0'>
                    {/* <div className='py-4 d-flex flex-row'>
                        <img
                            alt='Logo'
                            src={toAbsoluteUrl('/media/logos/new-logo.png')}
                            className='logo-sticky max-h-35px'
                        />
                        <h5 className='my-auto'>
                            <b>ปันสุข</b> |{' '}
                        </h5>
                        <span className='pl-2 my-auto'>ซิม</span>
                    </div> */}
                    <div className='bg-light h-100 row m-0 align-items-center rounded-lg'>
                        <div className='col-12 p-md-5 p-0'>
                            <div className='d-flex row m-0 p-2'>
                                {/* <div className='col-xl-12 d-flex'>
                                    <div>
                                        <p>
                                            <i className='fas fa-truck mr-3' />
                                            <b>ข้อมูลที่อยู่จัดส่ง</b>
                                        </p>
                                    </div>
                                </div> */}
                                <div className='col-xl-12 p-0'>
                                    <div className='rounded bg-white row m-0 p-3'>
                                        <div className='col-xl-12 mb-3'>
                                            <label>จำนวนหุ้นที่ต้องการจอง</label>
                                            <div className='input-group'>
                                                <input
                                                    type='text'
                                                    inputMode='tel'
                                                    className='form-control'
                                                    value={props.amount}
                                                    maxLength={props.maxAmount - props.totalAmount}
                                                    onInput={maxValueCheck}
                                                    onChange={(e) => props.setAmount(e.target.value)}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            if (event.key !== 'Enter') {
                                                                event.preventDefault();
                                                            }
                                                        }
                                                    }}
                                                    onPaste={(e) => e.preventDefault()}
                                                />
                                                <div className='input-group-append'>
                                                    <span className='input-group-text'>หุ้น</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-3 col-md-6 mobile'>
                    <div className='bg-light rounded d-flex flex-column p-3'>
                        <div className='p-2 ml-3'>
                            <h4>รายละเอียด</h4>
                        </div>
                        <div className='p-2 d-flex'>
                            <div className='col-8'>ราคาหุ้นต่อหนึ่งหุ้น</div>
                            <div className='ml-auto text-right'>
                                <NumberFormat
                                    value={parseFloat(props.price)}
                                    displayType={'text'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    thousandSeparator={true}
                                    suffix={' บาท'}
                                />
                            </div>
                        </div>
                        <div className='p-2 d-flex'>
                            <div className='col-8'>จำนวนหุ้นที่เหลืออยู่</div>
                            <div className='ml-auto'>
                                <NumberFormat
                                    value={parseFloat(props.maxAmount) - parseFloat(props.totalAmount)}
                                    displayType={'text'}
                                    // decimalScale={2}
                                    // fixedDecimalScale={true}
                                    thousandSeparator={true}
                                    suffix={' หุ้น'}
                                />
                            </div>
                        </div>
                        <div className='border-top px-4 mx-3' />
                        <div className='d-flex flex-row align-content-center p-4'>
                            <div className='pt-2 pr-2 col-xl-12'>
                                <div className='radio-inline'>
                                    <label className='radio radio-primary col-xl-12'>
                                        <input
                                            type='radio'
                                            name='payment'
                                            value='bank'
                                            checked={props.payment === 'bank'}
                                            onChange={() => props.setPayment('bank')}
                                        />
                                        <span />
                                        <div className='rounded border d-flex w-100 px-2 bg-white'>
                                            <div className='row p-0 m-0'>
                                                <p className='my-auto'>
                                                    <i className='fas fa-qrcode text-primary pr-2' />
                                                    ชำระเงินผ่านธนาคาร
                                                </p>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-row align-content-center p-4'>
                            <div className='pt-2 pr-2 col-xl-12'>
                                <div className='radio-inline'>
                                    <label className='radio radio-primary col-xl-12'>
                                        <input
                                            type='radio'
                                            name='payment'
                                            value='credit'
                                            checked={props.payment === 'credit'}
                                            onChange={() => props.setPayment('credit')}
                                        />
                                        <span />
                                        <div className='rounded border d-flex w-100 px-2 bg-white'>
                                            <div className='row p-0 m-0'>
                                                <p className='my-auto'>
                                                    <i className='fas fa-credit-card text-primary pr-2' />
                                                    ชำระเงินผ่านบัตรเครดิต
                                                </p>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-row align-content-center p-4'>
                            <div className='pt-2 pr-2 col-xl-12'>
                                <div className='radio-inline'>
                                    <label className='radio radio-primary col-xl-12'>
                                        <input
                                            type='radio'
                                            name='payment'
                                            value='wallet'
                                            checked={props.payment === 'wallet'}
                                            onChange={() => props.setPayment('wallet')}
                                        />
                                        <span />
                                        <div className='rounded border d-flex w-100 px-2 bg-white'>
                                            <div className='row p-0 m-0'>
                                                <p className='my-auto'>
                                                    <i className='fas fa-wallet text-primary pr-2' />
                                                    กระเป๋าซิมปันสุข
                                                </p>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-row align-content-center p-4'>
                            <div className='pt-2 pr-2 col-xl-12'>
                                <div className='radio-inline'>
                                    <label className='radio radio-primary col-xl-12'>
                                        <input
                                            type='radio'
                                            name='payment'
                                            value='wallet'
                                            checked={props.payment === 'share-wallet'}
                                            onChange={() => props.setPayment('share-wallet')}
                                        />
                                        <span />
                                        <div className='rounded border d-flex w-100 px-2 bg-white'>
                                            <div className='row p-0 m-0'>
                                                <p className='my-auto'>
                                                    <i className='fas fa-wallet text-primary pr-2' />
                                                    กระเป๋าส่วนแบ่งจากยอดขาย
                                                </p>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='border-top px-4 mx-3' />
                        <div className='p-2 d-flex pt-3'>
                            <div className='col-8'>
                                <b>ยอดรวม</b>
                            </div>
                            <div className='ml-auto'>
                                <b className='green'>
                                    <NumberFormat
                                        value={parseFloat(props.price * props.amount)}
                                        displayType={'text'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                        suffix={' บาท'}
                                    />
                                </b>
                            </div>
                        </div>
                        <div className='m-3'>
                            <button type='button' className='btn btn-primary btn-block' onClick={props.btnNext}>
                                ชำระเงิน
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
