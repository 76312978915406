import React from 'react';
import Select, { components } from 'react-select';
import TextareaAutosize from 'react-textarea-autosize';

const controlStyles = {
    borderRadius: '0.85rem',
    padding: '5px',
    background: '#F3F6F9',
    color: 'white'
};

const ControlComponent = (props) => (
    <div style={controlStyles}>
        <components.Control {...props} />
    </div>
);

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: '#F3F6F9'
    }),
    menuPortal: (base) => ({
        ...base,
        zIndex: 9999
    }),
    control: (provided, state) => ({
        ...provided,
        borderStyle: 'none',
        backgroundColor: 'transparent',
        boxShadow: 'none'
    })
};

export function NCAddress(props) {
    return (
        <div>
            <div className='form-group fv-plugins-icon-container'>
                <label>เลือกที่อยู่จัดส่ง</label>
                <div className='row'>
                    <div className='col-lg-6'>
                        <label className='option'>
                            <span className='option-control'>
                                <span className='radio'>
                                    <input
                                        type='radio'
                                        name='shippingtype'
                                        value='ST1'
                                        checked={props.shippingtype === 'ST1'}
                                        onChange={() => props.handleShippingType('ST1')}
                                    />
                                    <span />
                                </span>
                            </span>
                            <span className='option-label'>
                                <span className='option-head'>
                                    <span className='option-title'>ตามที่อยู่ที่ติดต่อได้</span>
                                </span>
                            </span>
                        </label>
                    </div>
                    <div className='col-lg-6'>
                        <label className='option'>
                            <span className='option-control'>
                                <span className='radio'>
                                    <input
                                        type='radio'
                                        name='shippingtype'
                                        value='ST2'
                                        checked={props.shippingtype === 'ST2'}
                                        onChange={() => props.handleShippingType('ST2')}
                                    />
                                    <span />
                                </span>
                            </span>
                            <span className='option-label'>
                                <span className='option-head'>
                                    <span className='option-title'>ที่อยู่อื่นนอกจากที่อยู่ที่ติดต่อได้</span>
                                </span>
                            </span>
                        </label>
                    </div>
                </div>
            </div>
            <div className='form-group fv-plugins-icon-container'>
                <label>ที่อยู่จัดส่ง</label>
                <div className='row'>
                    {props.shippingtype === 'ST1' ? (
                        <div className='col-lg-12'>
                            <label className='option'>
                                <span className='option-label'>
                                    <span className='option-head'>
                                        <span className='option-title'>ชื่อผู้รับสินค้า</span>
                                        <span className='option-focus'>{props.ogname}</span>
                                    </span>
                                    <span className='option-head'>
                                        <span className='option-title'>เบอร์โทรศัพท์ติดต่อ</span>
                                        <span className='option-focus'>{props.ogcontactnumber}</span>
                                    </span>
                                    <span className='option-head'>
                                        <span className='option-title'>ภาค</span>
                                        <span className='option-focus'>{props.oggeo}</span>
                                    </span>
                                    <span className='option-head'>
                                        <span className='option-title'>จังหวัด</span>
                                        <span className='option-focus'>{props.ogprovince}</span>
                                    </span>
                                    <span className='option-head'>
                                        <span className='option-title'>
                                            {props.ogprovince === 'กรุงเทพมหานคร' ? 'เขต' : 'อำเภอ'}
                                        </span>
                                        <span className='option-focus'>{props.ogdistrict}</span>
                                    </span>
                                    <span className='option-head'>
                                        <span className='option-title'>
                                            {props.ogprovince === 'กรุงเทพมหานคร' ? 'แขวง' : 'ตำบล'}
                                        </span>
                                        <span className='option-focus'>{props.ogsubdistrict}</span>
                                    </span>
                                    <span className='option-head'>
                                        <span className='option-title'>รหัสไปรษณีย์</span>
                                        <span className='option-focus'>{props.ogzipcode}</span>
                                    </span>
                                    <span className='option-head'>
                                        <span className='option-title'>บ้านเลขที่ ถนน ซอย</span>
                                        <span className='option-focus text-right'>{props.ogaddr}</span>
                                    </span>
                                </span>
                            </label>
                        </div>
                    ) : (
                        <div className='pb-5 col-xl-12'>
                            <label className='option'>
                                <span className='option-label'>
                                    <div className='row'>
                                        <div className='col-xl-6'>
                                            <div className='form-group fv-plugins-icon-container has-danger'>
                                                <label>
                                                    ชื่อผู้รับสินค้า
                                                    <span className='required-text'>*</span>
                                                </label>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-solid form-control-lg'
                                                    value={props.name}
                                                    onChange={(e) => props.handleName(e.target.value)}
                                                />
                                                {props.name === '' && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>กรุณากรอกชื่อผู้รับสินค้า</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-xl-6'>
                                            <div className='form-group fv-plugins-icon-container has-danger'>
                                                <label>
                                                    เบอร์โทรศัพท์ติดต่อ
                                                    <span className='required-text'>*</span>
                                                </label>
                                                <input
                                                    type='tel'
                                                    className='form-control form-control-solid form-control-lg'
                                                    value={props.contactnumber}
                                                    onChange={(e) => props.handleContactnumber(e.target.value)}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    maxLength='10'
                                                    onInput={props.maxLengthCheck}
                                                />
                                                {props.contactnumber === '' ? (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            กรุณากรอกเบอร์โทรศัพท์ติดต่อ
                                                        </div>
                                                    </div>
                                                ) : (
                                                    (props.contactnumber.charAt(0) !== '0' ||
                                                        props.contactnumber.length < 9) && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>
                                                                กรุณากรอกเบอร์โทรศัพท์ติดต่อให้ถูกต้อง
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-xl-6'>
                                            <div className='form-group fv-plugins-icon-container'>
                                                <label>
                                                    ภูมิภาค
                                                    <span className='required-text'>*</span>
                                                </label>
                                                <div>
                                                    <Select
                                                        components={{
                                                            Control: ControlComponent
                                                        }}
                                                        noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                                        value={props.geo}
                                                        options={props.maingeo}
                                                        placeholder='เลือก'
                                                        id='geo'
                                                        onChange={props.selectGeo}
                                                        isClearable={true}
                                                        menuPortalTarget={document.body}
                                                        styles={customStyles}
                                                    />
                                                </div>
                                                {props.geo === '' && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>กรุณาเลือกภูมิภาค</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-xl-6'>
                                            <div className='form-group fv-plugins-icon-container'>
                                                <label>
                                                    จังหวัด
                                                    <span className='required-text'>*</span>
                                                </label>
                                                <div>
                                                    <Select
                                                        components={{
                                                            Control: ControlComponent
                                                        }}
                                                        noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                                        value={props.province}
                                                        options={props.mainprovince}
                                                        placeholder='เลือก'
                                                        id='province'
                                                        onChange={props.selectProvince}
                                                        isClearable={true}
                                                        menuPortalTarget={document.body}
                                                        styles={customStyles}
                                                        isDisabled={
                                                            props.mainprovince === undefined ||
                                                            props.mainprovince.length === 0
                                                        }
                                                    />
                                                </div>
                                                {props.province === '' && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>กรุณาเลือกจังหวัด</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-xl-6'>
                                            <div className='form-group fv-plugins-icon-container'>
                                                <label>
                                                    {props.province.label === 'กรุงเทพมหานคร' ? 'เขต' : 'อำเภอ'}
                                                    <span className='required-text'>*</span>
                                                </label>
                                                <div>
                                                    <Select
                                                        components={{
                                                            Control: ControlComponent
                                                        }}
                                                        noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                                        value={props.district}
                                                        options={props.maindistrict}
                                                        placeholder='เลือก'
                                                        id='geo'
                                                        onChange={props.selectDistrict}
                                                        isClearable={true}
                                                        menuPortalTarget={document.body}
                                                        styles={customStyles}
                                                        isDisabled={
                                                            props.maindistrict === undefined ||
                                                            props.maindistrict.length === 0
                                                        }
                                                    />
                                                </div>
                                                {props.district === '' && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            กรุณาเลือก
                                                            {props.province.label === 'กรุงเทพมหานคร' ? 'เขต' : 'อำเภอ'}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-xl-6'>
                                            <div className='form-group fv-plugins-icon-container'>
                                                <label>
                                                    {props.province.label === 'กรุงเทพมหานคร' ? 'แขวง' : 'ตำบล'}
                                                    <span className='required-text'>*</span>
                                                </label>
                                                <div>
                                                    <Select
                                                        components={{
                                                            Control: ControlComponent
                                                        }}
                                                        noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                                        value={props.subdistrict}
                                                        options={props.mainsubdistrict}
                                                        placeholder='เลือก'
                                                        id='province'
                                                        onChange={props.selectSubDistrict}
                                                        isClearable={true}
                                                        menuPortalTarget={document.body}
                                                        styles={customStyles}
                                                        isDisabled={
                                                            props.mainsubdistrict === undefined ||
                                                            props.mainsubdistrict.length === 0
                                                        }
                                                    />
                                                </div>
                                                {props.subdistrict === '' && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            กรุณาเลือก
                                                            {props.province.label === 'กรุงเทพมหานคร' ? 'แขวง' : 'ตำบล'}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-xl-6'>
                                            <div className='form-group fv-plugins-icon-container'>
                                                <label>
                                                    รหัสไปรษณีย์
                                                    <span className='required-text'>*</span>
                                                </label>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-solid form-control-lg'
                                                    value={props.zipcode}
                                                    onChange={(e) => props.handleZipcode(e.target.value)}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    maxLength='5'
                                                    onInput={props.maxLengthCheck}
                                                />
                                                {props.zipcode === '' && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>กรุณากรอกรหัสไปรษณีย์</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-group fv-plugins-icon-container'>
                                        <label>
                                            บ้านเลขที่ ถนน ซอย
                                            <span className='required-text'>*</span>
                                        </label>
                                        <TextareaAutosize
                                            className='form-control form-control-solid form-control-lg textarea-min-h'
                                            value={props.addr}
                                            onChange={(e) => props.handleAddress(e.target.value)}
                                        />
                                        {props.addr === '' && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>กรุณากรอกบ้านเลขที่ ถนน ซอย</div>
                                            </div>
                                        )}
                                    </div>
                                </span>
                            </label>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
