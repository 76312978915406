import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';

import NumberFormat from 'react-number-format';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import Moment from 'moment';

import 'moment/locale/th';

import { ShopHistoryDetail } from './components';

import config from '../../environments/config';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

export function ShopHistoryMain() {
    const history = useHistory();

    const { user } = useSelector((state) => state.auth);

    const [ page, setPage ] = useState('main');
    const [ loading, setLoading ] = useState(false);
    const [ historys, setHistory ] = useState({});
    const [ details, setDetails ] = useState({});
    const initindex = 5;
    const [ startindex, setStartindex ] = useState(0);
    const [ endindex, setEndindex ] = useState(5);

    useEffect(() => {
        if (user.shop_account === undefined || user.shop_account === null || user.shop_account === '') {
            history.push('/shop/product?page=login');
        }
        getHistorys();
    }, []);

    const btnPrevious = (e) => {
        e.preventDefault();
        setStartindex(startindex - initindex);
        setEndindex(endindex - initindex);
    };

    const btnNext = (e) => {
        e.preventDefault();
        setStartindex(startindex + initindex);
        setEndindex(endindex + initindex);
    };

    const btnBack = () => {
        setPage('main');
    };

    const btnView = (oid) => {
        getOrderDetail(oid);
    };

    const getHistorys = () => {
        setLoading(true);
        fetch(config.punsook_shop_url + 'member/order/find', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: user['shop_token']
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                // console.log(result)
                setHistory(result);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const getOrderDetail = (oid) => {
        setLoading(true);
        fetch(config.punsook_shop_url + 'member/order/get', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: user['shop_token'],
                id: oid.toString()
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                // console.log(result)
                if (result.responsecode === 0) {
                    setDetails(result);
                    setPage('detail');
                } else {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `${result.responsecode}! ไม่สามารถดึงข้อมูลรายการได้ กรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: `ไม่สามารถดึงข้อมูลรายการได้ กรุณาลองใหม่อีกครั้ง`,
                    icon: 'error',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            });
    };
    return (
        <div>
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='history-page'>
                    {page === 'main' ? (
                        <div className='clearfix bg-white p-5'>
                            {Object.keys(historys).length <= 0 ? (
                                <div className='row justify-content-center align-items-center bg-white cart-box min-h-500px'>
                                    <div className='col-12 text-center'>
                                        <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                        <h3 className='col mt-5'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h3>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    {historys.responsecode !== 0 ? (
                                        <div className='row justify-content-center align-items-center bg-white cart-box min-h-500px'>
                                            <div className='col-12 text-center'>
                                                <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                                <h3 className='col mt-5'>ไม่พบประวัติการสั่งซื้อของคุณ</h3>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <ul className='xsearch-items'>
                                                {historys.info.slice(startindex, endindex).map((object, index, arr) => {
                                                    return (
                                                        <div key={index}>
                                                            <li className='search-item'>
                                                                <div className='search-item-content mx-7 pb-0'>
                                                                    <h3 className='search-item-caption'>
                                                                        <div className='text-primary d-inline-block'>
                                                                            # {object.id}
                                                                        </div>
                                                                    </h3>
                                                                    <div className='search-item-meta mb-5'>
                                                                        <ul className='list-inline'>
                                                                            <li className='time d-none d-lg-block'>
                                                                                <h6>
                                                                                    วันที่:{' '}
                                                                                    {Moment(
                                                                                        new Date(object.create_date)
                                                                                    )
                                                                                        .add(543, 'year')
                                                                                        .locale('th')
                                                                                        .format(
                                                                                            'DD/MMMM/YYYY HH:mm:ss'
                                                                                        )}{' '}
                                                                                    | ยอดรวม:{' '}
                                                                                    <NumberFormat
                                                                                        value={parseFloat(
                                                                                            object.total_gross_price
                                                                                        )}
                                                                                        displayType={'text'}
                                                                                        decimalScale={2}
                                                                                        fixedDecimalScale={true}
                                                                                        thousandSeparator={true}
                                                                                        suffix={' บาท'}
                                                                                    />{' '}
                                                                                    | การชำระเงิน:{' '}
                                                                                    <span>
                                                                                        <strong
                                                                                            className={`${object.status ===
                                                                                            'pending'
                                                                                                ? 'text-secondary'
                                                                                                : object.status ===
                                                                                                  'cancel'
                                                                                                  ? 'text-warning'
                                                                                                  : object.status ===
                                                                                                    'expired'
                                                                                                    ? 'text-primary'
                                                                                                    : 'text-success'}`}
                                                                                        >
                                                                                            {object.status ===
                                                                                            'pending' ? (
                                                                                                'รอการชำระเงิน'
                                                                                            ) : object.status ===
                                                                                            'paid' ? (
                                                                                                'ชำระแล้ว'
                                                                                            ) : object.status ===
                                                                                            'cancel' ? (
                                                                                                'ยกเลิก'
                                                                                            ) : object.status ===
                                                                                            'expired' ? (
                                                                                                'หมดอายุ'
                                                                                            ) : object.status ===
                                                                                            'completed' ? (
                                                                                                'ยืนยันสินค้าแล้ว'
                                                                                            ) : null}
                                                                                        </strong>
                                                                                    </span>
                                                                                </h6>
                                                                            </li>
                                                                            <li className='time d-block d-lg-none'>
                                                                                <h6>
                                                                                    วันที่:{' '}
                                                                                    {Moment(
                                                                                        new Date(object.create_date)
                                                                                    )
                                                                                        .add(543, 'year')
                                                                                        .locale('th')
                                                                                        .format(
                                                                                            'DD/MMMM/YYYY HH:mm:ss'
                                                                                        )}
                                                                                </h6>
                                                                                <h6>
                                                                                    ยอดรวม:{' '}
                                                                                    <NumberFormat
                                                                                        value={parseFloat(
                                                                                            object.total_gross_price
                                                                                        )}
                                                                                        displayType={'text'}
                                                                                        decimalScale={2}
                                                                                        fixedDecimalScale={true}
                                                                                        thousandSeparator={true}
                                                                                        suffix={' บาท'}
                                                                                    />
                                                                                </h6>
                                                                                <h6>
                                                                                    การชำระเงิน:{' '}
                                                                                    <span>
                                                                                        <strong
                                                                                            className={`${object.status ===
                                                                                            'pending'
                                                                                                ? 'text-primary'
                                                                                                : 'text-success'}`}
                                                                                        >
                                                                                            {object.status ===
                                                                                            'pending' ? (
                                                                                                'รอการชำระเงิน'
                                                                                            ) : (
                                                                                                'ชำระแล้ว'
                                                                                            )}
                                                                                        </strong>
                                                                                    </span>
                                                                                </h6>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className='d-flex justify-content-end'>
                                                                        <button
                                                                            type='button'
                                                                            className='btn btn-outline-primary'
                                                                            onClick={() => btnView(object.id)}
                                                                        >
                                                                            ดูรายละเอียด
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            {arr.length - 1 !== index && <hr />}
                                                        </div>
                                                    );
                                                })}
                                            </ul>
                                            <div className='d-flex justify-content-between align-items-center flex-wrap'>
                                                <div className='d-flex flex-wrap py-2 mr-3'>
                                                    {/* <a href='#' className='btn btn-icon btn-sm btn-light-danger mr-2 my-1'>
                                                <i className='ki ki-bold-double-arrow-back icon-xs' />
                                            </a> */}
                                                    {historys.info.length > initindex && (
                                                        <button
                                                            type='button'
                                                            className='btn btn-icon btn-sm btn-light-danger mr-2 my-1'
                                                            onClick={btnPrevious}
                                                            disabled={endindex === initindex}
                                                        >
                                                            <i className='ki ki-bold-arrow-back icon-xs' />
                                                        </button>
                                                    )}
                                                    {/* <a
                                                href='#'
                                                className='btn btn-icon btn-sm border-0 btn-hover-danger mr-2 my-1'
                                            >
                                                ...
                                            </a> */}
                                                    {/* <a
                                                href='#'
                                                className='btn btn-icon btn-sm border-0 btn-hover-danger mr-2 my-1'
                                            >
                                                23
                                            </a>
                                            <a
                                                href='#'
                                                className='btn btn-icon btn-sm border-0 btn-hover-danger active mr-2 my-1'
                                            >
                                                24
                                            </a>
                                            <a
                                                href='#'
                                                className='btn btn-icon btn-sm border-0 btn-hover-danger mr-2 my-1'
                                            >
                                                25
                                            </a>
                                            <a
                                                href='#'
                                                className='btn btn-icon btn-sm border-0 btn-hover-danger mr-2 my-1'
                                            >
                                                26
                                            </a>
                                            <a
                                                href='#'
                                                className='btn btn-icon btn-sm border-0 btn-hover-danger mr-2 my-1'
                                            >
                                                27
                                            </a>
                                            <a
                                                href='#'
                                                className='btn btn-icon btn-sm border-0 btn-hover-danger mr-2 my-1'
                                            >
                                                28
                                            </a> */}
                                                    {/* <a
                                                href='#'
                                                className='btn btn-icon btn-sm border-0 btn-hover-danger mr-2 my-1'
                                            >
                                                ...
                                            </a> */}
                                                    {historys.info.length > initindex && (
                                                        <button
                                                            type='button'
                                                            className='btn btn-icon btn-sm btn-light-danger mr-2 my-1'
                                                            onClick={btnNext}
                                                            disabled={endindex >= historys.info.length}
                                                        >
                                                            <i className='ki ki-bold-arrow-next icon-xs' />
                                                        </button>
                                                    )}
                                                    {/* <a href='#' className='btn btn-icon btn-sm btn-light-danger mr-2 my-1'>
                                                <i className='ki ki-bold-double-arrow-next icon-xs' />
                                            </a> */}
                                                </div>
                                                <div className='d-flex align-items-center py-3'>
                                                    <span className='text-muted'>
                                                        แสดง{' '}
                                                        {endindex >= historys.info.length ? (
                                                            historys.info.length
                                                        ) : (
                                                            endindex
                                                        )}{' '}
                                                        จาก {historys.info.length} รายการ
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    ) : page === 'detail' ? (
                        <ShopHistoryDetail
                            setLoading={setLoading}
                            btnBack={btnBack}
                            details={details}
                            user={user}
                            getOrderDetail={getOrderDetail}
                        />
                    ) : null}
                </div>
            )}
        </div>
    );
}
