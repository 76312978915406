import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SimkitTable } from './components/index';
import Moment from 'moment';
import 'moment/locale/th';
import config from '../../../../app/pages/environments/config';
import DatePicker, { utils } from 'react-modern-calendar-datepicker';
import { myCustomLocale, minimumDate } from './components';

export function ShopSimkitMain() {
    const { user } = useSelector((state) => state.auth);
    const [ loading, setLoading ] = useState(true);
    const [ page, setPage ] = useState('main');
    const [ data, setData ] = useState({});
    const [ sim, setSim ] = useState({});
    const [ date, setDate ] = useState({
        from: null,
        to: null
    });

    const btnTable = (orderid) => {
        getSimDetail(orderid);
    };

    useEffect(() => {
        let day = {
            user_id: user.id
        };
        getSim(day);
    }, []);

    const getSim = (param) => {
        setLoading(true);
        fetch(config.py_url + config.version + '/simkit/get', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify(param)
        })
            .then((response) => response.json())
            .then((result) => {
                setSim(result);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const getSimDetail = (orderid) => {
        setLoading(true);
        fetch(config.py_url + config.version + '/simkit/get-detail', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                user_id: user.id,
                order_id: orderid.toString()
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    setData(result);
                    setPage('table');
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const btnSearch = () => {
        let param = {
            user_id: user.id,
            option: [ 'detail' ]
        };
        if (date.from !== null && date.to !== null) {
            param['ordered_date'] = {
                start: date.from.year + '-' + ('0' + date.from.month).slice(-2) + '-' + ('0' + date.from.day).slice(-2),
                end: date.to.year + '-' + ('0' + date.to.month).slice(-2) + '-' + ('0' + date.to.day).slice(-2)
            };
        }
        getSim(param);
    };

    const renderCustomInput = ({ ref }) => (
        <input
            readOnly
            ref={ref}
            value={
                date.from && date.to ? (
                    date.from.year +
                    '/' +
                    ('0' + date.from.month).slice(-2) +
                    '/' +
                    ('0' + date.from.day).slice(-2) +
                    ' - ' +
                    date.to.year +
                    '/' +
                    ('0' + date.to.month).slice(-2) +
                    '/' +
                    ('0' + date.to.day).slice(-2)
                ) : (
                    ''
                )
            }
            className='col-12 form-control h-100 border-0 rounded-lg rounded-right-0'
            type='text'
            id='dateperiod'
            name='dateperiod'
        />
    );

    return (
        <div>
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='card card-custom gutter-b'>
                    <div className={`card-body${page === 'table' ? ' p-0' : ''}`}>
                        <div className='row'>
                            {page === 'main' ? (
                                <div className='col-12'>
                                    <div className='row m-0'>
                                        <div className='col-12 col-md-4 my-5 ml-auto p-0'>
                                            <div className='input-group'>
                                                <DatePicker
                                                    value={date}
                                                    onChange={setDate}
                                                    renderInput={renderCustomInput}
                                                    minimumDate={minimumDate}
                                                    maximumDate={utils().getToday()}
                                                    locale={myCustomLocale}
                                                    wrapperClassName='col-12 p-0 form-control rounded-lg rounded-right-0'
                                                    calendarClassName='responsive-calendar'
                                                    calendarPopperPosition='left'
                                                    shouldHighlightWeekends
                                                />
                                                <div className='input-group-append'>
                                                    <button
                                                        className='btn btn-secondary rounded-lg rounded-left-0'
                                                        type='button'
                                                        onClick={btnSearch}
                                                    >
                                                        ค้นหา
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h3>รายการสั่งซื้อ</h3>
                                    <div className='col-12 clearfix bg-white p-5'>
                                        {Object.keys(sim).length <= 0 ? (
                                            <div className='row justify-content-center align-items-center bg-white cart-box min-h-500px'>
                                                <div className='col-12 text-center'>
                                                    <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                                    <h3 className='col mt-5'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h3>
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                {sim.responsecode !== 0 ? (
                                                    <div className='row justify-content-center align-items-center bg-white cart-box min-h-500px'>
                                                        <div className='col-12 text-center'>
                                                            <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                                            <h3 className='col mt-5'>ไม่พบประวัติการสั่งซื้อของคุณ</h3>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className='xsearch-items'>
                                                        {sim.info.map((object, index, arr) => {
                                                            return (
                                                                <div key={index}>
                                                                    <div className='search-item-content mx-7 pb-0'>
                                                                        <div className='search-item-meta mb-5'>
                                                                            <h5>เลขออเดอร์ : {object.id}</h5>
                                                                            <h5>
                                                                                วันที่สั่งซื้อ :{' '}
                                                                                {Moment(new Date(object.created_date))
                                                                                    .add(543, 'year')
                                                                                    .locale('th')
                                                                                    .format('DD/MMMM/YYYY HH:mm:ss')}
                                                                            </h5>
                                                                            <h5>
                                                                                สถานะ :{' '}
                                                                                <label
                                                                                    className={`${object.status ===
                                                                                    'pending'
                                                                                        ? 'text-secondary'
                                                                                        : object.status === 'paid'
                                                                                          ? 'text-success'
                                                                                          : 'text-info'}`}
                                                                                >
                                                                                    <strong>
                                                                                        {object.status === 'paid' ? (
                                                                                            'ชำระแล้ว'
                                                                                        ) : object.status ===
                                                                                        'pending' ? (
                                                                                            'รอการชำระเงิน'
                                                                                        ) : null}
                                                                                    </strong>
                                                                                </label>
                                                                            </h5>
                                                                            <div className='d-flex justify-content-end'>
                                                                                <button
                                                                                    type='button'
                                                                                    className='btn btn-outline-primary'
                                                                                    onClick={() => btnTable(object.id)}
                                                                                >
                                                                                    ดูรายละเอียด
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {arr.length - 1 !== index && <hr />}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ) : page === 'table' ? (
                                <div className='card-body justify-content-center col-12'>
                                    <SimkitTable setPage={setPage} data={data} user={user} />
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
