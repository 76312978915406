import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';

import { ShopInsuranceHistoryDetail, ShopInsuranceHistoryList } from './components';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import config from '../../environments/config';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

export function ShopInsuranceHistoryMain() {
    const history = useHistory();

    const { user } = useSelector((state) => state.auth);

    const [ page, setPage ] = useState('main');
    const [ loading, setLoading ] = useState(false);
    const [ historys, setHistory ] = useState({});
    const [ details, setDetails ] = useState({});
    const initindex = 5;
    const [ startindex, setStartindex ] = useState(0);
    const [ endindex, setEndindex ] = useState(5);

    useEffect(() => {
        if (user.shop_account === undefined || user.shop_account === null || user.shop_account === '') {
            history.push('/shop/product?page=login');
        }
        getHistorys();
    }, []);

    const btnPrevious = (e) => {
        e.preventDefault();
        setStartindex(startindex - initindex);
        setEndindex(endindex - initindex);
    };

    const btnNext = (e) => {
        e.preventDefault();
        setStartindex(startindex + initindex);
        setEndindex(endindex + initindex);
    };

    const btnBack = () => {
        setPage('main');
    };

    const btnView = (oid) => {
        getOrderDetail(oid);
    };

    const getHistorys = () => {
        setLoading(true);
        fetch(config.punsook_shop_url + 'member/insurance/order/find', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: user['shop_token']
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                // console.log(result)
                setHistory(result);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const getOrderDetail = (oid) => {
        setLoading(true);
        fetch(config.punsook_shop_url + 'member/insurance/get', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: user['shop_token'],
                id: oid.toString()
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                // console.log(result)
                if (result.responsecode === 0) {
                    setDetails(result);
                    setPage('detail');
                } else {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `${result.responsecode}! ไม่สามารถดึงข้อมูลรายการได้ กรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: `ไม่สามารถดึงข้อมูลรายการได้ กรุณาลองใหม่อีกครั้ง`,
                    icon: 'error',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            });
    };

    return (
        <div>
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='insurance-history-page'>
                    {page === 'main' ? (
                        <ShopInsuranceHistoryList
                            historys={historys}
                            initindex={initindex}
                            startindex={startindex}
                            endindex={endindex}
                            btnPrevious={btnPrevious}
                            btnNext={btnNext}
                            btnView={btnView}
                        />
                    ) : page === 'detail' ? (
                        <ShopInsuranceHistoryDetail btnBack={btnBack} details={details} />
                    ) : null}
                </div>
            )}
        </div>
    );
}
