import React, { useEffect, useState } from 'react';

import { NavLink } from 'react-router-dom';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import NumberFormat from 'react-number-format';

import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';

import config from '../../../environments/config';

export function ShopProductProduct(props) {
    const [ loading, setLoading ] = useState(false);
    const [ catloading, setCatLoading ] = useState(false);
    const [ actionpanel, setActionpanel ] = useState(false);
    const [ keyword, setKeyword ] = useState('');
    const [ catalogs, setCatalogs ] = useState({});
    const [ selectcatalogs, setSelectCatalogs ] = useState([]);
    const [ checkboxs, setCheckboxs ] = useState({});
    const [ products, setProducts ] = useState({});
    const initindex = 9;
    const [ startindex, setStartindex ] = useState(0);
    const [ endindex, setEndindex ] = useState(9);

    useEffect(() => {
        if (props.catid !== '') {
            getCatalogs({
                filters: {
                    parent_id: props.catid.toString()
                }
            });
            getProducts({
                filters: {
                    category_id: [ props.catid.toString() ]
                }
            });
        } else {
            if (props.keyword !== '') {
                getProducts(
                    {
                        search: props.keyword,
                        options: [ 'return_category' ]
                    },
                    true
                );
            } else {
                getCatalogs({});
                getProducts({});
            }
        }
    }, []);

    const handleCatalogs = (event, item, index) => {
        let checked = checkboxs;
        if (event.target.checked) {
            setSelectCatalogs([ ...selectcatalogs, item ]);
            checked[index] = true;
        } else {
            setSelectCatalogs(selectcatalogs.filter((catalog) => catalog.id !== item.id));
            checked[index] = false;
        }
        setCheckboxs(checked);
    };

    const btnSearch = () => {
        console.log(selectcatalogs);
        let param = {};
        if (keyword !== '') {
            param['search'] = keyword;
        }
        if (selectcatalogs.length > 0) {
            param['filters'] = {
                category_id: []
            };
            for (const index in selectcatalogs) {
                // console.log(selectcatalogs[index]['id']);
                param['filters']['category_id'].push(selectcatalogs[index]['id'].toString());
            }
            // if (props.catid !== '') {
            //     param['filters'] = {
            //         category_id: [ props.catid.toString() ]
            //     };
            // }
        } else {
            if (props.catid !== '') {
                param['filters'] = {
                    category_id: [ props.catid.toString() ]
                };
            }
        }

        getProducts(param);
    };

    const btnClear = () => {
        setSelectCatalogs([]);
        setKeyword('');
        let checked = checkboxs;
        for (const index in checkboxs) {
            checked[index] = false;
        }
        setCheckboxs(checked);
    };

    const btnPrevious = (e) => {
        e.preventDefault();
        setStartindex(startindex - initindex);
        setEndindex(endindex - initindex);
    };

    const btnNext = (e) => {
        e.preventDefault();
        setStartindex(startindex + initindex);
        setEndindex(endindex + initindex);
    };

    const getCatalogs = (param) => {
        setCatLoading(true);
        fetch(config.punsook_shop_url + 'guest/category/find', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify(param)
        })
            .then((response) => response.json())
            .then((result) => {
                setCatLoading(false);
                // console.log(result);
                setCatalogs(result);
                if (result.responsecode === 0) {
                    let json = checkboxs;
                    for (const index in result.catalogs) {
                        json[index] = false;
                    }
                    setCheckboxs(json);
                }
            })
            .catch((error) => {
                setCatLoading(false);
                // console.log(error);
            });
    };

    const getProducts = (param, cat = false) => {
        if (cat) {
            setCatLoading(true);
        }
        setLoading(true);
        fetch(config.punsook_shop_url + 'guest/product/find', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify(param)
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setProducts(result);
                if (cat) {
                    setCatLoading(false);
                    setCatalogs({
                        responsecode: 0,
                        catalogs: result.category
                    });
                    let json = checkboxs;
                    for (const index in result.category) {
                        json[index] = false;
                    }
                    setCheckboxs(json);
                }
            })
            .catch((error) => {
                setLoading(false);
                if (cat) {
                    setCatLoading(false);
                }
                // console.log(error);
            });
    };

    const addCart = (pid, amount) => {
        props.setMainLoading(true);
        fetch(config.punsook_shop_url + 'member/cart/add', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: props.user.shop_token,
                product_id: pid.toString(),
                quantity: amount
            })
        })
            .then((response) => response.json())
            .then((result) => {
                props.setMainLoading(false);
                props.setShowToast(true);
                // console.log(result);
                if (result.responsecode === 0) {
                    props.toast.success('เพิ่มสินค้าลงตะกร้าเรียบร้อยแล้ว', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined
                    });
                } else {
                    props.toast.warn(`${result.responsecode}! ไม่สามารถเพิ่มสินค้าลงตะกร้าได้ กรุณาลองใหม่อีกครั้ง`, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined
                    });
                }
                // setProducts(result);
            })
            .catch((error) => {
                props.setMainLoading(false);
                props.setShowToast(true);
                props.toast.error('ไม่สามารถเพิ่มสินค้าลงตะกร้าได้ กรุณาลองใหม่อีกครั้ง', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined
                });
            });
    };
    return (
        <div className='row'>
            {/* {props.user.shop_account === undefined ||
            props.user.shop_account === null ||
            props.user.shop_account === '' ? (
                <div className='col-12 text-right mb-7'>
                    <h6 className='d-inline'>
                        ใช้งานด้วยผู้ใช้งานทั่วไป{' '}
                        <span
                            className='d-inline text-danger text-hover-danger cursor-pointer'
                            onClick={props.btnSwitchAccount}
                        >
                            สมัครสมาชิก
                        </span>
                    </h6>
                </div>
            ) : (
                <div className='col-12 text-right mb-7'>
                    <h6 className='d-inline'>
                        ใช้งานด้วยเบอร์ {props.user.shop_account}{' '}
                        <span
                            className='d-inline text-danger text-hover-danger cursor-pointer'
                            onClick={props.btnSwitchAccount}
                        >
                            เปลี่ยน
                        </span>
                    </h6>
                </div>
            )} */}
            {/* <div className='col-12 text-right mb-7'>
                        <div className='form-group'>
                            <input type='text' className='form-control' />
                        </div>
                    </div> */}
            <div className='col-md-3'>
                <div className={`app-action-panel${actionpanel ? ' open' : ''}`} id='contacts-action-panel'>
                    <div className='action-panel-toggle' onClick={() => setActionpanel(!actionpanel)}>
                        <i className='fa fa-chevron-right' />
                        <i className='fa fa-chevron-left' />
                    </div>
                    <div
                        id='categories-list'
                        className='app-actions-list scrollable-container ps-container ps-theme-default p-5 bg-white'
                    >
                        <label className='font-size-h3 font-weight-bolder text-dark mb-7'>ค้นหา</label>
                        <div className='form-group'>
                            <input
                                type='text'
                                name='keyword'
                                className='form-control'
                                value={keyword}
                                onChange={(e) => setKeyword(e.target.value)}
                            />
                        </div>
                        <label className='font-size-h3 font-weight-bolder text-dark mb-7'>หมวดหมู่</label>
                        <div className='checkbox-list mb-7'>
                            {catloading ? (
                                <div className='row align-items-center col-12 p-0 m-0 min-h-300px'>
                                    <div className='text-center col-12 p-0'>
                                        <i className='text-dark-75 fas fa-spinner fa-pulse fa-5x' />
                                        <h1 className='col mt-5'>กรุณารอสักครู่</h1>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    {Object.keys(catalogs).length <= 0 ? (
                                        <div className='row align-items-center col-12 p-0 m-0 min-h-300px'>
                                            <div className='text-center col-12 p-0'>
                                                <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                                <h1 className='col mt-5'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h1>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {catalogs.responsecode === 101 ? (
                                                <div className='row align-items-center col-12 p-0 m-0 min-h-300px'>
                                                    <div className='text-center col-12 p-0'>
                                                        <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                                        <h1 className='col mt-5 font-size-h6'>
                                                            ไม่มีหมวดหมู่ในตอนนี้<br />กรุณาลองใหม่อีกครั้งภายหลัง
                                                        </h1>
                                                    </div>
                                                </div>
                                            ) : catalogs.responsecode !== 0 ? (
                                                <div className='row align-items-center col-12 p-0 m-0 min-h-300px'>
                                                    <div className='text-center col-12 p-0'>
                                                        <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                                        <h1 className='col mt-5'>
                                                            มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง
                                                        </h1>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    {catalogs.catalogs.map((object, index) => {
                                                        return (
                                                            <label className='checkbox checkbox-lg mb-7' key={index}>
                                                                <input
                                                                    type='checkbox'
                                                                    name={`cat-${object.id}`}
                                                                    onChange={(e) => handleCatalogs(e, object, index)}
                                                                    checked={checkboxs[index] ? true : false}
                                                                />
                                                                <span />
                                                                <div className='font-size-lg text-dark-75 font-weight-bold'>
                                                                    {object.title}
                                                                </div>
                                                                <div className='ml-auto text-muted font-weight-bold'>
                                                                    {object.count}
                                                                </div>
                                                            </label>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        {catalogs.responsecode === 0 && (
                            <button
                                type='button'
                                className='btn btn-primary font-weight-bolder mr-2 px-8'
                                onClick={btnSearch}
                            >
                                ค้นหา
                            </button>
                        )}
                        {(keyword !== '' || selectcatalogs.length > 0) && (
                            <button
                                type='button'
                                className='btn btn-clear font-weight-bolder text-muted px-8'
                                onClick={btnClear}
                            >
                                ล้าง
                            </button>
                        )}
                        {/* <div className='list-group'>
                                <button type='button' className={`list-group-item active`}>
                                    <span>ผู้ใช้งานทั้งหมด</span>
                                    <span className='float-right'>
                                        xxx
                                    </span>
                                </button>
                            </div> */}
                        {/* <hr className='brake-line m-0 m-b-md' />
                            <div className='list-group h-400px'>
                                <div className='list-group'>
                                    <button type='button' className={`list-group-item active`}>
                                        <div className='item-data row m-0'>
                                            <span className='label-text row col-7 m-0 p-0'>
                                                <span className='col-12 p-0'>xx</span>
                                                <span className='col-12 p-0 text-muted overflow-hidden text-nowrap w-100'>
                                                    xx
                                                </span>
                                            </span>
                                            <span className='text-right pull-right col-5 p-0 my-auto'>x</span>
                                        </div>
                                    </button>
                                </div>
                                <button type='button' className='list-group-item text-color'>
                                    <i className='fa fa-plus m-r-sm' /> เพิ่มบทบาท
                                </button>
                            </div> */}
                    </div>
                    <div className='m-h-md' />
                </div>
            </div>
            <div className='col-md-9'>
                <div className='d-flex justify-content-between align-items-center mb-7'>
                    <h2 className='font-weight-bolder text-dark font-size-h3 mb-0'>สินค้าปันสุข</h2>
                </div>
                {loading ? (
                    // <div className='d-flex flex-column flex-root' id='loading-section'>
                    //     <div
                    //         className='row justify-content-center align-items-center overlay p-0 m-0'
                    //         id='overlay'
                    //     >
                    //         <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                    //             <div className='bounce col-12'>
                    //                 <div className='bounce1' />
                    //                 <div className='bounce2' />
                    //                 <div className='bounce3' />
                    //             </div>
                    //             <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                    //         </div>
                    //     </div>
                    // </div>
                    <div className='row align-items-center col-12 p-0 m-0 min-h-500px'>
                        <div className='text-center col-12 p-0'>
                            <i className='text-dark-75 fas fa-spinner fa-pulse fa-5x' />
                            <h1 className='col mt-5'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                ) : (
                    <div>
                        {Object.keys(products).length <= 0 ? (
                            <div className='row align-items-center col-12 p-0 m-0 min-h-500px'>
                                <div className='text-center col-12 p-0'>
                                    <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                    <h1 className='col mt-5'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h1>
                                </div>
                            </div>
                        ) : (
                            <div>
                                {products.responsecode === 101 ? (
                                    <div className='row align-items-center col-12 p-0 m-0 min-h-500px'>
                                        <div className='text-center col-12 p-0'>
                                            <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                            <h1 className='col mt-5'>ไม่พบสินค้า กรุณาลองใหม่อีกครั้ง</h1>
                                        </div>
                                    </div>
                                ) : products.responsecode === 102 ? (
                                    <div className='row align-items-center col-12 p-0 m-0 min-h-500px'>
                                        <div className='text-center col-12 p-0'>
                                            <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                            <h1 className='col mt-5'>
                                                ไม่พบสินค้าในตอนนี้ กรุณาลองใหม่อีกครั้งภายหลัง
                                            </h1>
                                        </div>
                                    </div>
                                ) : products.responsecode !== 0 ? (
                                    <div className='row align-items-center col-12 p-0 m-0 min-h-500px'>
                                        <div className='text-center col-12 p-0'>
                                            <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                            <h1 className='col mt-5'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h1>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='row'>
                                        {products.products.slice(startindex, endindex).map((object, index) => {
                                            return (
                                                <div className='col-md-4 col-xxl-4 col-lg-4 col-6' key={index}>
                                                    <div className='card card-custom card-shadowless'>
                                                        <div className='card-body p-0'>
                                                            <div className='overlay'>
                                                                <div className='overlay-wrapper rounded bg-light text-center'>
                                                                    <img
                                                                        src={
                                                                            Object.keys(object.gallery).length >
                                                                            0 ? object.gallery.cover_image !== '' ? (
                                                                                `data:image/png;base64,${object.gallery
                                                                                    .cover_image}`
                                                                            ) : (
                                                                                toAbsoluteUrl(
                                                                                    `/media/products/${index + 1}.png`
                                                                                )
                                                                            ) : (
                                                                                toAbsoluteUrl(
                                                                                    `/media/products/${index + 1}.png`
                                                                                )
                                                                            )
                                                                        }
                                                                        alt={`product-${object.code}`}
                                                                        className='mw-100 w-100'
                                                                    />
                                                                </div>
                                                                <div className='overlay-layer'>
                                                                    <NavLink
                                                                        to={`/shop/productdetail/${object.code}`}
                                                                        target={'_blank'}
                                                                        className='btn font-weight-bolder btn-sm btn-primary mr-2'
                                                                    >
                                                                        รายละเอียดสินค้า
                                                                    </NavLink>
                                                                    {props.user.shop_account !== undefined &&
                                                                    props.user.shop_account !== null &&
                                                                    props.user.shop_account !== '' && (
                                                                        <button
                                                                            type='button'
                                                                            className='btn font-weight-bolder btn-sm btn-light-primary'
                                                                            onClick={() => addCart(object.id, '1')}
                                                                        >
                                                                            เพิ่มไปยังตะกร้า
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className='text-center mt-5 mb-md-0 mb-lg-5 mb-md-0 mb-lg-5 mb-lg-0 mb-5 d-flex flex-column'>
                                                                <OverlayTrigger
                                                                    placement={'bottom'}
                                                                    overlay={<Tooltip>{object.name}</Tooltip>}
                                                                >
                                                                    <div className='font-size-h5 font-weight-bolder overflow-hidden text-nowrap text-dark-75 mb-1 px-2'>
                                                                        {object.name}
                                                                    </div>
                                                                </OverlayTrigger>
                                                                <OverlayTrigger
                                                                    placement={'bottom'}
                                                                    overlay={<Tooltip>{object.description}</Tooltip>}
                                                                >
                                                                    <span className='font-size-lg overflow-hidden text-nowrap px-4'>
                                                                        {object.description}
                                                                    </span>
                                                                </OverlayTrigger>
                                                                <div className='font-size-h6 text-muted font-weight-bolder'>
                                                                    <NumberFormat
                                                                        value={parseFloat(object.price)}
                                                                        displayType={'text'}
                                                                        decimalScale={2}
                                                                        fixedDecimalScale={true}
                                                                        thousandSeparator={true}
                                                                        suffix={' บาท'}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        <div className='col-12 d-flex justify-content-between align-items-center flex-wrap'>
                                            <div className='d-flex flex-wrap py-2 mr-3'>
                                                {products.products.length > initindex && (
                                                    <button
                                                        type='button'
                                                        className='btn btn-icon btn-sm btn-light-danger mr-2 my-1'
                                                        onClick={btnPrevious}
                                                        disabled={endindex === initindex}
                                                    >
                                                        <i className='ki ki-bold-arrow-back icon-xs' />
                                                    </button>
                                                )}
                                                {products.products.length > initindex && (
                                                    <button
                                                        type='button'
                                                        className='btn btn-icon btn-sm btn-light-danger mr-2 my-1'
                                                        onClick={btnNext}
                                                        disabled={endindex >= products.products.length}
                                                    >
                                                        <i className='ki ki-bold-arrow-next icon-xs' />
                                                    </button>
                                                )}
                                            </div>
                                            <div className='d-flex align-items-center py-3'>
                                                <span className='text-muted'>
                                                    แสดง{' '}
                                                    {endindex >= products.products.length ? (
                                                        products.products.length
                                                    ) : (
                                                        endindex
                                                    )}{' '}
                                                    จาก {products.products.length} รายการ
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
