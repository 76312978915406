import React, { useEffect } from 'react';

import $ from 'jquery';

export function NumberBoxDetail(props) {
    useEffect(
        () => {
            $(document).ready(function() {
                var table = $('#kt_number_box_detail_table').DataTable({
                    data: props.detail,
                    lengthChange: false,
                    responsive: true,
                    // dom: "Bfrtip",
                    // buttons: ["excel"],
                    // order: [[0, "asc"]],
                    language: {
                        paginate: {
                            previous: 'ก่อนหน้า',
                            next: 'ถัดไป'
                        },
                        info: 'แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ',
                        infoEmpty: 'แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ',
                        emptyTable: 'ไม่มีข้อมูลที่ต้องการค้นหาจากระบบ',
                        infoFiltered: '(ค้นหาจากทั้งหมด _MAX_ รายการ)',
                        zeroRecords: 'ไม่พบข้อมูลที่ต้องการค้นหาจากคำสำคัญ'
                    },
                    oLanguage: {
                        sSearch: 'คำสำคัญ'
                    },
                    columns: [
                        {
                            title: '#',
                            data: function(value) {
                                return '';
                                // if (value.type === "accumulated") {
                                //     return '<span class="badge badge-success mx-auto my-auto">คำนวณแล้ว</span>';
                                // } else if (value.type === "estimated") {
                                //     return '<span class="badge badge-secondary mx-auto my-auto">รอคำนวณ</span>';
                                // }
                            }
                        },
                        {
                            title: 'หมายเลขโทรศัพท์',
                            data: function(value) {
                                if (value.phonenumber !== null && value.phonenumber !== '') {
                                    return (
                                        value.phonenumber.slice(0, 3) +
                                        '-' +
                                        value.phonenumber.slice(3, 6) +
                                        '-' +
                                        value.phonenumber.slice(6)
                                    );
                                } else {
                                    return '-';
                                }
                            }
                        },
                        {
                            title: 'หมายเลขซิม',
                            data: function(value) {
                                if (value.iccid !== null && value.iccid !== '') {
                                    return value.iccid;
                                } else {
                                    return '-';
                                }
                            }
                        },
                        {
                            title: 'สถานะร้านค้า',
                            className: 'dt-body-center',
                            data: function(value) {
                                if (value.simpack_number_status !== null && value.simpack_number_status !== '') {
                                    if (value.simpack_number_status === 'pending') {
                                        return '<span class="badge badge-secondary mx-auto my-auto">รอการสั่งซื้อ</span>';
                                    } else if (value.simpack_number_status === 'canceled') {
                                        return '<span class="badge badge-danger mx-auto my-auto">ยกเลิกการซื้อขายแล้ว</span>';
                                    } else {
                                        return '<span class="badge badge-success mx-auto my-auto">สั่งซื้อเรียบร้อย</span>';
                                    }
                                } else {
                                    return '<span class="badge badge-danger mx-auto my-auto">ไม่พร้อมให้บริการ</span>';
                                }
                            }
                        },
                        {
                            title: 'สถานะซิม',
                            className: 'dt-body-center',
                            data: function(value) {
                                if (
                                    value.sim_status !== null &&
                                    value.sim_status !== '' &&
                                    value.sim_status !== 'NEW' &&
                                    value.sim_status !== 'REUSE'
                                ) {
                                    return '<span class="badge badge-success mx-auto my-auto">ลงทะเบียนเรียบร้อย</span>';
                                } else if (value.simpack_number_status === 'canceled') {
                                    return '<span class="badge badge-danger mx-auto my-auto">ยกเลิกการซื้อขายแล้ว</span>';
                                } else {
                                    return '<span class="badge badge-secondary mx-auto my-auto">รอการลงทะเบียน</span>';
                                }
                            }
                        }
                    ]
                });

                table
                    .on('order.dt search.dt', function() {
                        table.column(0, { search: 'applied', order: 'applied' }).nodes().each(function(cell, i) {
                            cell.innerHTML = i + 1;
                        });
                    })
                    .draw();
            });
        },
        [ props.detail ]
    );
    return (
        <div className='container clearfix p-0'>
            <div className='card rounded-lg'>
                <div className='card-header bg-secondary p-4 rounded-lg rounded-bottom-0'>
                    <div className='row justify-content-between m-0'>
                        <button type='button' className='btn btn-link-primary' onClick={props.btnBack}>
                            <i className='fas fa-angle-double-left icon-md py-2' />
                            ย้อนกลับ
                        </button>
                    </div>
                </div>
                <div className='card-body rounded-lg rounded-top-0'>
                    <table id='kt_number_box_detail_table' className='table table-striped table-bordered' />
                </div>
            </div>
        </div>
    );
}
