import React from 'react';

import { useFormik } from 'formik';

import * as Yup from 'yup';

import InputMask from 'react-input-mask';

export function ShopInsuranceForm(props) {
    Yup.addMethod(Yup.string, 'validateCitizenid', function(errorMessage) {
        return this.test(`test-citizenid-format`, errorMessage, function(value) {
            const { path, createError } = this;
            if (value && value.length >= 13) {
                let sum = 0;
                for (let i = 0; i < 12; i++) {
                    sum += parseFloat(value.charAt(i)) * (13 - i);
                }
                if ((11 - sum % 11) % 10 != parseFloat(value.charAt(12))) {
                    return createError({ path, message: errorMessage });
                } else {
                    return value;
                }
            }
        });
    });

    Yup.addMethod(Yup.string, 'validateBirthdateLength', function(errorMessage) {
        return this.test(`test-birthdate-length`, errorMessage, function(value) {
            const { path, createError } = this;
            let emptydigit = value.includes('_');
            if (emptydigit) {
                return createError({ path, message: errorMessage });
            } else {
                return value;
            }
        });
    });

    Yup.addMethod(Yup.string, 'validateBirthdate', function(errorMessage) {
        return this.test(`test-birthdate-format`, errorMessage, function(value) {
            const { path, createError } = this;
            let birthdatelist = value.split('/');
            if (parseInt(birthdatelist[1]) > 12 || parseInt(birthdatelist[1]) < 0) {
                return createError({ path, message: errorMessage });
            } else {
                let maxdate = new Date(parseInt(birthdatelist[2]), parseInt(birthdatelist[1]), 0).getDate();
                if (parseInt(birthdatelist[0]) > maxdate || parseInt(birthdatelist[0]) < 0) {
                    return createError({ path, message: errorMessage });
                } else {
                    return value;
                }
            }
        });
    });

    Yup.addMethod(Yup.string, 'validateBirthdateAge', function(errorMessage) {
        return this.test(`test-birthdate-age`, errorMessage, function(value) {
            const { path, createError } = this;
            let birthdatelist = value.split('/');
            let newBirthday = birthdatelist[2] + '-' + birthdatelist[1] + '-' + birthdatelist[0];
            let datelist = newBirthday.split('-');
            if (parseInt(datelist[2]) === 0 || parseInt(datelist[1]) === 0) {
                if (parseInt(datelist[0]) === 0) {
                    return value;
                }
                let today = new Date();
                today.toLocaleString('th-TH', { timeZone: 'Asia/Bangkok' });
                let nowyear = today.getFullYear();
                let birth = new Date(datelist[0] + '-' + '01-01');
                birth.toLocaleString('th-TH', { timeZone: 'Asia/Bangkok' });
                let birthyear = birth.getFullYear();
                let age = nowyear - birthyear;
                if (age > 65) {
                    return createError({ path, message: errorMessage });
                }
                if (age < 16) {
                    return createError({ path, message: errorMessage });
                }
                return value;
            }
            let today = new Date();
            today.toLocaleString('th-TH', { timeZone: 'Asia/Bangkok' });
            let nowyear = today.getFullYear();
            let nowmonth = today.getMonth();
            let nowday = today.getDate();
            let birth = new Date(newBirthday);
            birth.toLocaleString('th-TH', { timeZone: 'Asia/Bangkok' });
            let birthyear = birth.getFullYear();
            let birthmonth = birth.getMonth();
            let birthday = birth.getDate();
            let age = nowyear - birthyear;
            if (age > 65) {
                return createError({ path, message: errorMessage });
            }
            if (age < 16) {
                return createError({ path, message: errorMessage });
            }
            if (age === 16) {
                if (nowmonth < birthmonth) {
                    return createError({ path, message: errorMessage });
                }
                if (nowmonth === birthmonth) {
                    if (nowday < birthday) {
                        return createError({ path, message: errorMessage });
                    }
                }
            }
            return value;
        });
    });

    const initialValues = {
        firstname: props.firstname,
        lastname: props.lastname,
        citizenid: props.citizenid,
        birthdate: props.birthdate,
        beneficiary: props.beneficiary
    };

    const FormSchema = Yup.object().shape({
        firstname: Yup.string().required('กรุณากรอกชื่อ'),
        lastname: Yup.string().required('กรุณากรอกนามสกุล'),
        citizenid: Yup.string()
            .min(13, 'กรุณากรอกหมายเลขบัตรประชาชนให้ครบ 13 หลัก')
            .required('กรุณากรอกหมายเลขบัตรประชาชน')
            .validateCitizenid('กรุณากรอกหมายเลขบัตรประชาชนให้ถูกต้อง'),
        birthdate: Yup.string()
            .validateBirthdateLength('กรุณากรอกวัน/เดือน/ปีเกิดให้ครบถ้วน')
            .required('กรุณากรอกวัน/เดือน/ปีเกิด')
            .validateBirthdate('กรุณากรอกวันเกิดให้ถูกต้อง')
            .validateBirthdateAge('อายุของคุณไม่อยู่ระหว่าง 16 - 65 ปี'),
        beneficiary: Yup.string().required('กรุณากรอกผู้รับผลประโยชน์')
    });

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return 'is-invalid';
        }
        return '';
    };

    const formik = useFormik({
        initialValues,
        validationSchema: FormSchema,
        onSubmit: (values, { setSubmitting }) => {
            props.setLoading(true);
            props.setFirstname(values.firstname);
            props.setLastname(values.lastname);
            props.setCitizenid(values.citizenid);
            props.setBirthdate(values.birthdate);
            props.setBeneficiary(values.beneficiary);
            props.checkLimit('package', values.citizenid);
        }
    });

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength);
        }
    };

    return (
        <div className='col-lg-10 col-12 p-0'>
            <div className='card'>
                <div className='card-body'>
                    <div className='text-center mb-10'>
                        <h3 className='font-size-h1'>รายละเอียดผู้สมัครกรมธรรม์</h3>
                    </div>
                    <form onSubmit={formik.handleSubmit} className='form fv-plugins-bootstrap fv-plugins-framework'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='form-group'>
                                    <label>ชื่อ</label>
                                    <input
                                        type='text'
                                        name='firstname'
                                        className={`form-control form-control-lg ${getInputClasses('firstname')}`}
                                        readOnly
                                        {...formik.getFieldProps('firstname')}
                                    />
                                    {formik.touched.firstname && formik.errors.firstname ? (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.firstname}</div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='form-group'>
                                    <label>นามสกุล</label>
                                    <input
                                        type='text'
                                        name='lastname'
                                        className={`form-control form-control-lg ${getInputClasses('lastname')}`}
                                        readOnly
                                        {...formik.getFieldProps('lastname')}
                                    />
                                    {formik.touched.lastname && formik.errors.lastname ? (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.lastname}</div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='form-group'>
                                    <label>หมายเลขบัตรประชาชน</label>
                                    <input
                                        type='text'
                                        name='citizenid'
                                        className={`form-control form-control-lg ${getInputClasses('citizenid')}`}
                                        onKeyPress={(e) => {
                                            if (!/[0-9]/.test(e.key)) {
                                                if (e.key !== 'Enter') {
                                                    e.preventDefault();
                                                }
                                            }
                                        }}
                                        maxLength='13'
                                        onInput={maxLengthCheck}
                                        readOnly
                                        {...formik.getFieldProps('citizenid')}
                                    />
                                    {formik.touched.citizenid && formik.errors.citizenid ? (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.citizenid}</div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='form-group'>
                                    <label>
                                        วัน/เดือน/ปีเกิด (ปีคริสต์ศักราช)<span className='required-text'>*</span>
                                    </label>
                                    <InputMask
                                        mask='99/99/9999'
                                        alwaysShowMask={true}
                                        name='birthdate'
                                        inputMode='tel'
                                        className={`form-control form-control-lg ${getInputClasses('birthdate')}`}
                                        {...formik.getFieldProps('birthdate')}
                                    />
                                    {formik.touched.birthdate && formik.errors.birthdate ? (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.birthdate}</div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='form-group'>
                                    <label>อาชืพ</label>
                                    <div className='radio-inline form-control form-control-lg border-0 px-0'>
                                        <label className='radio radio-success'>
                                            <input
                                                type='radio'
                                                name='national'
                                                defaultValue='ดีลเลอร์'
                                                checked={props.occupation === '1'}
                                                onChange={() => props.setOccupation('1')}
                                            />
                                            <span />
                                            ดีลเลอร์
                                        </label>
                                        {/* <label className='radio radio-success'>
                                            <input
                                                type='radio'
                                                name='national'
                                                defaultValue='อื่นๆ'
                                                checked={props.occupation === '2'}
                                                onChange={() => props.setOccupation('2')}
                                            />
                                            <span />
                                            อื่นๆ
                                        </label> */}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='form-group'>
                                    <label>
                                        ผู้รับผลประโยชน์<span className='required-text'>*</span>
                                    </label>
                                    <input
                                        type='text'
                                        name='beneficiary'
                                        className={`form-control form-control-lg ${getInputClasses('beneficiary')}`}
                                        {...formik.getFieldProps('beneficiary')}
                                    />
                                    {formik.touched.beneficiary && formik.errors.beneficiary ? (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.beneficiary}</div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        <div className='text-center'>
                            <button type='submit' className='btn btn-primary font-weight-bold px-9 py-4 my-3'>
                                ถัดไป
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
