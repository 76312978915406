import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { toAbsoluteUrl } from '../../_metronic/_helpers';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { FormattedMessage, injectIntl } from 'react-intl';
import 'react-toastify/dist/ReactToastify.css';
import * as auth from '../modules/Auth/_redux/authRedux';
import SVG from 'react-inlinesvg';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import NumberFormat from 'react-number-format';
import config from './environments/config';
import { SubscriberList } from '../Setting';

const reg_url = 'https://reg.simk4.com/';
const training_url = 'https://training.simk4.com/vsp?id=';

function Dashboard(props) {
    const [ loading, setLoading ] = useState(true);
    const [ error, setError ] = useState(false);
    const [ allcommission, setAllCommission ] = useState('0');
    const [ dailycommission, setDailyCommission ] = useState('0');
    const [ commission, setCommission ] = useState('0');
    const [ promolist, setPromoList ] = useState({});
    const [ cardnumberlist, setCardNumberList ] = useState([]);
    const [ active, setActive ] = useState(null);
    const [ unactive, setUnactive ] = useState([]);
    const [ name, setName ] = useState('');
    const [ profileimg, setProfileimg ] = useState('');

    const navigate = (id) => {
        setActive(id);
    };

    const [ showModal, setShow ] = useState(false);
    const [ showPromoModal, setShowPromo ] = useState(false);
    const [ showShareModal, setShowShare ] = useState(false);
    const [ showBannerModal, setBannerShow ] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleBannerClose = () => setBannerShow(false);

    const handleClosePromo = () => setShowPromo(false);
    const handleShowPromo = () => setShowPromo(true);

    const handleCloseShare = () => {
        setShowShare(false);
        setActive(null);
    };
    const handleShowShare = () => setShowShare(true);

    const ref = useRef(null);

    const scroll = (size, option) => {
        const osInstance = ref.current.osInstance();
        if (osInstance) {
            osInstance.scrollStop().scroll(
                {
                    x: `${option}= ${size}px`
                },
                1000,
                'easeOutCubic'
            );
        }
    };

    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        getInfo();
    }, []);

    const showToast = () => {
        toast.error('ไม่สามารถดึงข้อมูลได้ชั่วคราว กรุณาลองใหม่อีกครั้ง', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            icon: false
        });
    };

    const copyCoupon = (promocode) => {
        var copyText = document.getElementById('promo_' + promocode);
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand('copy');
    };

    const copyLink = (text, type) => {
        var copyText = document.getElementById('link' + type + '_' + text);
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand('copy');
    };

    const getInfo = () => {
        fetch(config.py_url + config.version + '/dashboard/info', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                dealerid: user.dealerid
            })
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.responsecode === 0) {
                    setAllCommission(result.totalcom);
                    setDailyCommission(result.todaycom);
                    setCommission(result.combalance);
                    getPromo();
                } else {
                    setLoading(false);
                    setError(true);
                    showToast();
                }
            })
            .catch((error) => {
                setLoading(false);
                setError(true);
                showToast();
            });
    };

    const getPromo = () => {
        fetch(config.shop_url + '/feelsshop/getcoupon', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                options: [ 'available_only' ]
            })
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.responsecode === '0') {
                    setPromoList(result.result);
                }
                getNumberList(user.id);
            })
            .catch((error) => {
                setLoading(false);
                setError(true);
                showToast();
            });
    };

    const getNumberList = (memberid) => {
        fetch(config.py_url + config.version + '/profile/phonenumber_list', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                userid: memberid
            })
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.responsecode === 0) {
                    setCardNumberList(result.numbers);
                    let unactivelist = [];
                    for (let prop in result.numbers) {
                        if (result.numbers[prop]['feels_kyc'] === 0) {
                            unactivelist = [ ...unactivelist, result.numbers[prop] ];
                        }
                    }
                    setUnactive(unactivelist);
                    getProfileInfo(memberid);
                } else {
                    setLoading(false);
                    setError(true);
                    showToast();
                }
            })
            .catch((error) => {
                setLoading(false);
                setError(true);
                showToast();
            });
    };

    const getProfileInfo = (memberid) => {
        fetch(config.py_url + config.version + '/profile/info', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                userid: memberid
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    let newUser = user;
                    newUser['main_position'] = result.mainnumber.position;
                    props.newLogin(newUser);
                    setName(result.profile.name);
                    setProfileimg(result.profile.image !== '' ? result.profile.image : '');
                } else {
                    setError(true);
                    showToast();
                }
            })
            .catch((error) => {
                setLoading(false);
                setError(true);
                showToast();
            });
    };

    return (
        <div>
            {!loading ? (
                <div>
                    {error && <ToastContainer theme='colored' />}
                    <div className='row'>
                        <div className='col-xl-12'>
                            <div className='card card-custom gutter-b card-stretch card-shadowless'>
                                <div className='card-body p-0 d-flex'>
                                    <div className='d-flex align-items-start justify-content-start flex-grow-1 bg-light-warning p-8 card-rounded flex-grow-1 position-relative'>
                                        <div className='d-flex flex-column align-items-start flex-grow-1 h-100'>
                                            <div className='p-1 flex-grow-1'>
                                                <h4 className='text-warning font-weight-bolder'>
                                                    <FormattedMessage id='DASHBOARD.WELCOME' />
                                                </h4>
                                                <p className='text-dark-50 font-weight-bold mt-3'>เมืองปันสุข</p>
                                            </div>
                                        </div>
                                        <div className='position-absolute right-0 bottom-0 mr-5 overflow-hidden'>
                                            <img
                                                src={toAbsoluteUrl('/media/logos/new-logo.png')}
                                                className='max-h-200px max-h-xl-275px mb-n20'
                                                alt='punsook-logo'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-xl-8'>
                            <div className='card card-custom card-stretch gutter-b'>
                                <div className='card-body'>
                                    <div className='d-flex justify-content-between flex-column pt-4 h-100'>
                                        <div className='pb-5'>
                                            <div className='d-flex flex-column flex-center'>
                                                <div className='text-right col-12 p-0 mb-3'>
                                                    <button
                                                        className='btn btn-icon btn-clean btn-lg mb-1 position-relative border-3 border-secondary'
                                                        onClick={(e) => {
                                                            if (!error) {
                                                                handleShowShare();
                                                            } else {
                                                                return false;
                                                            }
                                                        }}
                                                        disabled={error}
                                                    >
                                                        <span className='svg-icon svg-icon-lg'>
                                                            <SVG
                                                                title='แชร์'
                                                                src={toAbsoluteUrl(
                                                                    '/media/svg/icons/Communication/Share.svg'
                                                                )}
                                                            />
                                                        </span>
                                                    </button>
                                                    <button
                                                        className='btn btn-icon btn-clean btn-lg mb-1 position-relative ml-3 border-3 border-secondary'
                                                        onClick={(e) => {
                                                            if (!error) {
                                                                handleShowPromo();
                                                            } else {
                                                                return false;
                                                            }
                                                        }}
                                                        disabled={error}
                                                    >
                                                        <span className='svg-icon svg-icon-lg'>
                                                            <SVG
                                                                title='โปรโมชั่น'
                                                                src={toAbsoluteUrl(
                                                                    '/media/svg/icons/Shopping/Sale2.svg'
                                                                )}
                                                            />
                                                        </span>
                                                        <span className='label label-sm label-light-danger label-rounded font-weight-bolder position-absolute top-0 right-0 mt-1 mr-1'>
                                                            {Object.keys(promolist).length}
                                                        </span>
                                                    </button>
                                                </div>
                                                <div className='symbol symbol-120 symbol-circle symbol-secondary overflow-hidden'>
                                                    <span className='symbol-label'>
                                                        <img
                                                            src={
                                                                profileimg === '' ? (
                                                                    toAbsoluteUrl('/media/users/blank.png')
                                                                ) : (
                                                                    `data:image/png;base64,${profileimg}`
                                                                )
                                                            }
                                                            className='h-100 w-100 align-self-end img-cover'
                                                            alt='user icon'
                                                        />
                                                    </span>
                                                </div>
                                                <p className='card-title font-weight-bolder text-dark-75 font-size-h4 m-0 pt-7 pb-1'>
                                                    {name}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-4'>
                            <div className='card card-custom bg-light-success gutter-b card-stretch card-shadowless'>
                                <div className='card-header border-0'>
                                    <h3 className='card-title font-weight-bolder text-success'>
                                        เบอร์ที่คุณเข้าร่วมโครงการ
                                    </h3>
                                    {unactive.length > 0 && (
                                        <span
                                            className='label label-lg label-inline font-weight-bolder label-primary my-auto cursor-pointer'
                                            onClick={handleShow}
                                        >
                                            ใหม่ ({unactive.length})
                                        </span>
                                    )}
                                </div>
                                <div className='card-body pt-2'>
                                    <OverlayScrollbarsComponent className='sim-stock-height'>
                                        <div className='pt-3 pb-3 pl-5 pr-5 sim-stock-bg min-h-200px'>
                                            {!error ? (
                                                cardnumberlist.map((object, index) => {
                                                    if (object.position !== '') {
                                                        if (object.feels_kyc === 1) {
                                                            return (
                                                                <div
                                                                    className='d-flex align-items-center mt-5 mb-5'
                                                                    key={index}
                                                                >
                                                                    <div className='symbol symbol-40 symbol-light-white mr-5'>
                                                                        <div className='symbol-label'>
                                                                            <i className='fas fa-sim-card fa-2x' />
                                                                        </div>
                                                                    </div>
                                                                    <div className='d-flex flex-column font-weight-bold'>
                                                                        <span className='text-dark'>
                                                                            {object.phonenumber}
                                                                        </span>
                                                                        <span className='text-dark'>
                                                                            กำหนดรักษาสิทธิ์
                                                                            {' ' + object.preserve_date}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    }
                                                })
                                            ) : (
                                                <div className='d-flex align-items-center mt-5 mb-5'>
                                                    <div className='symbol symbol-40 symbol-light-white mr-5'>
                                                        <div className='symbol-label'>
                                                            <i className='fas fa-sim-card fa-2x' />
                                                        </div>
                                                    </div>
                                                    <div className='d-flex flex-column font-weight-bold'>
                                                        <span className='text-dark'>-</span>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </OverlayScrollbarsComponent>
                                </div>
                            </div>
                        </div>
                    </div>

                    {!SubscriberList.includes(user.main_position) && (
                        <div className='row'>
                            <div className='col-xl-4'>
                                <div className='card card-custom bgi-no-repeat card-stretch gutter-b dashboard-allcommission-card bg-light-info'>
                                    <div className='card-body'>
                                        <h4 className='card-title font-weight-bold font-size-h5'>คอมมิชชั่นทั้งหมด</h4>
                                        <p className='text-dark-75 font-weight-bolder font-size-h5 m-0'>
                                            {!error ? (
                                                <NumberFormat
                                                    value={parseFloat(allcommission)}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                />
                                            ) : (
                                                '-'
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-4'>
                                <div className='card card-custom bgi-no-repeat card-stretch gutter-b dashboard-dailycommission-card bg-light-danger'>
                                    <div className='card-body'>
                                        <h4 className='card-title font-weight-bold font-size-h5'>
                                            คอมมิชชั่นเบื้องต้นวันนี้
                                        </h4>
                                        <p className='text-dark-75 font-weight-bolder font-size-h5 m-0'>
                                            {!error ? (
                                                <NumberFormat
                                                    value={parseFloat(dailycommission)}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                />
                                            ) : (
                                                '-'
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-4'>
                                <div className='card card-custom bgi-no-repeat card-stretch gutter-b dashboard-commission-card bg-light-success'>
                                    <div className='card-body'>
                                        <h4 className='card-title font-weight-bold font-size-h5'>
                                            คอมมิชชั่นที่ใช้ได้
                                        </h4>
                                        <p className='text-dark-75 font-weight-bolder font-size-h5 m-0'>
                                            {!error ? (
                                                <NumberFormat
                                                    value={parseFloat(commission)}
                                                    displayType={'text'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    thousandSeparator={true}
                                                />
                                            ) : (
                                                '-'
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            )}

            <Modal show={showModal} onHide={handleClose} size='lg' centered aria-labelledby='unactive-number-modal'>
                <Modal.Header closeButton>
                    <Modal.Title id='unactive-number-modal' className='col-11 p-0'>
                        เบอร์ที่ยังไม่ลงทะเบียน
                    </Modal.Title>
                    <button type='button' className='close col-1 text-right pr-3' onClick={handleClose}>
                        <i className='ki ki-close' />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row' id='wallet-info'>
                        <div className='cards-menu w-100'>
                            <div className='card-list testimonial-group'>
                                <div className='text-right d-none d-md-block'>
                                    <button className='btn btn-secondary mr-3' onClick={() => scroll('200', '-')}>
                                        <i className='fas fa-chevron-left' />
                                    </button>
                                    <button className='btn btn-secondary' onClick={() => scroll('200', '+')}>
                                        <i className='fas fa-chevron-right' />
                                    </button>
                                </div>
                                <div className='inline-scroll'>
                                    <OverlayScrollbarsComponent className='wv-100 h-auto' ref={ref}>
                                        {unactive.map((object, index) => {
                                            if (object.position !== '') {
                                                return (
                                                    <div className='inline-card' key={index}>
                                                        <a
                                                            className='card cursor-pointer min-height'
                                                            href={`${reg_url}${object.account === 'dealer'
                                                                ? 'onlinesim/agreement'
                                                                : 'feels-sim/force/register'}?id=${user.id}&mobile=${object.phonenumber}&serialno=${object.iccid}`}
                                                            target='_blank'
                                                            rel='noopener noreferrer'
                                                        >
                                                            <div className='symbol symbol-40 symbol-light-white mr-5'>
                                                                <div className='symbol-label'>
                                                                    <i className='fas fa-sim-card fa-2x' />
                                                                </div>
                                                            </div>
                                                            <p className='card-number'>{object.phonenumber}</p>
                                                            <p className='validity-date p-0'>
                                                                กำหนดรักษาสิทธิ์: <span>{object.preserve_date}</span>
                                                            </p>
                                                            <p className='validity-date p-0 mb-0'>
                                                                <span className='menu-label'>
                                                                    <span className='label label-warning label-inline'>
                                                                        ยังไม่ลงทะเบียนซิม
                                                                    </span>
                                                                </span>
                                                            </p>
                                                        </a>
                                                    </div>
                                                );
                                            } else {
                                                return (
                                                    <div className='inline-card' key={index}>
                                                        <div className='card cursor-disable card-disable'>
                                                            <div className='symbol symbol-40 symbol-light-white mr-5'>
                                                                <div className='symbol-label'>
                                                                    <i className='fas fa-sim-card fa-2x' />
                                                                </div>
                                                            </div>
                                                            <p className='card-number'>{object.phonenumber}</p>
                                                            <p className='validity-date p-0 mb-0'>
                                                                <span className='menu-label'>
                                                                    <span className='label label-danger label-inline'>
                                                                        ไม่พร้อมใช้งาน
                                                                    </span>
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        })}
                                    </OverlayScrollbarsComponent>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={showPromoModal}
                onHide={handleClosePromo}
                size='lg'
                centered
                scrollable
                aria-labelledby='promo-modal'
                id='promo-modal'
            >
                <Modal.Header closeButton>
                    <div className='row p-0 m-0 col-12'>
                        <Modal.Title id='promo-modal' className='col-6 p-0'>
                            โปรโมชั่น
                        </Modal.Title>
                        <div className='col-6 p-0 text-right'>
                            <button
                                type='button'
                                className='btn btn-light-danger font-weight-bold mr-3'
                                onClick={handleClosePromo}
                            >
                                ปิด
                            </button>
                            <a
                                href='https://shop.feels.co.th'
                                className='btn btn-light-success font-weight-bold'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                ไปยังร้านค้า
                            </a>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {Object.keys(promolist).length > 0 ? (
                        <div className='testimonial-group'>
                            <div className='text-right mb-5 d-none d-md-block'>
                                <button className='btn btn-secondary mr-3' onClick={() => scroll('200', '-')}>
                                    <i className='fas fa-chevron-left' />
                                </button>
                                <button className='btn btn-secondary' onClick={() => scroll('200', '+')}>
                                    <i className='fas fa-chevron-right' />
                                </button>
                            </div>
                            <div className='inline-scroll'>
                                <OverlayScrollbarsComponent ref={ref}>
                                    <div className='row flex-nowrap'>
                                        {Object.values(promolist).map((object, index) => {
                                            return (
                                                <div className='col-xl-6 col-12' key={index}>
                                                    <div className='dl'>
                                                        <div className='brand'>
                                                            <h2>คูปองส่วนลด</h2>
                                                        </div>
                                                        <div
                                                            className={`discount ${object.discount_type === 'value'
                                                                ? 'peter-river'
                                                                : 'amethyst'}`}
                                                        >
                                                            {object.discount}
                                                            <div className='type'>
                                                                {object.discount_type === 'value' ? (
                                                                    'บาท'
                                                                ) : (
                                                                    'เปอร์เซ็นต์'
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className='descr row'>
                                                            <div className='col-12'>
                                                                <strong>{object.coupon_description}</strong>
                                                            </div>
                                                        </div>
                                                        <div className='ends'>
                                                            <strong>ใช้ได้ถึง {object.expire_date}</strong>
                                                        </div>
                                                        <div className='coupon midnight-blue'>
                                                            <div className='code input-group input-group-solid'>
                                                                <input
                                                                    type='text'
                                                                    defaultValue={object.coupon_code}
                                                                    className='form-control'
                                                                    id={`promo_${object.coupon_code}`}
                                                                    readOnly
                                                                />
                                                                <div className='input-group-append'>
                                                                    <button
                                                                        className='btn btn-success'
                                                                        onClick={() => copyCoupon(object.coupon_code)}
                                                                    >
                                                                        คัดลอก <i className='far fa-copy' />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </OverlayScrollbarsComponent>
                            </div>
                        </div>
                    ) : (
                        <div className='row'>
                            <div className='col-12'>
                                <div className='row align-items-center col-12 p-0 m-0 min-h-200px'>
                                    <div className='text-center col-12 p-0'>
                                        <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                        <h1 className='col mt-5'>ไม่พบโปรโมชั่นในขณะนี้ กรุณาลองใหม่ภายหลัง</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Modal.Body>
            </Modal>

            <Modal
                show={showShareModal}
                onHide={handleCloseShare}
                size='lg'
                centered
                aria-labelledby='unactive-number-modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id='unactive-number-modal' className='col-11 p-0'>
                        ลิงค์ต่างๆ
                    </Modal.Title>
                    <button type='button' className='close col-1 text-right pr-3' onClick={handleCloseShare}>
                        <i className='ki ki-close' />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row' id='wallet-info'>
                        <div className='cards-menu w-100'>
                            <div className='card-list testimonial-group'>
                                <div className='text-right d-none d-md-block'>
                                    <button className='btn btn-secondary mr-3' onClick={() => scroll('200', '-')}>
                                        <i className='fas fa-chevron-left' />
                                    </button>
                                    <button className='btn btn-secondary' onClick={() => scroll('200', '+')}>
                                        <i className='fas fa-chevron-right' />
                                    </button>
                                </div>
                                <div className='inline-scroll'>
                                    <OverlayScrollbarsComponent className='wv-100' ref={ref}>
                                        {cardnumberlist.map((object, index) => {
                                            if (object.position !== '') {
                                                return (
                                                    <div
                                                        className='inline-card'
                                                        key={index}
                                                        onClick={() => navigate(object.phonenumber)}
                                                    >
                                                        <div
                                                            className={`card cursor-pointer ${active ===
                                                            object.phonenumber
                                                                ? 'sim-active'
                                                                : ''}`}
                                                        >
                                                            <div className='symbol symbol-40 symbol-light-white mr-5'>
                                                                <div className='symbol-label'>
                                                                    <i className='fas fa-sim-card fa-2x' />
                                                                </div>
                                                            </div>
                                                            <p className='card-number'>{object.phonenumber}</p>
                                                            <p className='validity-date p-0 mb-0'>
                                                                {object.feels_kyc === 1 ? (
                                                                    <div>
                                                                        กำหนดรักษาสิทธิ์:{' '}
                                                                        <span>{object.preserve_date}</span>
                                                                    </div>
                                                                ) : (
                                                                    <span className='menu-label'>
                                                                        <span className='label label-warning label-inline'>
                                                                            ยังไม่ลงทะเบียนซิม
                                                                        </span>
                                                                    </span>
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                );
                                            } else {
                                                return (
                                                    <div className='inline-card' key={index}>
                                                        <div className='card cursor-disable card-disable'>
                                                            <div className='symbol symbol-40 symbol-light-white mr-5'>
                                                                <div className='symbol-label'>
                                                                    <i className='fas fa-sim-card fa-2x' />
                                                                </div>
                                                            </div>
                                                            <p className='card-number'>{object.phonenumber}</p>
                                                            <p className='validity-date p-0 mb-0'>
                                                                {object.position !== '' ? (
                                                                    <span className='menu-label'>
                                                                        <span className='label label-warning label-inline'>
                                                                            ยังไม่ลงทะเบียนซิม
                                                                        </span>
                                                                    </span>
                                                                ) : (
                                                                    <span className='menu-label'>
                                                                        <span className='label label-danger label-inline'>
                                                                            ไม่พร้อมใช้งาน
                                                                        </span>
                                                                    </span>
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        })}
                                    </OverlayScrollbarsComponent>
                                </div>
                            </div>
                        </div>
                        {active !== null && (
                            <div className='col-12'>
                                <div className='form-group'>
                                    <label className='font-size-h4 font-weight-bolder text-dark'>
                                        ลิงค์สำหรับสมัครเป็นตัวแทนจำหน่าย
                                    </label>
                                    <div className='input-group input-group-solid'>
                                        <input
                                            type='text'
                                            readOnly
                                            value={`${reg_url}agreement?mobile=${active}`}
                                            className='form-control'
                                            id={`linka_${active}`}
                                        />
                                        <div className='input-group-append'>
                                            <button
                                                type='button'
                                                className='btn btn-success'
                                                onClick={() => copyLink(active, 'a')}
                                            >
                                                คัดลอก <i className='far fa-copy' />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <label className='font-size-h4 font-weight-bolder text-dark'>
                                        ลิงค์สำหรับสมัครเป็นผู้ใช้งานทั่วไป
                                    </label>
                                    <div className='input-group input-group-solid'>
                                        <input
                                            type='text'
                                            readOnly
                                            value={`${reg_url}subscriber/register?mobile=${active}`}
                                            className='form-control'
                                            id={`linkc_${active}`}
                                        />
                                        <div className='input-group-append'>
                                            <button
                                                type='button'
                                                className='btn btn-success'
                                                onClick={() => copyLink(active, 'c')}
                                            >
                                                คัดลอก <i className='far fa-copy' />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='form-group'>
                                    <label className='font-size-h4 font-weight-bolder text-dark'>ลิงค์เซลเพจ</label>
                                    <div className='input-group input-group-solid'>
                                        <input
                                            type='text'
                                            readOnly
                                            value={`${training_url + active}`}
                                            className='form-control'
                                            id={`linkb_${active}`}
                                        />
                                        <div className='input-group-append'>
                                            <button
                                                type='button'
                                                className='btn btn-success'
                                                onClick={() => copyLink(active, 'b')}
                                            >
                                                คัดลอก <i className='far fa-copy' />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                show={showBannerModal}
                onHide={handleBannerClose}
                size='lg'
                centered
                aria-labelledby='banner-modal'
                className='banner-modal'
                // contentClassName='bg-transparent shadow-none'
            >
                <Modal.Header closeButton>
                    {/* <Modal.Title
                        id="banner-modal"
                        className="col-11 p-0"
                    >
                        เพิ่มข้อมูลหมายเลขพัสดุ
                    </Modal.Title> */}
                    <button type='button' className='close col-1 text-right pr-3 ml-auto' onClick={handleBannerClose}>
                        <i className='ki ki-close' />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row d-flex justify-content-center'>
                        {/* <div class='circle-close' onClick={handleClose}>
                            &times;
                        </div> */}
                        {/* <a href='https://form.jotform.com/212912570478459' target='_blank' rel='noopener noreferrer'> */}
                        <img src={toAbsoluteUrl('/media/banners/banner-241210.png')} className='w-100 h-100' />
                        {/* </a> */}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(Dashboard));
