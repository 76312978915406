import React from 'react';

import Moment from 'moment';

import 'moment/locale/th';

import NumberFormat from 'react-number-format';

export function ShopInsuranceHistoryList(props) {
    return (
        <div className='clearfix bg-white p-5'>
            {Object.keys(props.historys).length <= 0 ? (
                <div className='row justify-content-center align-items-center bg-white cart-box min-h-500px'>
                    <div className='col-12 text-center'>
                        <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                        <h3 className='col mt-5'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h3>
                    </div>
                </div>
            ) : (
                <div>
                    {props.historys.responsecode !== 0 ? (
                        <div className='row justify-content-center align-items-center bg-white cart-box min-h-500px'>
                            <div className='col-12 text-center'>
                                <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                <h3 className='col mt-5'>ไม่พบประวัติการสั่งซื้อของคุณ</h3>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <ul className='xsearch-items'>
                                {props.historys.info
                                    .slice(props.startindex, props.endindex)
                                    .map((object, index, arr) => {
                                        return (
                                            <div key={index}>
                                                <li className='search-item'>
                                                    <div className='search-item-content mx-7 pb-0'>
                                                        <h3 className='search-item-caption'>
                                                            <div className='text-primary d-inline-block'>
                                                                # {object.id}
                                                            </div>
                                                        </h3>
                                                        <div className='search-item-meta mb-5'>
                                                            <ul className='list-inline'>
                                                                <li className='time d-none d-lg-block'>
                                                                    <h6>
                                                                        วันที่:{' '}
                                                                        {Moment(new Date(object.create_date))
                                                                            .add(543, 'year')
                                                                            .locale('th')
                                                                            .format('DD/MMMM/YYYY HH:mm:ss')}{' '}
                                                                        | ยอดรวม:{' '}
                                                                        <NumberFormat
                                                                            value={parseFloat(object.total_gross_price)}
                                                                            displayType={'text'}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={true}
                                                                            thousandSeparator={true}
                                                                            suffix={' บาท'}
                                                                        />{' '}
                                                                        | การชำระเงิน:{' '}
                                                                        <span>
                                                                            <strong
                                                                                className={`${object.status ===
                                                                                'pending'
                                                                                    ? 'text-secondary'
                                                                                    : object.status === 'cancel'
                                                                                      ? 'text-warning'
                                                                                      : object.status === 'expired'
                                                                                        ? 'text-primary'
                                                                                        : 'text-success'}`}
                                                                            >
                                                                                {object.status === 'pending' ? (
                                                                                    'รอการชำระเงิน'
                                                                                ) : object.status === 'paid' ? (
                                                                                    'ชำระแล้ว'
                                                                                ) : object.status === 'cancel' ? (
                                                                                    'ยกเลิก'
                                                                                ) : object.status === 'expired' ? (
                                                                                    'หมดอายุ'
                                                                                ) : object.status === 'completed' ? (
                                                                                    'ยืนยันสินค้าแล้ว'
                                                                                ) : null}
                                                                            </strong>
                                                                        </span>
                                                                    </h6>
                                                                </li>
                                                                <li className='time d-block d-lg-none'>
                                                                    <h6>
                                                                        วันที่:{' '}
                                                                        {Moment(new Date(object.create_date))
                                                                            .add(543, 'year')
                                                                            .locale('th')
                                                                            .format('DD/MMMM/YYYY HH:mm:ss')}
                                                                    </h6>
                                                                    <h6>
                                                                        ยอดรวม:{' '}
                                                                        <NumberFormat
                                                                            value={parseFloat(object.total_gross_price)}
                                                                            displayType={'text'}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={true}
                                                                            thousandSeparator={true}
                                                                            suffix={' บาท'}
                                                                        />
                                                                    </h6>
                                                                    <h6>
                                                                        การชำระเงิน:{' '}
                                                                        <span>
                                                                            <strong
                                                                                className={`${object.status ===
                                                                                'pending'
                                                                                    ? 'text-primary'
                                                                                    : 'text-success'}`}
                                                                            >
                                                                                {object.status === 'pending' ? (
                                                                                    'รอการชำระเงิน'
                                                                                ) : (
                                                                                    'ชำระแล้ว'
                                                                                )}
                                                                            </strong>
                                                                        </span>
                                                                    </h6>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className='d-flex justify-content-end'>
                                                            <button
                                                                type='button'
                                                                className='btn btn-outline-primary'
                                                                onClick={() => props.btnView(object.id)}
                                                            >
                                                                ดูรายละเอียด
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                                {arr.length - 1 !== index && <hr />}
                                            </div>
                                        );
                                    })}
                            </ul>
                            <div className='d-flex justify-content-between align-items-center flex-wrap'>
                                <div className='d-flex flex-wrap py-2 mr-3'>
                                    {props.historys.info.length > props.initindex && (
                                        <button
                                            type='button'
                                            className='btn btn-icon btn-sm btn-light-danger mr-2 my-1'
                                            onClick={props.btnPrevious}
                                            disabled={props.endindex === props.initindex}
                                        >
                                            <i className='ki ki-bold-arrow-back icon-xs' />
                                        </button>
                                    )}
                                    {props.historys.info.length > props.initindex && (
                                        <button
                                            type='button'
                                            className='btn btn-icon btn-sm btn-light-danger mr-2 my-1'
                                            onClick={props.btnNext}
                                            disabled={props.endindex >= props.historys.info.length}
                                        >
                                            <i className='ki ki-bold-arrow-next icon-xs' />
                                        </button>
                                    )}
                                </div>
                                <div className='d-flex align-items-center py-3'>
                                    <span className='text-muted'>
                                        แสดง{' '}
                                        {props.endindex >= props.historys.info.length ? (
                                            props.historys.info.length
                                        ) : (
                                            props.endindex
                                        )}{' '}
                                        จาก {props.historys.info.length} รายการ
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
