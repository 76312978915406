import Swal from 'sweetalert2/dist/sweetalert2.js';

export const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

export const fileToBase64 = (filename, filepath) => {
    return new Promise((resolve, reject) => {
        var file = new File([ filename ], filepath);
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function(event) {
            resolve(event.target.result);
        };
        reader.onerror = (error) => {
            reject(error);
        };
    });
};

export const fileToBase64V2 = (filepath) => {
    return new Promise((resolve, reject) => {
        let canvas = document.createElement('CANVAS');
        let img = document.createElement('img');
        img.src = filepath;
        img.onload = function() {
            canvas.height = img.height;
            canvas.width = img.width;
            var dataURL = canvas.toDataURL('image/png');
            // alert(dataURL);
            resolve(dataURL);
            canvas = null;
        };
        img.onerror = (error) => {
            reject(error);
        };
    });
};

export function moreThanToday(date) {
    const today = new Date();
    const datetime = new Date(date);
    datetime.setDate(datetime.getDate() + 1);
    if (today > datetime) {
        return true;
    }
    return false;
}

export function getQueryVariable(props, variable) {
    var query = props.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (pair[0] === variable) {
            return pair[1];
        }
    }
    return false;
}

export const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
        object.target.value = object.target.value.slice(0, object.target.maxLength);
    }
};

export const validateAge18 = (data) => {
    let today = new Date();
    today.toLocaleString('th-TH', { timeZone: 'Asia/Bangkok' });
    let nowyear = today.getFullYear();
    let nowmonth = today.getMonth();
    let nowday = today.getDate();
    let birth = new Date(data);
    birth.toLocaleString('th-TH', { timeZone: 'Asia/Bangkok' });
    let birthyear = birth.getFullYear();
    let birthmonth = birth.getMonth();
    let birthday = birth.getDate();
    let age = nowyear - birthyear;
    let age_month = nowmonth - birthmonth;
    let age_day = nowday - birthday;
    if ((age === 18 && age_month <= 0 && age_day < 0) || age < 18) {
        return false;
    } else {
        return true;
    }
};

export const validateAge = (data, initage) => {
    let today = new Date();
    today.toLocaleString('th-TH', { timeZone: 'Asia/Bangkok' });
    let nowyear = today.getFullYear();
    let nowmonth = today.getMonth();
    let nowday = today.getDate();
    let birth = new Date(data);
    birth.toLocaleString('th-TH', { timeZone: 'Asia/Bangkok' });
    let birthyear = birth.getFullYear();
    let birthmonth = birth.getMonth();
    let birthday = birth.getDate();
    let age = nowyear - birthyear;
    let age_month = nowmonth - birthmonth;
    let age_day = nowday - birthday;
    if ((age === initage && age_month <= 0 && age_day < 0) || age < initage) {
        return false;
    } else {
        return true;
    }
};

export const validateCitizenid = (value) => {
    let sum = 0;
    for (let i = 0; i < 12; i++) {
        sum += parseFloat(value.charAt(i)) * (13 - i);
    }
    if ((11 - sum % 11) % 10 !== parseFloat(value.charAt(12))) {
        return false;
    }
    return true;
};

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};

export const validateNumber = (event) => {
    if (!/[0-9]/.test(event.key)) {
        if (event.key !== 'Enter') {
            event.preventDefault();
        }
    }
};

export const validateThai = (event) => {
    if (!/[ก-๚]/.test(event.key)) {
        if (event.key !== 'Enter') {
            event.preventDefault();
        }
    }
};

export const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});
