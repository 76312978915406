import React, { useEffect, useState } from 'react';

import { Dropdown, Modal } from 'react-bootstrap';

import { NavLink } from 'react-router-dom';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import NumberFormat from 'react-number-format';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import { QRCode } from 'react-qrcode-logo';

import html2canvas from 'html2canvas';

import Moment from 'moment';

import 'moment/locale/th';

import $ from 'jquery';

import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';

import config from '../../../environments/config';

import initaddress from '../../../environments/address';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

export function ShopHistoryDetail(props) {
    const [ province, setProvince ] = useState('');
    const [ district, setDistrict ] = useState('');
    const [ subdistrict, setSubdistrict ] = useState('');
    const [ approvebtn, setApprovebtn ] = useState(false);

    const [ showModal, setShow ] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        $(document).ready(function() {
            $('#react-qrcode-logo').css('border', '2px solid');
        });
    };

    useEffect(() => {
        let provincelabel = '';
        let districtlabel = '';
        let subdistrictlabel = '';
        for (const thisprovince in initaddress.province) {
            if (initaddress.province[thisprovince]['value'] === props.details.info.shipping.province.toString()) {
                provincelabel = initaddress.province[thisprovince]['label'];
                break;
            }
            provincelabel = '';
        }
        setProvince(provincelabel);
        for (const thisdistrict in initaddress.district) {
            if (initaddress.district[thisdistrict]['value'] === props.details.info.shipping.district.toString()) {
                districtlabel = initaddress.district[thisdistrict]['label'];
                break;
            }
            districtlabel = '';
        }
        setDistrict(districtlabel);
        for (const thissubdistrict in initaddress.subdistrict) {
            if (
                initaddress.subdistrict[thissubdistrict]['value'] === props.details.info.shipping.subdistrict.toString()
            ) {
                subdistrictlabel = initaddress.subdistrict[thissubdistrict]['label'];
                break;
            }
            subdistrictlabel = '';
        }
        setSubdistrict(subdistrictlabel);
        for (const thisitemindex in props.details.info.items) {
            if (props.details.info.items[thisitemindex]['shipping_status'] !== 'completed') {
                setApprovebtn(true);
                break;
            }
        }
    }, []);

    const handleDownload = () => {
        let d = new Date();
        html2canvas(document.querySelector('#react-qrcode-logo')).then(function(canvas) {
            const link = document.createElement('a');
            link.download = `payment-${Moment(d).format('YYYYMMDDhhmmss')}.png`;
            link.href = canvas.toDataURL();
            link.click();
        });
    };

    const btnTracking = (code) => {
        var $temp = $('<input>');
        $('body').append($temp);
        $temp.val(code).select();
        document.execCommand('copy');
        $temp.remove();
        window.open('https://th.kerryexpress.com/th/track/', '_blank');
    };

    const btnApproveItems = () => {
        let itemsid = [];
        for (const thisitemindex in props.details.info.items) {
            if (
                props.details.info.items[thisitemindex]['shipping_status'] !== 'completed' &&
                props.details.info.items[thisitemindex]['item_type'] === 'product'
            ) {
                itemsid.push(props.details.info.items[thisitemindex]['id']);
            }
        }
        setApprovebtn(false);
        approveItems(itemsid);
    };

    const approveItems = (items) => {
        props.setLoading(true);
        fetch(config.punsook_shop_url + 'member/order/completed', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: props.user['shop_token'],
                order_item_id: items
            })
        })
            .then((response) => response.json())
            .then((result) => {
                props.setLoading(false);
                // console.log(result)
                if (result.responsecode === 0) {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `ดำเนินการยืนยันเรียบร้อย`,
                        icon: 'success',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            props.getOrderDetail(props.details.info.id);
                        }
                    });
                } else {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `${result.responsecode}! ไม่สามารถดึงข้อมูลรายการได้ กรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                    return;
                }
            })
            .catch((error) => {
                props.setLoading(false);
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: `ไม่สามารถดึงข้อมูลรายการได้ กรุณาลองใหม่อีกครั้ง`,
                    icon: 'error',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return;
            });
    };
    return (
        <div className='container clearfix p-0'>
            <div className='card rounded-lg'>
                <div className='card-header bg-secondary p-4 rounded-lg rounded-bottom-0'>
                    <div className='row justify-content-between m-0'>
                        <button type='button' className='btn btn-link-primary' onClick={props.btnBack}>
                            <i className='fas fa-angle-double-left icon-md py-2' />
                            ย้อนกลับ
                        </button>
                        <Dropdown>
                            <Dropdown.Toggle variant='primary' className='rounded-lg'>
                                คำสั่งต่างๆ
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item
                                    disabled={
                                        props.details.info.payment_id === '1' ||
                                        (props.details.info.payment_id === '2' &&
                                            props.details.info.status !== 'pending')
                                    }
                                    onClick={handleShow}
                                >
                                    เรียกดูคิวอาร์โค้ด
                                </Dropdown.Item>
                                {/* <Dropdown.Item
                                // onClick={downloadTextFile}
                                >
                                    ดาวน์โหลดใบแจ้งหนี้
                                </Dropdown.Item> */}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className='card-body rounded-lg rounded-top-0'>
                    <div className='row m-0'>
                        <div className='col-12 p-0 account-user'>
                            <div className='row m-0'>
                                <h1 className='d-inline-block'># {props.details.info.id}</h1>
                            </div>
                            <h2 className='order-date'>
                                สั่งซื้อเมื่อวันที่{' '}
                                {Moment(new Date(props.details.info.create_date))
                                    .add(543, 'year')
                                    .locale('th')
                                    .format('LL เวลา HH:mm:ss')}
                            </h2>
                            <div className='status capitalize bottompad'>
                                <strong>สถานะรายการ : </strong>{' '}
                                <span>
                                    {props.details.info.status === 'pending' ? (
                                        'รอการชำระเงิน'
                                    ) : props.details.info.status === 'paid' ? (
                                        'ชำระแล้ว'
                                    ) : props.details.info.status === 'cancel' ? (
                                        'ยกเลิก'
                                    ) : props.details.info.status === 'expired' ? (
                                        'หมดอายุ'
                                    ) : props.details.info.status === 'completed' ? (
                                        'ยืนยันสินค้าแล้ว'
                                    ) : null}
                                </span>
                                {/* <span class='vl' />
                                <strong>สถานะสินค้า : </strong> <span>Fulfilled</span> */}
                            </div>
                        </div>
                        <div className='row justify-content-center col-12 bg-gray-100 py-8 px-4 py-md-10 px-md-0 mx-0 my-5'>
                            <div className='col-md-10 p-0'>
                                <div className='table-responsive'>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th className='font-weight-bold text-muted text-uppercase'>
                                                    ช่องทางชำระเงิน
                                                </th>
                                                <th className='font-weight-bold text-muted text-uppercase'>
                                                    วันที่ชำระค่าบริการ
                                                </th>
                                                <th className='font-weight-bold text-muted text-uppercase text-right'>
                                                    ยอดรวม
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className='font-weight-bolder'>
                                                <td>
                                                    {props.details.info.payment_id === '1' ? (
                                                        'กระเป๋าซิมปันสุข'
                                                    ) : (
                                                        'คิวอาร์โค้ด'
                                                    )}
                                                </td>
                                                <td>
                                                    {props.details.info.paid_date === '' ? (
                                                        '-'
                                                    ) : (
                                                        props.details.info.paid_date
                                                    )}
                                                </td>
                                                <td className='text-primary font-size-h3 font-weight-boldest text-right'>
                                                    <NumberFormat
                                                        value={
                                                            parseFloat(props.details.info.total_gross_price) +
                                                            parseFloat(props.details.info.total_vat)
                                                        }
                                                        displayType={'text'}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        thousandSeparator={true}
                                                        suffix={' บาท'}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 p-0 account-table'>
                            <div className='row justify-content-between m-0'>
                                <h3 className='my-5'>รายละเอียดรายการสินค้า</h3>
                                {props.details.info.status === 'paid' &&
                                approvebtn && (
                                    <div className='ml-auto my-auto'>
                                        <button
                                            type='button'
                                            className='btn btn-outline-primary'
                                            onClick={btnApproveItems}
                                        >
                                            ยืนยันสินค้าทั้งหมด
                                        </button>
                                    </div>
                                )}
                            </div>
                            <OverlayScrollbarsComponent className='h-300px p-5'>
                                {props.details.info.items.map((object, index, arr) => {
                                    return (
                                        <div key={index}>
                                            <div className='row m-0 justify-content-center justify-content-md-start'>
                                                <div className='col-lg-3 item-thumb title-align title-align-left toppad'>
                                                    <div className='relative text-center'>
                                                        <img
                                                            src={
                                                                object.item_type === 'shipping' ? (
                                                                    toAbsoluteUrl('/media/products/kerry.png')
                                                                ) : object.item_type === 'coupon' ? (
                                                                    toAbsoluteUrl('/media/products/coupon.png')
                                                                ) : Object.keys(object.gallery).length > 0 &&
                                                                object.gallery.cover_image !== '' ? (
                                                                    `data:image/png;base64,${object.gallery
                                                                        .cover_image}`
                                                                ) : (
                                                                    toAbsoluteUrl(`/media/products/${index + 1}.png`)
                                                                )
                                                            }
                                                            alt={`product-${object.code}`}
                                                            className='w-100px'
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-lg-9 item-details toppad row justify-content-between m-0'>
                                                    <div className='my-auto'>
                                                        <h5>
                                                            <NavLink
                                                                to={`/shop/productdetail/${object.code}`}
                                                                target={'_blank'}
                                                            >
                                                                {object.item_name}
                                                            </NavLink>
                                                            <br />
                                                            <small className='overflow-hidden text-nowrap'>
                                                                {object.description}
                                                            </small>
                                                        </h5>
                                                        {object.item_type === 'product' && (
                                                            <p>
                                                                <small className='d-none d-md-block'>
                                                                    <strong>วันที่จัดส่ง:</strong>{' '}
                                                                    {object.shipping_date !== '' ? (
                                                                        Moment(new Date(object.shipping_date))
                                                                            .add(543, 'year')
                                                                            .locale('th')
                                                                            .format('LL')
                                                                    ) : (
                                                                        'ไม่มีข้อมูล'
                                                                    )}
                                                                    <span class='vl' />
                                                                    <strong>หมายเลขพัสดุ:</strong>{' '}
                                                                    {object.tracking_id !== '' ? (
                                                                        <button
                                                                            type='button'
                                                                            className='btn btn-link px-0'
                                                                            onClick={() =>
                                                                                btnTracking(object.tracking_id)}
                                                                            title={object.tracking_id}
                                                                        >
                                                                            {object.tracking_id}
                                                                        </button>
                                                                    ) : (
                                                                        'ไม่มีข้อมูล'
                                                                    )}
                                                                </small>
                                                                <small className='d-block d-md-none'>
                                                                    <strong>วันที่จัดส่ง:</strong>{' '}
                                                                    {object.shipping_date !== '' ? (
                                                                        Moment(new Date(object.shipping_date))
                                                                            .add(543, 'year')
                                                                            .locale('th')
                                                                            .format('LL')
                                                                    ) : (
                                                                        'ไม่มีข้อมูล'
                                                                    )}
                                                                    <br />
                                                                    <strong>หมายเลขพัสดุ:</strong>{' '}
                                                                    {object.tracking_id !== '' ? (
                                                                        <button
                                                                            type='button'
                                                                            className='btn btn-link px-0'
                                                                            onClick={() =>
                                                                                btnTracking(object.tracking_id)}
                                                                            title={object.tracking_id}
                                                                        >
                                                                            {object.tracking_id}
                                                                        </button>
                                                                    ) : (
                                                                        'ไม่มีข้อมูล'
                                                                    )}
                                                                </small>
                                                            </p>
                                                        )}
                                                        <div className='product-price'>
                                                            {object.item_type === 'product' ? (
                                                                <h6>
                                                                    <span className='order-money'>
                                                                        ราคา{' ('}
                                                                        <NumberFormat
                                                                            value={parseFloat(object.gross_price)}
                                                                            displayType={'text'}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={true}
                                                                            thousandSeparator={true}
                                                                            suffix={' x '}
                                                                        />
                                                                        <NumberFormat
                                                                            value={parseFloat(object.vat)}
                                                                            displayType={'text'}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={true}
                                                                            thousandSeparator={true}
                                                                            suffix={' บาท'}
                                                                        />
                                                                    </span>
                                                                    {') '}
                                                                    x{' '}
                                                                    <span className='order-quantity'>
                                                                        {' '}
                                                                        {object.quantity} ชิ้น
                                                                    </span>{' '}
                                                                    ={' '}
                                                                    <span className='order-total'>
                                                                        {' '}
                                                                        <NumberFormat
                                                                            value={
                                                                                parseFloat(object.total) +
                                                                                parseFloat(object.vat) *
                                                                                    parseFloat(object.quantity)
                                                                            }
                                                                            displayType={'text'}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={true}
                                                                            thousandSeparator={true}
                                                                            suffix={' บาท'}
                                                                        />
                                                                    </span>
                                                                </h6>
                                                            ) : (
                                                                <h6>
                                                                    <span className='order-money'>
                                                                        {object.item_type === 'coupon' && 'ส่วนลด'}ราคา{' '}
                                                                        <NumberFormat
                                                                            value={parseFloat(
                                                                                object.item_type !== 'coupon'
                                                                                    ? object.total
                                                                                    : object.discount
                                                                            )}
                                                                            displayType={'text'}
                                                                            decimalScale={2}
                                                                            fixedDecimalScale={true}
                                                                            thousandSeparator={true}
                                                                            suffix={' บาท'}
                                                                        />
                                                                    </span>
                                                                </h6>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {props.details.info.status === 'paid' &&
                                                    object.shipping_status !== 'completed' &&
                                                    object.item_type === 'product' && (
                                                        <div className='my-auto mx-md-0 mx-auto'>
                                                            <button
                                                                type='button'
                                                                className='btn btn-outline-primary'
                                                                onClick={() => approveItems([ object.id ])}
                                                            >
                                                                ยืนยันสินค้า
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            {arr.length - 1 !== index && <hr />}
                                        </div>
                                    );
                                })}
                            </OverlayScrollbarsComponent>
                            {/* <div className='p-2 d-flex'>
                                <div className='col-12'>
                                    <div className='table-responsive'>
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th className='pl-0 font-weight-bold text-muted text-uppercase'>
                                                        สินค้า
                                                    </th>
                                                    <th className='text-right font-weight-bold text-muted text-uppercase'>
                                                        จำนวน
                                                    </th>
                                                    <th className='text-right font-weight-bold text-muted text-uppercase'>
                                                        ราคาต่อชิ้น
                                                    </th>
                                                    <th className='text-right pr-0 font-weight-bold text-muted text-uppercase'>
                                                        ราคารวม
                                                    </th>
                                                    <th className='text-right pr-0 font-weight-bold text-muted text-uppercase'>
                                                        วันที่จัดส่ง
                                                    </th>
                                                    <th className='text-right pr-0 font-weight-bold text-muted text-uppercase'>
                                                        หมายเลขพัสดุ
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className='font-weight-boldest font-size-lg'>
                                                    <td className='pl-0 pt-7'>หุ้นสหกรณ์</td>
                                                    <td className='text-right pt-7'>10</td>
                                                    <td className='text-right pt-7'>
                                                        <span>10.00 บาท</span>
                                                    </td>
                                                    <td className='text-primary pt-7 text-right'>
                                                        <span>100.00 บาท</span>
                                                    </td>
                                                    <td className='pt-7 text-right'>
                                                        <span>XX/XX/XX</span>
                                                    </td>
                                                    <td className='text-primary pr-0 pt-7 text-right'>
                                                        <span>XXXXX</span>
                                                    </td>
                                                </tr>
                                                <tr className='font-weight-boldest font-size-lg'>
                                                    <td className='pl-0 pt-7'>ค่าธรรมเนียมแรกเข้าสหกรณ์</td>
                                                    <td className='text-right pt-7'>1</td>
                                                    <td className='text-right pt-7'>
                                                        <span>100.00 บาท</span>
                                                    </td>
                                                    <td className='text-primary pt-7 text-right'>
                                                        <span>100.00 บาท</span>
                                                    </td>
                                                    <td className='pt-7 text-right'>
                                                        <span>XX/XX/XX</span>
                                                    </td>
                                                    <td className='text-primary pr-0 pt-7 text-right'>
                                                        <span>XXXXX</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        {/* <div className='col-12 p-0 account-table'>
                            <div className='table-center'>
                                <div className='clearfix bottompad-quarter'>
                                    <div className='three orders text-right'>Subtotal:</div>
                                    <div className='nine orders text-left'>1,640.00 ฿ THB</div>
                                </div>
                                <div className='clearfix'>
                                    <div className='three orders text-right'>
                                        <h5>EMS:</h5>
                                    </div>
                                    <div className='nine orders text-left'>
                                        <h5>60.00 ฿ THB</h5>
                                    </div>
                                </div>
                                <div className='clearfix'>
                                    <div className='three orders text-right'>
                                        <h3>Total:</h3>
                                    </div>
                                    <div className='nine orders text-left'>
                                        <h3>1,700.00 ฿ THB</h3>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className='col-12 p-0 row m-0 mt-5 mt-md-0 account-user'>
                            {/* <div className='col-lg-6 p-0'>
                                <h3>Billing Address</h3>
                                <p>
                                    กรณศักดิ์ วงษ์จินดา<br />45/703 อ.B ไอเฮาส์ลากูน่า RCA ชั้น 7 ห้อง 703 ซ.ศูนย์วิจัย
                                    ถ.พระราม 9 บางกะปิ<br />ห้วยขวาง<br />Bangkok 10310<br />Thailand
                                </p>
                            </div> */}
                            <div className='col-lg-6 p-0 ml-auto text-right'>
                                <h3>ที่อยู่สำหรับการจัดส่ง</h3>
                                <p>
                                    {props.details.info.shipping.name} ({props.details.info.shipping.contact_number})<br />
                                    {[
                                        props.details.info.shipping.address1,
                                        props.details.info.shipping.address2
                                    ].join(' ')}
                                    <br />
                                    {subdistrict} {district}
                                    <br />
                                    {[ province, props.details.info.shipping.postalcode ].join(' ')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={handleClose} size='md' centered aria-labelledby='qrcode-modal'>
                <Modal.Header closeButton>
                    <Modal.Title id='qrcode-modal' className='col-11 p-0'>
                        คิวอาร์โค้ด
                    </Modal.Title>
                    <button type='button' className='close col-1 text-right pr-3' onClick={handleClose}>
                        <i className='ki ki-close' />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row d-flex justify-content-center'>
                        <div className='col-sm-12 d-flex justify-content-center'>
                            <QRCode
                                value={props.details.info.qr}
                                size={256}
                                eyeRadius={10}
                                logoImage={toAbsoluteUrl('/media/logos/new-logo.png')}
                                logoWidth={100}
                                style={`border: 2px solid #000000`}
                            />
                        </div>
                        <div className='col-sm-12 d-flex justify-content-end pt-5'>
                            <button type='button' className='btn btn-primary rounded-lg' onClick={handleDownload}>
                                <i className='fas fa-save' /> บันทึกคิวอาร์โค้ด
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}
