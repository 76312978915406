import React from 'react';
import SVG from 'react-inlinesvg';
import { QRCode } from 'react-qrcode-logo';
import Moment from 'moment';
import html2canvas from 'html2canvas';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';

export function NCResult(props) {
    const btnDownload = () => {
        let d = new Date();
        html2canvas(document.querySelector('#react-qrcode-logo')).then(function(canvas) {
            const link = document.createElement('a');
            link.download = `${props.response.order.ordernumber}-${Moment(d).format('YYYYMMDDhhmmss')}.png`;
            link.href = canvas.toDataURL();
            link.click();
        });
    };

    return (
        <div>
            {props.paymenttype === '2' ? (
                <div className='row d-flex justify-content-center pb-5'>
                    {props.response.responsecode === 0 ? (
                        <div className='col-12'>
                            <div className='row justify-content-center'>
                                <div className='text-center rounded bg-payment p-1'>
                                    <div className='text-white text-center'>
                                        หมายเลขคำสั่งซื้อ {props.response.order.ordernumber}
                                    </div>
                                    <div className='qrimg'>
                                        <QRCode
                                            className='border'
                                            value={props.response.order.qrcode}
                                            size={256}
                                            eyeRadius={10}
                                            logoImage={toAbsoluteUrl('/media/logos/new-logo.png')}
                                            logoWidth={100}
                                        />
                                    </div>
                                    <div className='text-white text-center'>
                                        เบอร์ที่สั่งซื้อ :{' '}
                                        {props.response.phonenumber.slice(0, 3) +
                                            '-' +
                                            props.response.phonenumber.slice(3, 6) +
                                            '-' +
                                            props.response.phonenumber.slice(6)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='col-12'>
                            <div className='row justify-content-center'>
                                <div className='text-center'>
                                    <div className='qrimg'>
                                        <img
                                            alt='error'
                                            src={toAbsoluteUrl('/media/error/qr-error.png')}
                                            className='logo-sticky max-h-250px'
                                        />
                                    </div>
                                    <h4 className='text-center text-color-main mb-0 mt-5'>
                                        {props.response.responsecode === '102' ? (
                                            'ขั้นตอนการเพิ่มสิทธิ์ไม่สมบูรณ์ กรุณาติดต่อเจ้าหน้าที่'
                                        ) : (
                                            'คิวอาร์โค้ดไม่สมบูรณ์'
                                        )}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    )}
                    {props.response.responsecode === 0 && (
                        <div className='row col-12 p-0 m-0'>
                            <div className='col-12 mt-50 text-center'>
                                <button
                                    type='button'
                                    className='btn btn-success font-weight-bold text-uppercase px-6 py-2'
                                    onClick={btnDownload}
                                >
                                    บันทึกภาพ
                                </button>
                            </div>
                            <div className='col-12 mt-50 text-center'>
                                <h5 className='text-center text-color-main mb-0'>
                                    หมายเลขคำสั่งซื้อ {props.response.order.ordernumber}
                                    <br /> เบอร์ที่สั่งซื้อ{' '}
                                    {props.response.phonenumber.slice(0, 3) +
                                        '-' +
                                        props.response.phonenumber.slice(3, 6) +
                                        '-' +
                                        props.response.phonenumber.slice(6)}
                                </h5>
                                <p className='text-center'>
                                    กรุณาบันทึกภาพคิวอาร์โค้ดและทำการชำระเงินที่แอพ
                                    <span> ธนาคาร </span> ของท่าน
                                    <b className='text-danger'>ภายใน 1 วัน</b> เมื่อชำระเงินเรียบร้อยแล้วรอรับซิม Feels
                                    ที่บ้านประมาณ 2 - 3 วันทำการ
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div className='card card-custom position-relative overflow-hidden col-12 mx-auto p-0'>
                    <div className='position-absolute opacity-30'>
                        <span className='svg-icon svg-icon-10x svg-logo-white'>
                            <SVG src={toAbsoluteUrl('/media/svg/shapes/abstract-8.svg')} />
                        </span>
                    </div>
                    <div className='row justify-content-center py-8 px-8 py-md-36 px-md-0 bg-primary'>
                        <div className='col-md-9'>
                            <div className='d-flex justify-content-between align-items-md-center flex-column flex-md-row'>
                                <div className='d-flex flex-column px-0 order-2 order-md-1'>
                                    <div className='py-4 d-flex flex-row text-white'>
                                        <img
                                            alt='Logo'
                                            src={toAbsoluteUrl('/media/logos/new-logo.png')}
                                            className='logo-sticky max-h-60px'
                                        />
                                        <h2 className='my-auto'>
                                            <b>ปันสุข</b> |{' '}
                                        </h2>
                                        <span className='pl-2 my-auto font-size-h3'>ซิม</span>
                                    </div>
                                    <span className='d-flex flex-column font-size-h5 font-weight-bold text-white' />
                                </div>
                                <h1 className='display-3 font-weight-boldest text-white order-1 order-md-2'>
                                    สรุปรายการ
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center py-8 px-8 py-md-30 px-md-0 bg-gray-300'>
                        <div className='col-md-9'>
                            {props.response.responsecode === 0 ? (
                                <div className='row pb-26'>
                                    <div className='col-md-12 pr-md-10 py-md-10'>
                                        <div className='custom-title'>
                                            <div className='title-text text-dark-50 font-size-lg font-weight-bold my-auto'>
                                                หมายเลขคำสั่งซื้อ
                                            </div>
                                            <div className='title-line bg-gray-500 my-auto' />
                                        </div>
                                        <div className='font-size-lg font-weight-bold text-right mb-10'>
                                            {props.response.order.ordernumber}
                                        </div>
                                        <div className='custom-title'>
                                            <div className='title-text text-dark-50 font-size-lg font-weight-bold my-auto'>
                                                เบอร์ที่สั่งซื้อ
                                            </div>
                                            <div className='title-line bg-gray-500 my-auto' />
                                        </div>
                                        <div className='font-size-lg font-weight-bold text-right mb-10'>
                                            {props.response.phonenumber.slice(0, 3) +
                                                '-' +
                                                props.response.phonenumber.slice(3, 6) +
                                                '-' +
                                                props.response.phonenumber.slice(6)}
                                        </div>
                                        <div className='custom-title'>
                                            <div className='title-line bg-gray-500 my-auto' />
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className='row d-flex justify-content-center pb-26'>
                                    <div className='col-12'>
                                        <div className='row justify-content-center'>
                                            <div className='text-center'>
                                                <div className='qrimg'>
                                                    <img
                                                        alt='error'
                                                        src={toAbsoluteUrl('/media/error/qr-error.png')}
                                                        className='logo-sticky max-h-250px'
                                                    />
                                                </div>
                                                <h4 className='text-center text-color-main mb-0 mt-5'>
                                                    {props.response.responsecode === '102' ? (
                                                        'ขั้นตอนการเพิ่มสิทธิ์ไม่สมบูรณ์ กรุณาติดต่อเจ้าหน้าที่'
                                                    ) : (
                                                        'คิวอาร์โค้ดไม่สมบูรณ์'
                                                    )}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
