import React from 'react';

import { NavLink } from 'react-router-dom';

import SVG from 'react-inlinesvg';

import { toAbsoluteUrl } from '../../_metronic/_helpers';

export function MobileShopMenu() {
    return (
        <div className='row p-0 m-0 mb-15'>
            <NavLink className='col-12 p-0' to='/shop/product'>
                <div className='card'>
                    <div className='card-body'>
                        <div className='d-flex'>
                            {/* <div className="col-12"> */}
                            <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                <SVG title='สินค้า' src={toAbsoluteUrl('/media/svg/icons/Shopping/Box3.svg')} />
                            </span>
                            {/* </div> */}
                            <h5 className='my-auto font-weight-bolder text-dark'>สินค้า</h5>
                        </div>
                    </div>
                </div>
            </NavLink>
            <NavLink className='col-12 p-0' to='/shop/cart'>
                <div className='card'>
                    <div className='card-body'>
                        <div className='d-flex'>
                            {/* <div className="col-12"> */}
                            <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                <SVG title='ตะกร้า' src={toAbsoluteUrl('/media/svg/icons/Shopping/Cart2.svg')} />
                            </span>
                            {/* </div> */}
                            <h5 className='my-auto font-weight-bolder text-dark'>ตะกร้า</h5>
                        </div>
                    </div>
                </div>
            </NavLink>
            <NavLink className='col-12 p-0' to='/shop/history'>
                <div className='card'>
                    <div className='card-body'>
                        <div className='d-flex'>
                            {/* <div className="col-12"> */}
                            <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                <SVG
                                    title='ประวัติการซื้อ'
                                    src={toAbsoluteUrl('/media/svg/icons/Code/Time-schedule.svg')}
                                />
                            </span>
                            {/* </div> */}
                            <h5 className='my-auto font-weight-bolder text-dark'>ประวัติการซื้อ</h5>
                        </div>
                    </div>
                </div>
            </NavLink>
            <NavLink className='col-12 p-0' to='/shop/coupon'>
                <div className='card'>
                    <div className='card-body'>
                        <div className='d-flex'>
                            {/* <div className="col-12"> */}
                            <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                <SVG title='คูปองส่วนลด' src={toAbsoluteUrl('/media/svg/icons/Shopping/Ticket.svg')} />
                            </span>
                            {/* </div> */}
                            <h5 className='my-auto font-weight-bolder text-dark'>คูปองส่วนลด</h5>
                        </div>
                    </div>
                </div>
            </NavLink>
            <NavLink className='col-12 p-0' to='/shop/numberbox'>
                <div className='card'>
                    <div className='card-body'>
                        <div className='d-flex'>
                            {/* <div className="col-12"> */}
                            <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                <SVG
                                    title='รายการร้านค้าเสมือน'
                                    src={toAbsoluteUrl('/media/svg/icons/Home/Building.svg')}
                                />
                            </span>
                            {/* </div> */}
                            <h5 className='my-auto font-weight-bolder text-dark'>รายการร้านค้าเสมือน</h5>
                        </div>
                    </div>
                </div>
            </NavLink>
            <NavLink className='col-12 p-0' to='/shop/insurance'>
                <div className='card'>
                    <div className='card-body'>
                        <div className='d-flex'>
                            {/* <div className="col-12"> */}
                            <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                <SVG
                                    title='ประกันภัย'
                                    src={toAbsoluteUrl('/media/svg/icons/Communication/Shield-user.svg')}
                                />
                            </span>
                            {/* </div> */}
                            <h5 className='my-auto font-weight-bolder text-dark'>ประกันภัย</h5>
                        </div>
                    </div>
                </div>
            </NavLink>
            <NavLink className='col-12 p-0' to='/shop/insurancehistory'>
                <div className='card'>
                    <div className='card-body'>
                        <div className='d-flex'>
                            {/* <div className="col-12"> */}
                            <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                <SVG
                                    title='ประวัติการสั่งซื้อกรมธรรม์'
                                    src={toAbsoluteUrl('/media/svg/icons/Code/Time-schedule.svg')}
                                />
                            </span>
                            {/* </div> */}
                            <h5 className='my-auto font-weight-bolder text-dark'>ประวัติการสั่งซื้อกรมธรรม์</h5>
                        </div>
                    </div>
                </div>
            </NavLink>
            {/* <NavLink className='col-12 p-0' to='/shop/reservation'>
                <div className='card'>
                    <div className='card-body'>
                        <div className='d-flex'>
                            <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                <SVG
                                    title='จองแท็บเล็ต'
                                    src={toAbsoluteUrl('/media/svg/icons/Devices/Tablet.svg')}
                                />
                            </span>
                            <h5 className='my-auto font-weight-bolder text-dark'>จองแท็บเล็ต</h5>
                        </div>
                    </div>
                </div>
            </NavLink> */}
            <NavLink className='col-12 p-0' to='/shop/simkit'>
                <div className='card'>
                    <div className='card-body'>
                        <div className='d-flex'>
                            <span className='svg-icon svg-icon-2x svg-icon-primary mr-5'>
                                <SVG
                                    title='sim kit'
                                    src={toAbsoluteUrl('/media/svg/icons/Home/Box.svg')}
                                />
                            </span>
                            <h5 className='my-auto font-weight-bolder text-dark'>Sim Kit</h5>
                        </div>
                    </div>
                </div>
            </NavLink>
        </div>
    );
}
