import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import SVG from 'react-inlinesvg';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import {
    SubWalletWithdrawList,
    SubWalletWithdrawOtp,
    SubWalletWithdrawResult,
    SubWalletWithdrawSummary
} from './components';
import config from '../environments/config';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

export function SubWalletWithdrawMain() {
    const { user } = useSelector((state) => state.auth);
    const [ step, setStep ] = useState(1);
    const [ loading, setLoading ] = useState(false);
    const [ select, setSelect ] = useState({});
    // bank information
    const [ account, setAccount ] = useState('');
    const [ bank, setBank ] = useState('');
    const [ bankno, setBankno ] = useState('');
    // form
    const [ amount, setAmount ] = useState(0);
    // otp
    const [ refcode, setRefcode ] = useState('');
    const [ otpcode, setOtpcode ] = useState('');
    const [ disable, setDisable ] = useState(true);
    const [ expiretime, setExpiretime ] = useState('');
    // result
    const [ result, setResult ] = useState({});

    const handleOtp = (code) => {
        setOtpcode(code);
        if (code.length >= 6) {
            setDisable(false);
        } else {
            setDisable(true);
        }
    };

    const Completionist = () => {
        return (
            <div className='row justify-content-center col-12 p-0 m-0'>
                <button className='btn btn-primary rounded-lg' onClick={() => generateOTP(false)}>
                    ขอ OTP ใหม่
                </button>
            </div>
        );
    };

    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            return <Completionist />;
        } else {
            return (
                <div className='row col-12 p-0 m-0 text-center'>
                    <p className='col-12 m-0'>กรุณาลองใหม่ในอีก</p>
                    <span className='col-12'>
                        {(minutes < 10 ? '0' : '') + minutes}:
                        {(seconds < 10 ? '0' : '') + seconds}
                    </span>
                </div>
            );
        }
    };

    useEffect(() => {
        getInfo(user.id);
    }, []);

    const getInfo = (memberid) => {
        setLoading(true);
        fetch(config.py_url + config.version + '/profile/info', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                userid: memberid
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    setAccount(result.bank.account_name);
                    setBank(result.bank.bank_name);
                    setBankno(result.bank.account_no);
                } else {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `ไม่สามารถดึงข้อมูลธนาคารของคุณได้ กรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: `ไม่สามารถดึงข้อมูลธนาคารของคุณได้ กรุณาลองใหม่อีกครั้ง`,
                    icon: 'error',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            });
    };

    const generateOTP = (next = true) => {
        setLoading(true);
        setOtpcode('');
        fetch(config.py_url + 'partner/sim/otp-generate', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                // phonenumber: '0976954777'
                phonenumber: user.main_phonenumber
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    setRefcode(result.refcode);
                    setExpiretime(result.timeout);
                    // var currentTime = new Date();
                    // var Time = currentTime.setTime(currentTime.getTime() + 1000 * 60);
                    // setExpiretime(Time);
                    setDisable(true);
                    if (next) {
                        setStep(step + 1);
                    }
                } else {
                    SwalBs.fire({
                        html: `${result.responsecode}! มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            });
    };

    const validateOTP = () => {
        setLoading(true);
        fetch(config.py_url + 'partner/sim/otp-verify', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                // phonenumber: '0976954777',
                phonenumber: user.main_phonenumber,
                otp: otpcode,
                ref: refcode
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    setStep(step + 1);
                } else if (result.responsecode === 400) {
                    SwalBs.fire({
                        html: `รหัส OTP ไม่ถูกต้องกรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                } else if (result.responsecode === 401) {
                    SwalBs.fire({
                        html: `รหัส OTP หมดอายุแล้วกรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                } else if (result.responsecode === 499) {
                    SwalBs.fire({
                        html: `เอ๊ะ!!! มีบางอย่างผิดพลาดกรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                } else {
                    SwalBs.fire({
                        html: `${result.responsecode}! มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            });
    };

    const withdraw = () => {
        setLoading(true);
        fetch(config.py_url + config.version + '/wallet/subwallet/withdraw', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify({
                walletid: select.walletid.toString(),
                amount: amount.toString()
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    setResult(result);
                    setStep(step + 1);
                } else {
                    let message = '';
                    if (result.responsecode === 101) {
                        message = 'ไม่พบกระเป๋านี้ กรุณาติดต่อเจ้าหน้าที่';
                    } else if (result.responsecode === 102) {
                        message = 'จำนวนเงินที่ต้องการถอนต่ำกว่าที่กำหนดไว้ กรุณาลองใหม่อีกครั้ง';
                    } else if (result.responsecode === 103) {
                        message = 'จำนวนเงินที่ต้องการถอนสูงกว่าที่คุณมี กรุณาลองใหม่อีกครั้ง';
                    } else if (result.responsecode === 104) {
                        message = 'จำนวนเงินที่ถอนของวันนี้ถึงยอดสูงสุด กรุณาลองใหม่ภายหลัง';
                    } else if (result.responsecode === 105) {
                        message = 'ระบบถอนเงินมีปัญหา กรุณาลองใหม่อีกครั้ง';
                    } else if (result.responsecode === 106) {
                        if (result.gbpresultcode === '02' || result.gbpresultcode === '90') {
                            message = 'ไม่พบข้อมูลเลขบัญชีธนาคารของคุณ กรุณาติดต่อเจ้าหน้าที่';
                        } else {
                            message = 'ระบบถอนเงินมีปัญหา กรุณาลองใหม่อีกครั้ง';
                        }
                    }
                    SwalBs.fire({
                        html: message,
                        icon: 'warning',
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    html: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง',
                    icon: 'error',
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
            });
    };

    const btnNext = () => {
        if (step === 1) {
            if (amount === 0) {
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'กรุณากรอกจำนวนเงินให้เรียบร้อย',
                    icon: 'warning',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return false;
            } else if (amount < 100) {
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'กรุณากรอกจำนวนเงินอย่างน้อย 100 บาท',
                    icon: 'warning',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return false;
            } else if (amount > select.balance) {
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: 'ยอดเงินในกระเป๋าของคุณไม่เพียงพอกับจำนวนเงินที่ต้องการถอน',
                    icon: 'warning',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false
                });
                return false;
            } else {
                generateOTP();
            }
        } else if (step === 2) {
            validateOTP();
        } else if (step === 3) {
            withdraw();
        }
    };

    return (
        <div>
            {!loading ? (
                <div className='card card-custom card-shadowless bg-white rounded'>
                    <div className='wizard wizard-1' id='kt_wizard'>
                        <div className='wizard-nav border-bottom'>
                            <div className='wizard-steps p-8 p-lg-10'>
                                <div
                                    className={`wizard-step d-md-flex ${step !== 1 && 'd-none'}`}
                                    data-wizard-type='step'
                                    data-wizard-state={`${step >= 1 ? 'current' : 'pending'}`}
                                >
                                    <div className='wizard-label'>
                                        <i className='wizard-icon flaticon-list' />
                                        <h3 className='wizard-title'>กรอกรายละเอียด</h3>
                                    </div>
                                    <span className='svg-icon svg-icon-xl wizard-arrow d-none d-md-block'>
                                        <SVG
                                            title='ขั้นตอนที่1'
                                            src={toAbsoluteUrl('/media/svg/icons/Navigation/Arrow-right.svg')}
                                        />
                                    </span>
                                </div>
                                <div
                                    className={`wizard-step d-md-flex ${step !== 2 && 'd-none'}`}
                                    data-wizard-type='step'
                                    data-wizard-state={`${step >= 2 ? 'current' : 'pending'}`}
                                >
                                    <div className='wizard-label'>
                                        <i className='wizard-icon flaticon-user-ok' />
                                        <h3 className='wizard-title'>ตรวจสอบตัวตน</h3>
                                    </div>
                                    <span className='svg-icon svg-icon-xl wizard-arrow d-none d-md-block'>
                                        <SVG
                                            title='ขั้นตอนที่2'
                                            src={toAbsoluteUrl('/media/svg/icons/Navigation/Arrow-right.svg')}
                                        />
                                    </span>
                                </div>
                                <div
                                    className={`wizard-step d-md-flex ${step !== 3 && 'd-none'}`}
                                    data-wizard-type='step'
                                    data-wizard-state={`${step >= 3 ? 'current' : 'pending'}`}
                                >
                                    <div className='wizard-label'>
                                        <i className='wizard-icon flaticon-questions-circular-button' />
                                        <h3 className='wizard-title'>ยืนยันการทำรายการ</h3>
                                    </div>
                                    <span className='svg-icon svg-icon-xl wizard-arrow d-none d-md-block'>
                                        <SVG
                                            title='ขั้นตอนที่3'
                                            src={toAbsoluteUrl('/media/svg/icons/Navigation/Arrow-right.svg')}
                                        />
                                    </span>
                                </div>
                                <div
                                    className={`wizard-step d-md-flex ${step !== 4 && 'd-none'}`}
                                    data-wizard-type='step'
                                    data-wizard-state={`${step >= 4 ? 'current' : 'pending'}`}
                                >
                                    <div className='wizard-label'>
                                        <i className='wizard-icon flaticon-statistics' />
                                        <h3 className='wizard-title'>สรุปรายการ</h3>
                                    </div>
                                    <span className='svg-icon svg-icon-xl wizard-arrow last'>
                                        <SVG
                                            title='ขั้นตอนที่4'
                                            src={toAbsoluteUrl('/media/svg/icons/Navigation/Arrow-right.svg')}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card-body p-0'>
                        <div className='row justify-content-center m-0'>
                            <div className={`col-xl-12 py-5 py-md-0 ${step === 4 ? 'col-xxl-12' : 'col-xxl-12'}`}>
                                <div
                                    className='form my-0 my-lg-10 fv-plugins-bootstrap fv-plugins-framework'
                                    id='kt_form'
                                >
                                    <div className='d-flex flex-row row m-0'>
                                        {step === 1 ? (
                                            <SubWalletWithdrawList
                                                config={config}
                                                dealerid={user.dealerid}
                                                account={account}
                                                bank={bank}
                                                bankno={bankno}
                                                select={select}
                                                setSelect={setSelect}
                                                amount={amount}
                                                setAmount={setAmount}
                                                btnNext={btnNext}
                                            />
                                        ) : step === 2 ? (
                                            <SubWalletWithdrawOtp
                                                // phonenumber={'0976954777'}
                                                phonenumber={user.main_phonenumber}
                                                otpcode={otpcode}
                                                refcode={refcode}
                                                expiretime={expiretime}
                                                handleOtp={handleOtp}
                                                renderer={renderer}
                                                disable={disable}
                                                btnNext={btnNext}
                                            />
                                        ) : step === 3 ? (
                                            <SubWalletWithdrawSummary
                                                account={account}
                                                bank={bank}
                                                bankno={bankno}
                                                amount={amount}
                                                tax={select.tax}
                                                fee={select.fee}
                                                btnNext={btnNext}
                                            />
                                        ) : step === 4 ? (
                                            <SubWalletWithdrawResult
                                                account={account}
                                                bank={bank}
                                                bankno={bankno}
                                                amount={amount}
                                                tax={select.tax}
                                                fee={select.fee}
                                                result={result}
                                            />
                                        ) : (
                                            <div className='col-12 d-flex align-items-center justify-content-center position-relative empty min-h-500px'>
                                                <div className='code'>404 </div>
                                                <div className='message' style={{ padding: 10 }}>
                                                    ไม่พบหน้านี้
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
