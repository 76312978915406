import React, { useEffect, useState, useRef } from 'react';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import Select, { components } from 'react-select';

import NumberFormat from 'react-number-format';

import TextareaAutosize from "react-textarea-autosize";

import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';

import initaddress from '../../../environments/address';

import config from '../../../environments/config';

const controlStyles = {
    borderRadius: '0.85rem',
    // padding: '5px',
    // background: '#F3F6F9',
    color: 'white'
};

const ControlComponent = (props) => (
    <div style={controlStyles}>
        {/* {<p>Custom Control</p>} */}
        <components.Control {...props} />
    </div>
);

const customStyles = {
    menu: (provided, state) => ({
        ...provided
        // backgroundColor: '#F3F6F9'
    }),
    menuPortal: (base) => ({
        ...base,
        zIndex: 9999
    }),
    control: (provided, state) => ({
        ...provided,
        '&:hover': {
            borderColor: '#F64E60'
        },
        backgroundColor: state.isDisabled ? '#F3F6F9' : 'transparent',
        boxShadow: 'none',
        borderRadius: '0.85rem',
        borderColor: state.isFocused ? '#F64E60' : '#E4E6EF',
        borderStyle: 'solid',
        borderWidth: '1px'
    })
};

const controlStyles2 = {
    borderRadius: '0.85rem',
    // padding: '5px',
    background: '#fff',
    color: 'white'
};

const ControlComponent2 = (props) => (
    <div style={controlStyles2}>
        {/* {<p>Custom Control</p>} */}
        <components.Control {...props} />
    </div>
);

const customStyles2 = {
    menu: (provided, state) => ({
        ...provided
        // backgroundColor: '#F3F6F9'
    }),
    menuPortal: (base) => ({
        ...base,
        zIndex: 9999
    }),
    control: (provided, state) => ({
        ...provided,
        '&:hover': {
            borderColor: '#F64E60'
        },
        backgroundColor: state.isDisabled ? '#F3F6F9' : 'transparent',
        boxShadow: 'none',
        borderRadius: '0.85rem',
        borderColor: state.isFocused ? '#F64E60' : '#E4E6EF',
        borderStyle: 'solid',
        borderWidth: '1px'
    })
};

export function ShopCartCheckout(props) {
    const ref = useRef(null);

    const [ loading, setLoading ] = useState(false);
    const [ priceloading, setPriceLoading ] = useState(false);
    const [ couponloading, setCouponLoading ] = useState(false);
    const [ addresslist, setAddresslist ] = useState({});
    const [ selected, setSelected ] = useState('');
    const [ contact, setContact ] = useState({});
    const [ shippingprice, setShippingprice ] = useState(0);
    const [ discountprice, setDiscountprice ] = useState(0);
    const [ vatprice, setVatprice ] = useState(0);
    const [ totalprice, setTotalprice ] = useState(0);

    // init value
    const [ mainprovince, setMainProvince ] = useState([]);
    const [ maindistrict, setMainDistrict ] = useState([]);
    const [ mainsubdistrict, setMainSubDistrict ] = useState([]);

    const btnSelect = (index, object) => {
        if (index !== 'new') {
            let json = object;
            for (const thisprovince in initaddress.province) {
                if (initaddress.province[thisprovince]['value'] === json.province.toString()) {
                    json['provincelabel'] = initaddress.province[thisprovince]['label'];
                    break;
                }
                json['provincelabel'] = '';
            }
            for (const thisdistrict in initaddress.district) {
                if (initaddress.district[thisdistrict]['value'] === json.district.toString()) {
                    json['districtlabel'] = initaddress.district[thisdistrict]['label'];
                    break;
                }
                json['districtlabel'] = '';
            }
            for (const thissubdistrict in initaddress.subdistrict) {
                if (initaddress.subdistrict[thissubdistrict]['value'] === json.subdistrict.toString()) {
                    json['subdistrictlabel'] = initaddress.subdistrict[thissubdistrict]['label'];
                    break;
                }
                json['subdistrictlabel'] = '';
            }
            setContact(object);
        }
        if (index !== selected) {
            setSelected(index);
        } else {
            setSelected('');
        }
    };

    const selectProvince = (item) => {
        props.setDistrict('');
        props.setSubdistrict('');
        setMainSubDistrict([]);
        props.setZipcode('');
        let thisDistrict = [];
        let thisindex = 0;
        if (item) {
            props.setProvince(item);
            for (const prop in initaddress.district) {
                if (initaddress.district[prop]['provinceid'] === item['value']) {
                    thisDistrict[thisindex] = initaddress.district[prop];
                    thisindex += 1;
                }
            }
        } else {
            props.setProvince('');
        }
        setMainDistrict(thisDistrict);
    };

    const selectDistrict = (item) => {
        props.setSubdistrict('');
        props.setZipcode('');
        let thisSubDistrict = [];
        let thisindex = 0;
        if (item) {
            props.setDistrict(item);
            for (const prop in initaddress.subdistrict) {
                if (initaddress.subdistrict[prop]['districtid'] === item['value']) {
                    thisSubDistrict[thisindex] = initaddress.subdistrict[prop];
                    thisindex += 1;
                }
            }
        } else {
            props.setDistrict('');
        }
        setMainSubDistrict(thisSubDistrict);
    };

    const selectSubDistrict = (item) => {
        let thisZipcode = '';
        if (item) {
            props.setSubdistrict(item);
            thisZipcode = item['zipcode'];
        } else {
            props.setSubdistrict('');
        }
        props.setZipcode(thisZipcode);
    };

    const selectCoupon = (item) => {
        if (item) {
            props.setCoupon(item);
            getSummary(item);
        } else {
            props.setCoupon('');
            getSummary('');
        }
    };

    const scroll = (size, option) => {
        const osInstance = ref.current.osInstance();
        if (osInstance) {
            osInstance.scrollStop().scroll(
                {
                    x: `${option}= ${size}px`
                },
                1000,
                'easeOutCubic'
            );
        }
    };

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength);
        }
    };

    useEffect(() => {
        setMainProvince(initaddress.province);
        getAddress();
        getSummary('');
        getCoupons();
    }, []);

    const getAddress = () => {
        setLoading(true);
        fetch(config.punsook_shop_url + 'member/address/find', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: props.shoptoken
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setAddresslist(result);
                // if (result.responsecode === 0) {
                //     setAmount(0);
                //     setEditVal({});
                //     getCarts();
                // } else {
                //     setLoading(false);
                //     toast.warn(`${result.responsecode}! ไม่สามารถแก้ไขสินค้าในตะกร้าได้ กรุณาลองใหม่อีกครั้ง`, {
                //         position: 'top-right',
                //         autoClose: 5000,
                //         hideProgressBar: false,
                //         closeOnClick: false,
                //         pauseOnHover: true,
                //         draggable: false,
                //         progress: undefined
                //     });
                // }
            })
            .catch((error) => {
                setLoading(false);
                // toast.error('ไม่สามารถแก้ไขสินค้าในตะกร้าได้ กรุณาลองใหม่อีกครั้ง', {
                //     position: 'top-right',
                //     autoClose: 5000,
                //     hideProgressBar: false,
                //     closeOnClick: false,
                //     pauseOnHover: true,
                //     draggable: false,
                //     progress: undefined
                // });
                // console.log(error);
            });
    };

    const getCoupons = () => {
        setCouponLoading(true);
        fetch(config.punsook_shop_url + 'member/coupon/find/by-user', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: props.shoptoken
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setCouponLoading(false);
                // console.log(result)
                if (result.responsecode === 0) {
                    let couponlist = [];
                    for (const couponindex in result.info) {
                        // if (result.info[couponindex]['status'] === 'enable') {
                        couponlist.push({
                            label:
                                result.info[couponindex]['coupon_code'].toString() +
                                ' ' +
                                result.info[couponindex]['user_coupon_create_date'].toString(),
                            value: result.info[couponindex]['user_coupon_id'].toString()
                        });
                        // }
                    }
                    props.setCoupons(couponlist);
                }
            })
            .catch((error) => {
                setCouponLoading(false);
            });
    };

    const getSummary = (couponid) => {
        let param = {
            token: props.shoptoken,
            cart_id: props.cartitems
        };
        if (couponid !== '') {
            param['coupon_id'] = [ couponid.value ];
        }
        // if (coupon !== '') {
        //     param['coupon_id'] = [ couponid.value ];
        // }
        setPriceLoading(true);
        fetch(config.punsook_shop_url + 'member/cart/get-bill-summary', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify(param)
        })
            .then((response) => response.json())
            .then((result) => {
                setPriceLoading(false);
                if (result.responsecode === 0) {
                    setShippingprice(result.shipping_price);
                    setDiscountprice(result.discount_price);
                    setTotalprice(result.summary_price);
                    setVatprice(result.vat_price);
                }
            })
            .catch((error) => {
                setPriceLoading(false);
            });
    };
    return (
        <div className='row d-flex justify-content-center py-4 px-0 bg-white'>
            <div className='col-md-7'>
                <div className='p-5'>
                    <div className='d-flex row m-0 p-2'>
                        <div className='col-12 p-0'>
                            <div className='form-group fv-plugins-icon-container'>
                                <div className='row m-0'>
                                    <div className='my-auto'>
                                        <b>เลือกที่อยู่จัดส่ง</b>
                                    </div>
                                    <div className='ml-auto d-none d-md-block'>
                                        <button className='btn btn-secondary mr-3' onClick={() => scroll('200', '-')}>
                                            <i className='fas fa-chevron-left' />
                                        </button>
                                        <button className='btn btn-secondary' onClick={() => scroll('200', '+')}>
                                            <i className='fas fa-chevron-right' />
                                        </button>
                                    </div>
                                </div>
                                <div id='wallet-info'>
                                    {loading ? (
                                        <div className='row align-items-center col-12 p-0 m-0 min-h-100px'>
                                            <div className='text-center col-12 p-0'>
                                                <i className='text-dark-75 fas fa-spinner fa-pulse fa-5x' />
                                                <h1 className='col mt-5'>กรุณารอสักครู่</h1>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {Object.keys(addresslist).length <= 0 ? (
                                                <div className='row align-items-center col-12 p-0 m-0 min-h-100px'>
                                                    <div className='text-center col-12 p-0'>
                                                        <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                                        <h1 className='col mt-5'>
                                                            มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง
                                                        </h1>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    {addresslist.responsecode !== 0 ? (
                                                        <div className='row align-items-center col-12 p-0 m-0 min-h-100px'>
                                                            <div className='text-center col-12 p-0'>
                                                                <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                                                <h1 className='col mt-5'>
                                                                    มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง
                                                                </h1>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className='d-flex align-items-center'>
                                                            <div className='cards-menu w-100'>
                                                                <div className='card-list testimonial-group'>
                                                                    <div className='inline-scroll'>
                                                                        <OverlayScrollbarsComponent
                                                                            className='wv-100'
                                                                            ref={ref}
                                                                        >
                                                                            {addresslist.info.map((object, index) => {
                                                                                let province = '';
                                                                                let district = '';
                                                                                let subdistrict = '';
                                                                                for (const thisprovince in initaddress.province) {
                                                                                    if (
                                                                                        initaddress.province[
                                                                                            thisprovince
                                                                                        ]['value'] ===
                                                                                        object.province.toString()
                                                                                    ) {
                                                                                        province =
                                                                                            initaddress.province[
                                                                                                thisprovince
                                                                                            ]['label'];
                                                                                        break;
                                                                                    }
                                                                                }
                                                                                for (const thisdistrict in initaddress.district) {
                                                                                    if (
                                                                                        initaddress.district[
                                                                                            thisdistrict
                                                                                        ]['value'] ===
                                                                                        object.district.toString()
                                                                                    ) {
                                                                                        district =
                                                                                            initaddress.district[
                                                                                                thisdistrict
                                                                                            ]['label'];
                                                                                        break;
                                                                                    }
                                                                                }
                                                                                for (const thissubdistrict in initaddress.subdistrict) {
                                                                                    if (
                                                                                        initaddress.subdistrict[
                                                                                            thissubdistrict
                                                                                        ]['value'] ===
                                                                                        object.subdistrict.toString()
                                                                                    ) {
                                                                                        subdistrict =
                                                                                            initaddress.subdistrict[
                                                                                                thissubdistrict
                                                                                            ]['label'];
                                                                                        break;
                                                                                    }
                                                                                }
                                                                                return (
                                                                                    <div
                                                                                        className='inline-card'
                                                                                        key={index}
                                                                                        onClick={() =>
                                                                                            btnSelect(
                                                                                                object.id,
                                                                                                object
                                                                                            )}
                                                                                    >
                                                                                        <div
                                                                                            className={`card cursor-pointer max-h-80px my-4${selected ===
                                                                                            object.id
                                                                                                ? ' sim-active'
                                                                                                : ''}`}
                                                                                        >
                                                                                            <div className='symbol symbol-40 symbol-light-white mr-5'>
                                                                                                <div className='symbol-label'>
                                                                                                    <i className='fas fa-truck' />
                                                                                                </div>
                                                                                            </div>
                                                                                            <OverlayTrigger
                                                                                                placement={'bottom'}
                                                                                                overlay={
                                                                                                    <Tooltip>
                                                                                                        {[
                                                                                                            [
                                                                                                                object.address1,
                                                                                                                district,
                                                                                                                subdistrict,
                                                                                                                province
                                                                                                            ].join(
                                                                                                                ', '
                                                                                                            ),
                                                                                                            object.postalcode,
                                                                                                            '(' +
                                                                                                                object.contact_firstname,
                                                                                                            object.contact_lastname +
                                                                                                                ')'
                                                                                                        ].join(' ')}
                                                                                                    </Tooltip>
                                                                                                }
                                                                                            >
                                                                                                <p className='card-number mt-1 mb-0 overflow-hidden text-nowrap'>
                                                                                                    {[
                                                                                                        [
                                                                                                            object.address1,
                                                                                                            district,
                                                                                                            subdistrict,
                                                                                                            province
                                                                                                        ].join(', '),
                                                                                                        object.postalcode,
                                                                                                        '(' +
                                                                                                            object.contact_firstname,
                                                                                                        object.contact_lastname +
                                                                                                            ')'
                                                                                                    ].join(' ')}
                                                                                                </p>
                                                                                            </OverlayTrigger>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                            <div
                                                                                className='inline-card'
                                                                                onClick={() => btnSelect('new', {})}
                                                                            >
                                                                                <div
                                                                                    className={`card cursor-pointer max-h-80px my-4${selected ===
                                                                                    'new'
                                                                                        ? ' sim-active'
                                                                                        : ''}`}
                                                                                >
                                                                                    <div className='symbol symbol-40 symbol-light-white mr-5'>
                                                                                        <div className='symbol-label'>
                                                                                            <i className='fas fa-truck' />
                                                                                        </div>
                                                                                    </div>
                                                                                    <p className='card-number mt-1 mb-0 overflow-hidden text-nowrap'>
                                                                                        เพิ่มข้อมูลใหม่
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </OverlayScrollbarsComponent>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                            {selected === 'new' ? (
                                <form>
                                    <div>
                                        <p>
                                            <i className='fas fa-user mr-3' />
                                            <b>ข้อมูลติดต่อ</b>
                                        </p>
                                    </div>
                                    <div className='row'>
                                        <div className='form-group col-md-6'>
                                            <label>
                                                ชื่อผู้รับสินค้า<span className='required-text'>*</span>
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                value={props.firstname}
                                                onChange={(e) => props.setFirstname(e.target.value)}
                                            />
                                        </div>
                                        <div className='form-group col-md-6'>
                                            <label>
                                                นามสกุลผู้รับสินค้า<span className='required-text'>*</span>
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                value={props.lastname}
                                                onChange={(e) => props.setLastname(e.target.value)}
                                            />
                                        </div>
                                        <div className='form-group col-md-6'>
                                            <label>
                                                เบอร์ติดต่อผู้รับสินค้า<span className='required-text'>*</span>
                                            </label>
                                            <input
                                                type='text'
                                                inputMode='tel'
                                                className='form-control'
                                                value={props.contactnumber}
                                                onChange={(e) => props.setContactnumber(e.target.value)}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                maxLength='10'
                                                onInput={maxLengthCheck}
                                            />
                                        </div>
                                    </div>
                                    <hr />
                                    <div>
                                        <p>
                                            <i className='fas fa-truck mr-3' />
                                            <b>ที่อยู่สำหรับการจัดส่ง</b>
                                        </p>
                                    </div>
                                    <div className='row'>
                                        <div className='form-group col-md-6'>
                                            <label>
                                                จังหวัด<span className='required-text'>*</span>
                                            </label>
                                            <Select
                                                components={{
                                                    Control: ControlComponent
                                                }}
                                                noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                                value={props.province}
                                                options={mainprovince}
                                                placeholder='เลือก'
                                                id='province'
                                                onChange={selectProvince}
                                                isClearable={true}
                                                menuPortalTarget={document.body}
                                                styles={customStyles}
                                                // isDisabled={
                                                //     mainprovince ===
                                                //         undefined ||
                                                //     mainprovince.length === 0 ||
                                                //     error
                                                // }
                                            />
                                        </div>
                                        <div className='form-group col-md-6'>
                                            <label>
                                                {props.province === '' ? (
                                                    'เขต/อำเภอ'
                                                ) : props.province.label === 'กรุงเทพมหานคร' ? (
                                                    'เขต'
                                                ) : (
                                                    'อำเภอ'
                                                )}
                                                <span className='required-text'>*</span>
                                            </label>
                                            <Select
                                                components={{
                                                    Control: ControlComponent
                                                }}
                                                noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                                value={props.district}
                                                options={maindistrict}
                                                placeholder='เลือก'
                                                id='district'
                                                onChange={selectDistrict}
                                                isClearable={true}
                                                menuPortalTarget={document.body}
                                                styles={customStyles}
                                                isDisabled={props.province === ''}
                                            />
                                        </div>
                                        <div className='form-group col-md-6'>
                                            <label>
                                                {props.province === '' ? (
                                                    'แขวง/ตำบล'
                                                ) : props.province.label === 'กรุงเทพมหานคร' ? (
                                                    'แขวง'
                                                ) : (
                                                    'ตำบล'
                                                )}
                                                <span className='required-text'>*</span>
                                            </label>
                                            <Select
                                                components={{
                                                    Control: ControlComponent
                                                }}
                                                noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                                value={props.subdistrict}
                                                options={mainsubdistrict}
                                                placeholder='เลือก'
                                                id='subdistrict'
                                                onChange={selectSubDistrict}
                                                isClearable={true}
                                                menuPortalTarget={document.body}
                                                styles={customStyles}
                                                isDisabled={props.district === ''}
                                            />
                                        </div>
                                        <div className='form-group col-md-6'>
                                            <label>
                                                รหัสไปรษณีย์<span className='required-text'>*</span>
                                            </label>
                                            <input
                                                type='text'
                                                inputMode='tel'
                                                className='form-control'
                                                value={props.zipcode}
                                                onChange={(e) => props.setZipcode(e.target.value)}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                maxLength='5'
                                                onInput={maxLengthCheck}
                                            />
                                        </div>
                                        <div className='form-group col-md-12'>
                                            <label>
                                                ที่อยู่<span className='required-text'>*</span>
                                            </label>
                                            <textarea
                                                className='form-control h-38px'
                                                value={props.address}
                                                onChange={(e) => props.setAddress(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </form>
                            ) : selected === '' ? null : (
                                <div>
                                    <div>
                                        <p>
                                            <i className='fas fa-user mr-3' />
                                            <b>ข้อมูลติดต่อ</b>
                                        </p>
                                    </div>
                                    <div className='row'>
                                        <div className='form-group col-md-6'>
                                            <label>ชื่อผู้รับสินค้า</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                value={contact.contact_firstname}
                                                disabled
                                            />
                                        </div>
                                        <div className='form-group col-md-6'>
                                            <label>นามสกุลผู้รับสินค้า</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                value={contact.contact_lastname}
                                                disabled
                                            />
                                        </div>
                                        <div className='form-group col-md-6'>
                                            <label>เบอร์ติดต่อผู้รับสินค้า</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                value={contact.contact_phonenumber}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <hr />
                                    <div>
                                        <p>
                                            <i className='fas fa-truck mr-3' />
                                            <b>ที่อยู่สำหรับการจัดส่ง</b>
                                        </p>
                                    </div>
                                    <div className='row'>
                                        <div className='form-group col-md-6'>
                                            <label>จังหวัด</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                value={contact.provincelabel}
                                                disabled
                                            />
                                        </div>
                                        <div className='form-group col-md-6'>
                                            <label>เขต/อำเภอ</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                value={contact.districtlabel}
                                                disabled
                                            />
                                        </div>
                                        <div className='form-group col-md-6'>
                                            <label>แขวง/ตำบล</label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                value={contact.subdistrictlabel}
                                                disabled
                                            />
                                        </div>
                                        <div className='form-group col-md-6'>
                                            <label>รหัสไปรษณีย์</label>
                                            <input
                                                type='tel'
                                                className='form-control'
                                                value={contact.postalcode}
                                                disabled
                                            />
                                        </div>
                                        <div className='form-group col-md-12'>
                                            <label>ที่อยู่</label>
                                            <textarea
                                                className='form-control h-38px'
                                                value={contact.address1}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-md-5 mobile'>
                <div className='bg-light rounded d-flex flex-column p-3' id='cartsummary'>
                    <div className='p-2 ml-3'>
                        <h4>สรุปรายละเอียดสินค้า</h4>
                    </div>
                    {props.carts.info.map((object, index) => {
                        return (
                            <div
                                className='d-flex jusitfy-content-between align-items-center pt-3 pb-3 border-bottom'
                                key={index}
                            >
                                <div className='item pr-2'>
                                    <img
                                        src={
                                            Object.keys(object.gallery).length > 0 &&
                                            object.gallery.cover_image !== '' ? (
                                                `data:image/png;base64,${object.gallery.cover_image}`
                                            ) : (
                                                toAbsoluteUrl(`/media/products/${index + 1}.png`)
                                            )
                                        }
                                        alt={`product-${object.code}`}
                                        className='w-100px'
                                    />
                                </div>
                                <div className='d-flex flex-column px-3'>
                                    <b className='h5'>{object.name}</b>
                                    <p className='m-0'>x ( {object.quantity} ) ชิ้น</p>
                                </div>
                                <div className='ml-auto'>
                                    <b className='h5'>
                                        <NumberFormat
                                            value={parseFloat(object.price * object.quantity)}
                                            displayType={'text'}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            thousandSeparator={true}
                                            suffix={' บาท'}
                                        />
                                    </b>
                                </div>
                            </div>
                        );
                    })}
                    <div className='p-2 d-flex pt-3 row m-0'>
                        <div className='col-12'>
                            <b>หมายเหตุ</b>
                        </div>
                        <div className='col-12 mt-5'>
                            <TextareaAutosize
                                id="remark"
                                name="remark"
                                className="form-control"
                                maxLength="255"
                                value={props.remark}
                                onChange={(e) => props.setRemark(e.target.value)}
                                onInput={maxLengthCheck}
                            />
                        </div>
                    </div>
                    <div className='p-2 d-flex pt-3 row m-0'>
                        <div className='col-12'>
                            <b>คูปองที่คุณมี</b>
                        </div>
                        <div className='col-12 mt-5'>
                            <Select
                                components={{
                                    Control: ControlComponent2
                                }}
                                noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                value={props.coupon}
                                options={props.coupons}
                                placeholder='เลือก'
                                id='subdistrict'
                                onChange={selectCoupon}
                                isClearable={true}
                                menuPortalTarget={document.body}
                                styles={customStyles2}
                                isDisabled={props.coupons.length === 0}
                            />
                        </div>
                    </div>
                    <div className='p-2 d-flex pt-3'>
                        <div className='col-8'>
                            <b>ส่วนลด</b>
                        </div>
                        <div className='ml-auto'>
                            {priceloading ? (
                                <i className='fas fa-spinner fa-pulse green' />
                            ) : (
                                <b className='green'>
                                    <NumberFormat
                                        value={parseFloat(discountprice)}
                                        displayType={'text'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                        suffix={' บาท'}
                                    />
                                </b>
                            )}
                        </div>
                    </div>
                    <div className='p-2 d-flex pt-3'>
                        <div className='col-8'>
                            <b>ภาษีมูลค่าเพิ่ม</b>
                        </div>
                        <div className='ml-auto'>
                            {priceloading ? (
                                <i className='fas fa-spinner fa-pulse green' />
                            ) : (
                                <b className='green'>
                                    <NumberFormat
                                        value={parseFloat(vatprice)}
                                        displayType={'text'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                        suffix={' บาท'}
                                    />
                                </b>
                            )}
                        </div>
                    </div>
                    <div className='p-2 d-flex pt-3'>
                        <div className='col-8'>
                            <b>ค่าจัดส่ง</b>
                        </div>
                        <div className='ml-auto'>
                            {priceloading ? (
                                <i className='fas fa-spinner fa-pulse green' />
                            ) : (
                                <b className='green'>
                                    <NumberFormat
                                        value={parseFloat(shippingprice)}
                                        displayType={'text'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                        suffix={' บาท'}
                                    />
                                </b>
                            )}
                        </div>
                    </div>
                    <div className='border-top px-4 mx-3' />
                    <div className='p-2 d-flex pt-3'>
                        <div className='col-8'>
                            <b>ยอดรวม</b>
                        </div>
                        <div className='ml-auto'>
                            {priceloading ? (
                                <i className='fas fa-spinner fa-pulse green' />
                            ) : (
                                <b className='green'>
                                    <NumberFormat
                                        value={parseFloat(totalprice)}
                                        displayType={'text'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                        suffix={' บาท'}
                                    />
                                </b>
                            )}
                        </div>
                    </div>
                    <div className='border-top px-4 mx-3' />
                    <div className='d-flex flex-row align-content-center p-4'>
                        <div className='pt-2 pr-2 col-xl-12'>
                            <div className='radio-inline'>
                                <label className='radio radio-primary col-xl-12'>
                                    <input
                                        type='radio'
                                        name='radio'
                                        checked={props.payment === 'qrcode'}
                                        onChange={() => props.setPayment('qrcode')}
                                    />
                                    <span />
                                    <div className='rounded border d-flex w-100 px-2 bg-white'>
                                        <div className='row p-0 m-0'>
                                            <p className='my-auto'>
                                                <i className='fas fa-qrcode text-primary pr-2' />
                                                ชำระเงินผ่านคิวอาร์โค้ด
                                            </p>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-row align-content-center p-4'>
                        <div className='pt-2 pr-2 col-xl-12'>
                            <div className='radio-inline'>
                                <label className='radio radio-primary col-xl-12'>
                                    <input
                                        type='radio'
                                        name='radio'
                                        checked={props.payment === 'wallet'}
                                        onChange={() => props.setPayment('wallet')}
                                    />
                                    <span />
                                    <div className='rounded border d-flex w-100 px-2 bg-white'>
                                        <div className='row p-0 m-0'>
                                            <p className='my-auto'>
                                                <i className='fas fa-wallet text-primary pr-2' />
                                                กระเป๋าซิมปันสุข
                                            </p>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='m-3'>
                        <button
                            type='button'
                            className='btn btn-primary btn-block rounded-pill'
                            onClick={() => props.btnNext(selected)}
                        >
                            ชำระเงิน
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
