import React, { useEffect } from 'react';
import $ from 'jquery';

// let table = '';

export function SimkitTable(props) {
    const reg_url = `https://reg.simk4.com`;
    const copyToClipboard = (phonenumber) => {
        var aux = document.createElement('input');
        aux.setAttribute('value', `${reg_url}/simkit/register?mobile=${phonenumber}&userid=${props.user.dealerid}`);
        document.body.appendChild(aux);
        aux.select();
        document.execCommand('copy');
        document.body.removeChild(aux);
    };

    const btnBack = () => {
        // table.destroy();
        props.setPage('main');
    };

    useEffect(
        () => {
            $(document).ready(function() {
                if (Object.keys(props.data).length > 0) {
                    if (props.data.responsecode === 0) {
                        $('#kt_sim_kit_detail_table').DataTable({
                            data: props.data.items,
                            lengthChange: false,
                            pageLength: -1,
                            responsive: true,
                            destroy: true,
                            // order: false,
                            // dom: 'Bfrtip',
                            // buttons: [ 'excel' ],
                            language: {
                                paginate: {
                                    previous: 'ก่อนหน้า',
                                    next: 'ถัดไป'
                                },
                                info: 'แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ',
                                infoEmpty: 'แสดง _START_ ถึง _END_ จาก _TOTAL_ รายการ',
                                emptyTable: 'ไม่มีข้อมูลที่ต้องการค้นหาจากระบบ',
                                infoFiltered: '(ค้นหาจากทั้งหมด _MAX_ รายการ)',
                                zeroRecords: 'ไม่พบข้อมูลที่ต้องการค้นหาจากคำสำคัญ'
                            },
                            oLanguage: {
                                sSearch: 'คำสำคัญ'
                            },
                            columns: [
                                {
                                    title: 'ลิงค์สมัครสมาชิก',
                                    className: 'dt-body-center',
                                    width: '100px',
                                    data: function(data, type, row) {
                                        if (data.simpack_number_status === 'pending') {
                                            return `<div class='row justify-content-between m-0'>
                                                <button 
                                                class='btn btn-success view-link'
                                                data-phonenumber=${data.phonenumber}
                                                >
                                                    <i class="far fa-copy p-0"></i>
                                                </button>
                                                <a class='btn btn-warning' href="${reg_url}/simkit/register?mobile=${data.phonenumber}&userid=${props
                                                .user.dealerid}" target="_blank">
                                                    <i class="fas fa-user-plus p-0"></i>
                                                </a>
                                            </div>`;
                                        } else {
                                            return '';
                                        }
                                    }
                                },
                                {
                                    title: 'เบอร์',
                                    className: 'dt-body-center',
                                    width: '80px',
                                    data: function(data) {
                                        return data.phonenumber;
                                    }
                                },
                                {
                                    title: 'หมายเลขซิม',
                                    className: 'dt-body-center',
                                    width: '200px',
                                    data: function(data) {
                                        return data.iccid;
                                    }
                                },
                                {
                                    title: 'สถานะ',
                                    className: 'dt-body-center',
                                    width: '200px',
                                    data: function(data) {
                                        if (data.simpack_number_status !== null && data.simpack_number_status !== '') {
                                            if (data.simpack_number_status === 'pending') {
                                                return '<span class="badge badge-secondary mx-auto my-auto">รอการสั่งซื้อ</span>';
                                            } else {
                                                return '<span class="badge badge-success mx-auto my-auto">สั่งซื้อเรียบร้อย</span>';
                                            }
                                        } else {
                                            return '<span class="badge badge-danger mx-auto my-auto">ไม่พร้อมให้บริการ</span>';
                                        }
                                    }
                                },
                                {
                                    title: 'ที่อยู่จัดส่ง',
                                    className: 'dt-body-center',
                                    width: '200px',
                                    data: function(data) {
                                        if (data.address !== null) {
                                            return data.address;
                                        } else {
                                            return 'ไม่ต้องจัดส่ง';
                                        }
                                    }
                                }
                            ]
                        });
                    }
                }
            });
        },
        [ props.data ]
    );

    useEffect(() => {
        $(document).ready(function() {
            $('.view-link').click(function() {
                console.log('attr', $(this).attr('data-phonenumber'));
                copyToClipboard($(this).attr('data-phonenumber'));
            });
            // $('.view-regis').click(function() {
            //     console.log('attr', $(this).attr('data-mobile'));
            //     copyToClipboard($(this).attr('data-mobile'));
            // });
        });
    });

    return (
        <div className='container clearfix p-0'>
            <div className='card rounded-lg'>
                <div className='card-header bg-secondary p-4 rounded-lg rounded-bottom-0'>
                    <div className='row justify-content-between m-0'>
                        <button type='button' className='btn btn-link-primary' onClick={btnBack}>
                            <i className='fas fa-angle-double-left icon-md py-2' />
                            ย้อนกลับ
                        </button>
                    </div>
                </div>
                <div className='card-body rounded-lg rounded-top-0'>
                    <table id='kt_sim_kit_detail_table' className='table table-striped table-bordered' />
                </div>
            </div>
        </div>
    );
}
