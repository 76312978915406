import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';

import DatePicker, { utils } from 'react-modern-calendar-datepicker';

import Moment from 'moment';

import 'moment/locale/th';

import { myCustomLocale, minimumDate, NumberBoxDetail } from './components';

import config from '../../environments/config';

export function NumberBoxMain() {
    const history = useHistory();

    const { user } = useSelector((state) => state.auth);

    const [ page, setPage ] = useState('main');
    const [ loading, setLoading ] = useState(false);
    const [ date, setDate ] = useState({
        from: null,
        to: null
    });
    const [ orders, setOrders ] = useState({});
    const [ details, setDetails ] = useState([]);
    const initindex = 5;
    const [ startindex, setStartindex ] = useState(0);
    const [ endindex, setEndindex ] = useState(5);

    useEffect(() => {
        // if (user.shop_account === undefined || user.shop_account === null || user.shop_account === '') {
        //     history.push('/shop/product?page=login');
        // }
        let param = {
            user_id: user.id,
            option: [ 'detail' ]
        };
        getNumberPack(param);
    }, []);

    const btnPrevious = (e) => {
        e.preventDefault();
        setStartindex(startindex - initindex);
        setEndindex(endindex - initindex);
    };

    const btnNext = (e) => {
        e.preventDefault();
        setStartindex(startindex + initindex);
        setEndindex(endindex + initindex);
    };

    const btnBack = () => {
        setDetails([]);
        setPage('main');
    };

    const btnView = (detail) => {
        setDetails(detail);
        setPage('detail');
    };

    const btnEmpty = () => {
        setDate({
            from: null,
            to: null
        });
    };

    const btnSearch = () => {
        let param = {
            user_id: user.id,
            option: [ 'detail' ]
        };
        if (date.from !== null && date.to !== null) {
            param['ordered_date'] = {
                start: date.from.year + '-' + ('0' + date.from.month).slice(-2) + '-' + ('0' + date.from.day).slice(-2),
                end: date.to.year + '-' + ('0' + date.to.month).slice(-2) + '-' + ('0' + date.to.day).slice(-2)
            };
        }
        getNumberPack(param);
    };

    const getNumberPack = (param) => {
        setLoading(true);
        fetch(config.py_url + config.version + '/package/find-by-owner', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                secret: config.secret
            },
            body: JSON.stringify(param)
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                // console.log(result);
                setOrders(result);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const renderCustomInput = ({ ref }) => (
        <input
            readOnly
            ref={ref}
            value={
                date.from && date.to ? (
                    date.from.year +
                    '/' +
                    ('0' + date.from.month).slice(-2) +
                    '/' +
                    ('0' + date.from.day).slice(-2) +
                    ' - ' +
                    date.to.year +
                    '/' +
                    ('0' + date.to.month).slice(-2) +
                    '/' +
                    ('0' + date.to.day).slice(-2)
                ) : (
                    ''
                )
            }
            className='col-12 form-control h-100 border-0 rounded-lg rounded-right-0'
            type='text'
            id='dateperiod'
            name='dateperiod'
        />
    );
    return (
        <div>
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='history-page'>
                    {page === 'main' ? (
                        <div className='row m-0'>
                            <div className='col-12 col-md-4 my-5 ml-auto p-0'>
                                <div className='input-group'>
                                    <DatePicker
                                        value={date}
                                        onChange={setDate}
                                        renderInput={renderCustomInput}
                                        minimumDate={minimumDate}
                                        maximumDate={utils().getToday()}
                                        locale={myCustomLocale}
                                        wrapperClassName='col-12 p-0 form-control rounded-lg rounded-right-0'
                                        calendarClassName='responsive-calendar'
                                        calendarPopperPosition='left'
                                        shouldHighlightWeekends
                                    />
                                    <div className='input-group-append'>
                                        {date.from !== null &&
                                        date.to !== null && (
                                            <button className='btn btn-danger' type='button' onClick={btnEmpty}>
                                                <i className='fas fa-times p-0' />
                                            </button>
                                        )}
                                        <button
                                            className='btn btn-secondary rounded-lg rounded-left-0'
                                            type='button'
                                            onClick={btnSearch}
                                        >
                                            ค้นหา
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 clearfix bg-white p-5'>
                                {Object.keys(orders).length <= 0 ? (
                                    <div className='row justify-content-center align-items-center bg-white cart-box min-h-500px'>
                                        <div className='col-12 text-center'>
                                            <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                            <h3 className='col mt-5'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h3>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        {orders.responsecode !== 0 ? (
                                            <div className='row justify-content-center align-items-center bg-white cart-box min-h-500px'>
                                                <div className='col-12 text-center'>
                                                    <i className='text-dark-75 fas fa-exclamation-circle fa-5x' />
                                                    <h3 className='col mt-5'>ไม่พบประวัติการสั่งซื้อของคุณ</h3>
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <ul className='xsearch-items'>
                                                    {orders.info
                                                        .slice(startindex, endindex)
                                                        .map((object, index, arr) => {
                                                            return (
                                                                <div key={index}>
                                                                    <li className='search-item'>
                                                                        <div className='search-item-content mx-7 pb-0'>
                                                                            <h3 className='search-item-caption'>
                                                                                <div className='text-primary d-inline-block'>
                                                                                    # {object.id}
                                                                                </div>
                                                                            </h3>
                                                                            <div className='search-item-meta mb-5'>
                                                                                <ul className='list-inline'>
                                                                                    <li className='time d-none d-lg-block'>
                                                                                        <h6>
                                                                                            วันที่:{' '}
                                                                                            {Moment(
                                                                                                new Date(
                                                                                                    object.created_date
                                                                                                )
                                                                                            )
                                                                                                .add(543, 'year')
                                                                                                .locale('th')
                                                                                                .format(
                                                                                                    'DD/MMMM/YYYY HH:mm:ss'
                                                                                                )}{' '}
                                                                                            | การชำระเงิน:{' '}
                                                                                            <span>
                                                                                                <strong
                                                                                                    className={`${object.status ===
                                                                                                    'ordered'
                                                                                                        ? 'text-secondary'
                                                                                                        : object.status ===
                                                                                                          'paid'
                                                                                                          ? 'text-info'
                                                                                                          : 'text-success'}`}
                                                                                                >
                                                                                                    {object.status ===
                                                                                                    'ordered' ? (
                                                                                                        'รอการชำระเงิน'
                                                                                                    ) : object.status ===
                                                                                                    'paid' ? (
                                                                                                        'ชำระแล้ว'
                                                                                                    ) : (
                                                                                                        'จ่ายค่าตอบแทนแล้ว'
                                                                                                    )}
                                                                                                </strong>
                                                                                            </span>
                                                                                        </h6>
                                                                                    </li>
                                                                                    <li className='time d-block d-lg-none'>
                                                                                        <h6>
                                                                                            วันที่:{' '}
                                                                                            {Moment(
                                                                                                new Date(
                                                                                                    object.created_date
                                                                                                )
                                                                                            )
                                                                                                .add(543, 'year')
                                                                                                .locale('th')
                                                                                                .format(
                                                                                                    'DD/MMMM/YYYY HH:mm:ss'
                                                                                                )}
                                                                                        </h6>
                                                                                        <h6>
                                                                                            การชำระเงิน:{' '}
                                                                                            <span>
                                                                                                <strong
                                                                                                    className={`${object.status ===
                                                                                                    'ordered'
                                                                                                        ? 'text-secondary'
                                                                                                        : object.status ===
                                                                                                          'paid'
                                                                                                          ? 'text-info'
                                                                                                          : 'text-success'}`}
                                                                                                >
                                                                                                    {object.status ===
                                                                                                    'ordered' ? (
                                                                                                        'รอการชำระเงิน'
                                                                                                    ) : object.status ===
                                                                                                    'paid' ? (
                                                                                                        'ชำระแล้ว'
                                                                                                    ) : (
                                                                                                        'จ่ายค่าตอบแทนแล้ว'
                                                                                                    )}
                                                                                                </strong>
                                                                                            </span>
                                                                                        </h6>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                            <div className='d-flex justify-content-end'>
                                                                                <button
                                                                                    type='button'
                                                                                    className='btn btn-outline-primary'
                                                                                    onClick={() =>
                                                                                        btnView(object.items)}
                                                                                >
                                                                                    ดูรายละเอียด
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    {arr.length - 1 !== index && <hr />}
                                                                </div>
                                                            );
                                                        })}
                                                </ul>
                                                <div className='d-flex justify-content-between align-items-center flex-wrap'>
                                                    <div className='d-flex flex-wrap py-2 mr-3'>
                                                        {orders.info.length > initindex && (
                                                            <button
                                                                type='button'
                                                                className='btn btn-icon btn-sm btn-light-danger mr-2 my-1'
                                                                onClick={btnPrevious}
                                                                disabled={endindex === initindex}
                                                            >
                                                                <i className='ki ki-bold-arrow-back icon-xs' />
                                                            </button>
                                                        )}
                                                        {orders.info.length > initindex && (
                                                            <button
                                                                type='button'
                                                                className='btn btn-icon btn-sm btn-light-danger mr-2 my-1'
                                                                onClick={btnNext}
                                                                disabled={endindex >= orders.info.length}
                                                            >
                                                                <i className='ki ki-bold-arrow-next icon-xs' />
                                                            </button>
                                                        )}
                                                    </div>
                                                    <div className='d-flex align-items-center py-3'>
                                                        <span className='text-muted'>
                                                            แสดง{' '}
                                                            {endindex >= orders.info.length ? (
                                                                orders.info.length
                                                            ) : (
                                                                endindex
                                                            )}{' '}
                                                            จาก {orders.info.length} รายการ
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : page === 'detail' ? (
                        <NumberBoxDetail btnBack={btnBack} detail={details} />
                    ) : null}
                </div>
            )}
        </div>
    );
}
