import React from 'react';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

export function ShopInsuranceAgreement(props) {
    return (
        <div className='col-lg-10 col-12 p-0'>
            <div className='card'>
                <div className='card-body'>
                    <div className='text-center mb-10'>
                        <h3 className='font-size-h1'>แบบแสดงการแจ้งความประสงค์ซื้อประกันภัยและเงื่อนไขกรมธรรม์</h3>
                        {/* <p className='text-muted font-weight-bold'>ฌาปนกิจ</p> */}
                    </div>
                    <div className='mb-10'>
                        <OverlayScrollbarsComponent className='h-300px'>
                            <div className='row-inner p-5 bg-light'>
                                <h2 className='text-center mb-7'>
                                    <u>แบบแสดงการแจ้งความประสงค์ซื้อประกันภัย</u>
                                </h2>
                                <p>
                                    บริการจองสิทธิ์ประกันกลุ่ม พีเอ ซีเคียวร์ พลัส
                                    เป็นบริการจองสิทธิ์รับประกันอุบัติเหตุจากโบรกเกอร์ บริษัท กราสพ์ อินชัวร์ โบรกเกอร์
                                    จำกัด (“บริษัทประกัน”) เท่านั้น บริษัท เคโฟร์ คอมมูนิเคชั่น จำกัด
                                    มิได้เป็นบริษัทตัวแทนจำหน่ายประกัน และดีลเลอร์มิใช่ตัวแทนจำหน่ายประกันภัย
                                    ดีลเลอร์ยอมรับเงื่อนไขการให้ข้อมูลประกันที่ถูกต้องแก่ดีลเลอร์ที่สนใจ
                                    และรับจองสิทธิ์ประกันเท่านั้น ซึ่งสามารถตรวจสอบแผนกรมธรรม์ เงื่อนไข
                                    วันเริ่มและวันสิ้นสุดกรมธรรม์
                                    และติดต่อสอบถามข้อมูลเพิ่มเติมได้จากเว็บไซต์และ/หรือแอปพลิเคชันของบริษัทประกัน
                                    โดยสามารถเลือกจองแผนประกันได้ 2 ประเภท มีรายละเอียดและเงื่อนไขกรมธรรม์ดังต่อไปนี้
                                </p>
                                <h2 className='text-center mb-7'>
                                    <u>เงื่อนไขกรมธรรม์</u>
                                </h2>
                                <div className='table-container'>
                                    <table className='table table-borderless'>
                                        <tbody>
                                            <tr>
                                                <td className='col-4'>ประเภทการประกันภัย</td>
                                                <td className='col-8'>: การประกันภัยอุบัติเหตุกลุ่ม</td>
                                            </tr>
                                            <tr>
                                                <td className='col-4'>แบบกรมธรรม์ประกันภัย</td>
                                                <td className='col-8'>
                                                    : กรมธรรม์ประกันภัยอุบัติเหตุกลุ่ม พีเอ ซีเคียวร์ พลัส
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='col-4'>ผู้ถือกรมธรรม์ประกันภัย</td>
                                                <td className='col-8'>
                                                    : บริษัท เคโฟร์ คอมมูนิเคชั่น จำกัด (“บริษัท”)
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='col-4'>ผู้เอาประกันภัย</td>
                                                <td className='col-8'>
                                                    :
                                                    ผู้ถือกรมธรรม์ประกันภัยจะส่งรายชื่อผู้เอาประกันภัยตามวันที่กำหนดในแต่ละรอบ
                                                    เดือน (อายุของผู้เอาประกันภัยระหว่าง 16 - 65 ปี) โดยระบุ
                                                    วันเดือนปีเกิด เลขที่บัตรประจำตัวประชาชน ผู้รับผลประโยชน์
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='col-4'>ผู้รับผลประโยชน์</td>
                                                <td className='col-8'>: ตามที่ระบุในกรมธรรม์</td>
                                            </tr>
                                            <tr>
                                                <td className='col-4'>ระยะเวลาประกันภัย</td>
                                                <td className='col-8'>
                                                    : 1 ปี นับจากวันที่ระบุเป็นวันที่เริ่มต้นความคุ้มครอง
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='col-4'>อาณาเขตการคุ้มครอง</td>
                                                <td className='col-8'>: 24 ชั่วโมงทั่วโลก</td>
                                            </tr>
                                            <tr>
                                                <td className='col-4'>ขอบเขตอำนาจศาล/กฎหมาย</td>
                                                <td className='col-8'>: ราชอาณาจักรไทย/กฎหมายไทย</td>
                                            </tr>
                                            <tr>
                                                <td className='col-4'>
                                                    ข้อตกลงคุ้มครอง ทุนประกันภัย/คน ค่ารักษาพยาบาล/คน
                                                </td>
                                                <td className='col-8'>: ตามที่ระบุในตารางผลประโยชน์ความคุ้มครอง</td>
                                            </tr>
                                            {/* <tr>
                                                <td className='col-4'>ข้อตกลงคุ้มครอง</td>
                                                <td className='col-8'>
                                                    : 1. สำหรับความสูญเสียหรือความเสียหาย อันเกิดจากการ
                                                    บาดเจ็บทางร่างกายของผู้เอาประกันภัย และทำให้ผู้เอาประกันภัยเสียชีวิต
                                                    สูญเสียอวัยวะ สายตา หรือทุพพลภาพถาวร (อ.บ. 1) ภายใน 180 วัน
                                                    นับจากวันที่เกิดอุบัติเหตุ
                                                    หรือการบาดเจ็บที่ได้รับทำให้ผู้เอาประกันภัยต้องรักษาตัวติดต่อกันในฐานะผู้ป่วยในในโรงพยาบาล
                                                    หรือสถานพยาบาลเวชกรรม และเสียชีวิตเพราะการบาดเจ็บนั้น
                                                    ตามตารางผลประโยชน์ - การถูกฆาตกรรมและลอบทำร้าย
                                                    ในวงเงินเต็มทุนประกันภัย
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='col-4'>ทุนประกันภัย/คน ค่ารักษาพยาบาล/คน</td>
                                                <td className='col-8'>: ตามที่ระบุด้านล่าง</td>
                                            </tr> */}
                                            <tr>
                                                <td className='col-4'>การจำกัดความรับผิดของบริษัทประกัน</td>
                                                <td className='col-8'>
                                                    : 1. บริษัทฯ จำกัดความรับผิดชอบจากความสูญเสียหรือเสียหาย
                                                    ตามข้อตกลงคุ้มครอง ทั้งนี้จำกัดความรับผิดชอบสูงสุดไม่เกินทุน
                                                    ประกันภัยที่ระบุในหน้าตารางกรมธรรม์ประกันภัย 2.
                                                    ความคุ้มครองเริ่มมีผลทันที ณ วันที่ระบุเป็นวันเริ่มต้นคุ้มครอง
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='col-4'>ข้อยกเว้นหลัก</td>
                                                <td className='col-8'>
                                                    : ก. การฆ่าตัวตาย พยายามฆ่าตัวตาย หรือการทำร้ายร่างกายตนเอง ข.
                                                    การกระทำของผู้ได้รับความคุ้มครองขณะอยู่ภายใต้ฤทธิ์สุราสารเสพติด
                                                    หรือยาเสพติดให้โทษจนไม่สามารถครองสติได้คำว่า "ขณะอยู่ภายใต้ฤทธิ์สุรา
                                                    " นั้น
                                                    ในกรณีที่มีการตรวจเลือดให้ถือเกณฑ์มีระดับแอลกอฮอล์ในเลือดตั้งแต่ 150
                                                    มิลลิกรัมเปอร์เซ็นต์ขึ้นไป ค. การตั้งครรภ์ & การแท้งลูก ง. สงคราม
                                                    การรุกราน การกระทำที่มุ่งร้ายของศัตรูต่างชาติ หรือ
                                                    การกระทำที่มุ่งร้ายคล้ายสงคราม ไม่ว่าจะได้มีการประกาศสงคราม
                                                    หรือไม่ก็ตาม หรือสงครามกลางเมือง การแข็งข้อ การกบฎ การจลาจล
                                                    การนัดหยุดงาน การก่อความวุ่นวาย การปฏิวัติ การรัฐประหาร
                                                    การประกาศกฎอัยการศึก หรือ เหตุการณ์ใดๆ
                                                    ซึ่งจะเป็นเหตุให้มีการประกาศหรือคงไว้ซึ่งกฎอัยการศึกการก่อการร้าย ฉ.
                                                    การแผ่รังสี หรือ การแพร่กัมมันตภาพรังสีจากเชื้อเพลิง นิวเคลียร์
                                                    หรือจากกากนิวเคลียร์ใดๆ
                                                    อันเนื่องมาจากการเผาไหม้ของเชื้อเพลิงนิวเคลียร์
                                                    และจากกรรมวิธีใดๆแห่งการแตกแยกตัวทางนิวเคลียร์ซึ่งดำเนินติดตัวไปด้วยตัวเอง
                                                    ช. การระเบิดของกัมมันตภาพรังสี หรือวัตถุอันตราย
                                                    อันใดที่เกิดการระเบิดในกระบวนการนิวเคลียร์ได้ ซ.
                                                    ขณะที่ผู้ได้รับความคุ้มครองกำลังขึ้นหรือลง
                                                    หรือโดยสารอยู่ในอากาศยานที่มิได้จดทะเบียนเพื่อบรรทุกผู้โดยสาร
                                                    และมิได้ประกอบการโดยสายการบินพาณิชย์
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className='col-4'>เบี้ยประกันภัย (ต่อคน)</td>
                                                <td className='col-8'>: ตามที่ระบุในตารางผลประโยชน์ความคุ้มครอง</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <h3 className='text-center mb-7'>
                                        <u>ตารางผลประโยชน์ความคุ้มครอง</u>
                                    </h3>
                                    <div className='table-container'>
                                        <table className='table table-striped table-bordered bg-white'>
                                            <thead className='thead-dark'>
                                                <tr>
                                                    <th className='col-8 text-center'>ผลประโยชน์ความคุ้มครอง</th>
                                                    <th className='col-2 text-center'>แผนที่ 1</th>
                                                    <th className='col-2 text-center'>แผนที่ 2</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className='col-8'>
                                                        ข้อ 1. กรณีการเสียชีวิต สูญเสียอวัยวะ สายตา
                                                        หรือทุพพลภาพถาวรสิ้นเชิง เนื่องจากอุบัติเหตุทั่วไป (อบ.1)
                                                        (ไม่รวมการถูกฆาตกรรมหรืถูกทำร้ายร่างกาย
                                                        และ/หรือการขับขี่หรือโดยสารรถจักรยานยนต์)
                                                    </td>
                                                    <td className='col-2'>200,000</td>
                                                    <td className='col-2'>500,000</td>
                                                </tr>
                                                <tr>
                                                    <td className='col-8'>
                                                        ข้อ 2. กรณีการเสียชีวิต สูญเสียอวัยวะ สายตา
                                                        หรือทุพพลภาพถาวรสิ้นเชิงจากการถูกฆาตกรรมหรือถูกลอบทำร้ายร่างกาย
                                                    </td>
                                                    <td className='col-2'>200,000</td>
                                                    <td className='col-2'>500,000</td>
                                                </tr>
                                                <tr>
                                                    <td className='col-8'>
                                                        ข้อ 3. กรณีการเสียชีวิต สูญเสียอวัยวะ สายตา
                                                        หรือทุพพลภาพถาวรสิ้นเชิงจากการขับขี่ หรือโดยสารรถจักรยานยนต์
                                                    </td>
                                                    <td className='col-2'>100,000</td>
                                                    <td className='col-2'>250,000</td>
                                                </tr>
                                                <tr>
                                                    <td className='col-8'>
                                                        ข้อ 4. ค่ารักษาพยาบาล (ต่ออุบัติเหตุแต่ละครั้ง)
                                                        เนื่องจากอุบัติเหตุทั่วไป ไม่รวมการขับขี่หรือโดยสารถจักรยานยนต์
                                                    </td>
                                                    <td className='col-2'>15,000</td>
                                                    <td className='col-2'>30,000</td>
                                                </tr>
                                                <tr>
                                                    <td className='col-8'>
                                                        ข้อ 5. ค่าปลงศพหรือค่าใช้จ่ายในการจัดการงานศพ
                                                        กรณีเสียชีวิตจากการเจ็บป่วย
                                                    </td>
                                                    <td className='col-2'>20,000</td>
                                                    <td className='col-2'>30,000</td>
                                                </tr>
                                                <tr>
                                                    <td className='col-8'>
                                                        เบี้ยประกันภัยสุทธิ (ต่อคน/ต่อปี) (ยังไม่รวมภาษีมูลค่าเพิ่ม)
                                                    </td>
                                                    <td className='col-2'>1,000</td>
                                                    <td className='col-2'>2,500</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </OverlayScrollbarsComponent>

                        <div className='row mt-7 px-5'>
                            <div className='text-center'>
                                <div className='radio-inline'>
                                    <label className='radio radio-success'>
                                        <input
                                            type='radio'
                                            name='agree-btn'
                                            checked={props.agreement}
                                            onClick={() => props.setAgreement(!props.agreement)}
                                        />
                                        <span />
                                        ยอมรับข้อกำหนดและเงื่อนไข
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className='form-group d-flex flex-wrap flex-center mb-0'>
                            <button
                                type='button'
                                className='btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4 rounded-lg'
                                onClick={props.btnNext}
                                disabled={!props.agreement}
                            >
                                <span>ถัดไป</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
