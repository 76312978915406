import React from 'react';

import Moment from 'moment';

import 'moment/locale/th';

import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';

export function ShopInsuranceHistoryDetail(props) {
    return (
        <div className='container clearfix p-0'>
            <div className='card rounded-lg'>
                <div className='card-header bg-secondary p-4 rounded-lg rounded-bottom-0'>
                    <div className='row justify-content-between m-0'>
                        <button type='button' className='btn btn-link-primary' onClick={props.btnBack}>
                            <i className='fas fa-angle-double-left icon-md py-2' />
                            ย้อนกลับ
                        </button>
                    </div>
                </div>
                <div className='card-body rounded-lg rounded-top-0'>
                    <div className='row m-0'>
                        <div className='col-12 p-0'>
                            <div className='text-center mb-10'>
                                <h3 className='font-size-h1'>ข้อมูลผู้สั่งซื้อกรมธรรม์</h3>
                            </div>
                            {/* {JSON.stringify(props.details)} */}
                            <div className='d-flex justify-content-between pt-6'>
                                <div className='d-flex flex-column flex-root'>
                                    <span className='font-weight-bolder mb-2'>ผู้ทำประกัน</span>
                                    <span className='opacity-70 mb-5'>
                                        {props.details.info.firstname + ' ' + props.details.info.lastname}
                                    </span>
                                    <span className='font-weight-bolder mb-2'>วัน/เดือน/ปีเกิด</span>
                                    <span className='opacity-70'>
                                        {Moment(new Date(props.details.info.birthdate))
                                            .add(543, 'year')
                                            .locale('th')
                                            .format('DD MMMM YYYY')}
                                    </span>
                                </div>
                                <div className='d-flex flex-column flex-root'>
                                    <span className='font-weight-bolder mb-2'>เลขประจำตัวประชาชน</span>
                                    <span className='opacity-70 mb-5'>{props.details.info.idcard}</span>
                                    <span className='font-weight-bolder mb-2'>ผู้รับผลประโยชน์</span>
                                    <span className='opacity-70'>{props.details.info.beneficiary}</span>
                                </div>
                            </div>
                            <div className='appstore'>
                                <div id='newsspec-19854-app' className='news-app-promo'>
                                    <div className='news-app-promo-text'>
                                        <div className='news-app-promo-text__tagline text-center'>
                                            ติดตามรายละเอียดเพิ่มเติมได้ที่
                                        </div>
                                        {/* <br /> */}
                                        {/* <div className='news-app-promo-text__download'>Download the NAYN.CO App.</div> */}
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-6 col-12 text-md-right text-center my-auto'>
                                            <img
                                                className='m-0'
                                                src={toAbsoluteUrl('/media/logos/tpa-app-logo.png')}
                                                width={106}
                                                height={106}
                                            />
                                        </div>
                                        <div className='col-lg-6 col-12 text-md-left text-center my-auto'>
                                            <a
                                                className='news-app-promo-subsection--link news-app-promo-subsection--playstore'
                                                href='https://play.google.com/store/apps/details?id=th.co.digix.tpacare'
                                                target='_blank'
                                            >
                                                <img
                                                    className='news-app-promo__play-store mx-md-0 mx-auto mt-5 mt-md-0'
                                                    src='//news.files.bbci.co.uk/include/newsspec/19854/assets/app-project-assets/google_play_store.svg'
                                                    width={161}
                                                    height='auto'
                                                    border={0}
                                                />
                                            </a>
                                            <a
                                                className='news-app-promo-subsection--link news-app-promo-subsection--appstore'
                                                href='https://apps.apple.com/th/app/tpa-care/id1456356305?l=th'
                                                target='_blank'
                                            >
                                                <img
                                                    className='news-app-promo__app-store mx-md-0 mx-auto'
                                                    src='//news.files.bbci.co.uk/include/newsspec/19854/assets/app-project-assets/ios_app_store.svg'
                                                    width={161}
                                                    height='auto'
                                                    border={0}
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
