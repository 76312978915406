import React, { useEffect } from 'react';
import Moment from 'moment';
import 'moment/locale/th';
import { QRCode } from 'react-qrcode-logo';
import THBText from 'thai-baht-text';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { pdfImage } from './template';
import html2canvas from 'html2canvas';
import { toAbsoluteUrl } from '../../../../../../_metronic/_helpers';

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
    THSarabunNew: {
        normal: 'THSarabunNew.ttf',
        bold: 'THSarabunNew-Bold.ttf',
        italics: 'THSarabunNew-Italic.ttf',
        bolditalics: 'THSarabunNew-BoldItalic.ttf'
    },
    Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf'
    }
};

export function ShareOrderHistoryDetail(props) {
    useEffect(() => {
        if (props.result.order_status === '1') {
            let docDefinition = {
                pageSize: 'LETTER',
                content: [
                    {
                        image: `${pdfImage}`,
                        width: 550,
                        height: 750
                    },
                    {
                        text: Moment(new Date(props.result.order_date.replace(/-/g, '/')))
                            .add(543, 'year')
                            .locale('th')
                            .format('DD'),
                        absolutePosition: { x: 398, y: 144 }
                    },
                    {
                        text: Moment(new Date(props.result.order_date.replace(/-/g, '/')))
                            .add(543, 'year')
                            .locale('th')
                            .format('MMMM'),
                        absolutePosition: { x: 440, y: 144 }
                    },
                    {
                        text: Moment(new Date(props.result.order_date.replace(/-/g, '/')))
                            .add(543, 'year')
                            .locale('th')
                            .format('YYYY'),
                        absolutePosition: { x: 504, y: 144 }
                    },
                    {
                        text: props.result.name,
                        absolutePosition: { x: 210, y: 284 }
                    },
                    {
                        text: props.result.address,
                        absolutePosition: { x: 130, y: 308 }
                    },
                    {
                        text: props.result.item_amount,
                        absolutePosition: { x: 242, y: 333 }
                    },
                    {
                        text: THBText(props.result.item_amount).replace('บาทถ้วน', 'หุ้น'),
                        absolutePosition: { x: 325, y: 333 }
                    },
                    {
                        text: props.result.price,
                        absolutePosition: { x: 462, y: 333 }
                    },
                    {
                        text: THBText(props.result.price),
                        absolutePosition: { x: 135, y: 356 }
                    },
                    {
                        text: props.result.name,
                        absolutePosition: { x: 170, y: 644 }
                    }
                    // { text: props.result.info.idcard, absolutePosition: { x: 480, y: 202 } },
                    // { text: props.result.info.address, absolutePosition: { x: 122, y: 230 } },
                    // {
                    //     text: (new Date().getFullYear() + 543 - 1).toString(),
                    //     absolutePosition: { x: 400, y: 558 }
                    //     // absolutePosition: { x: 400, y: 600 }
                    // },
                    // {
                    //     text: props.result.info.income,
                    //     absolutePosition: { x: 467, y: 558 }
                    //     // absolutePosition: { x: 400, y: 600 }
                    // },
                    // {
                    //     text: props.result.info.tax,
                    //     absolutePosition: { x: 530, y: 558 }
                    //     // absolutePosition: { x: 400, y: 600 }
                    // },
                    // {
                    //     text: props.result.info.income,
                    //     absolutePosition: { x: 467, y: 578 }
                    //     // absolutePosition: { x: 400, y: 600 }
                    // },
                    // {
                    //     text: props.result.info.tax,
                    //     absolutePosition: { x: 530, y: 578 }
                    //     // absolutePosition: { x: 400, y: 600 }
                    // },
                    // {
                    //     text:
                    //         parseFloat(props.result.info.income) + parseFloat(props.result.info.tax) === 0
                    //             ? '-- ศูนย์บาทถ้วน --'
                    //             : '-- ' + THBText(props.result.info.tax) + ' --',
                    //     absolutePosition: { x: 200, y: 603 }
                    // }
                ],
                styles: {
                    boldStyle: {
                        // fontSize: 18,
                        bold: true
                    },
                    smallText: {
                        fontSize: 8
                    },
                    firstTab: {
                        margin: [ 9, 0, 0, 0 ]
                    },
                    secondTab: {
                        margin: [ 18, 0, 0, 0 ]
                    },
                    thirdTab: {
                        margin: [ 27, 0, 0, 0 ]
                    }
                },
                defaultStyle: {
                    font: 'THSarabunNew',
                    fontSize: 12
                },
                layout: {
                    defaultBorder: false
                }
            };
            props.setPdfTemplate(docDefinition);
            let doc = pdfMake.createPdf(docDefinition);
            let f = document.getElementById('pdf');
            let callback = function(url) {
                f.setAttribute('src', url);
            };
            doc.getDataUrl(callback, doc);
        }
    }, []);

    const downloadPdf = () => {
        pdfMake
            .createPdf(props.pdfTemplate)
            .download(`order-${Moment(new Date(props.result.order_date)).format('YYMMDDHHmmss')}`);
    };

    const btnDownload = () => {
        let d = new Date();
        html2canvas(document.querySelector('#react-qrcode-logo')).then(function(canvas) {
            const link = document.createElement('a');
            link.download = `${Moment(d).format('YYYYMMDDhhmmss')}.png`;
            link.href = canvas.toDataURL();
            link.click();
        });
    };

    return (
        <div className='container clearfix p-0'>
            <div className='card rounded-lg'>
                <div className='card-header bg-secondary p-4 rounded-lg rounded-bottom-0'>
                    <div className='row justify-content-between m-0'>
                        <button type='button' className='btn btn-link-primary' onClick={props.btnBack}>
                            <i className='fas fa-angle-double-left icon-md py-2' />
                            ย้อนกลับ
                        </button>
                    </div>
                </div>
                {props.result.order_status === '1' ? (
                    <div className='card-body rounded-lg rounded-top-0'>
                        <div className='d-none d-md-flex vh-100'>
                            <iframe title='template' width='100%' height='100%' id='pdf' />
                        </div>
                        <div className='row h-500px align-content-center justify-content-center d-flex d-md-none'>
                            <div className='col-12 text-center'>
                                <h1>บันทึกข้อตกลงการซื้อขายหุ้นบุริมสิทธิ</h1>
                                <div className='mt-15'>
                                    <button
                                        type='button'
                                        className='btn btn-primary btn-lg py-5 w-150px rounded-lg'
                                        onClick={downloadPdf}
                                    >
                                        ดาวน์โหลด
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : props.result.order_status === '0' ? (
                    <div className='card-body rounded-lg rounded-top-0'>
                        <div className='row d-flex justify-content-center pb-5'>
                            <div className='col-12'>
                                <div className='row justify-content-center'>
                                    <div className='text-center rounded bg-payment p-1 pt-3'>
                                        {/* <div className='text-white text-center'>
                                            หมายเลขคำสั่งซื้อ {props.result.reference1}
                                        </div> */}
                                        <div className='qrimg'>
                                            <QRCode
                                                className='border'
                                                value={props.result.qrcode}
                                                size={256}
                                                eyeRadius={10}
                                                logoImage={toAbsoluteUrl('/media/logos/new-logo.png')}
                                                logoWidth={100}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row col-12 p-0 m-0'>
                                <div className='col-12 mt-50 text-center'>
                                    <button
                                        type='button'
                                        className='btn btn-success font-weight-bold text-uppercase px-6 py-2'
                                        onClick={btnDownload}
                                    >
                                        บันทึกภาพ
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
}
